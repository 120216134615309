import React from "react";
import "./loaderData.css"; // Assurez-vous de styliser votre loader

const LoaderData = () => {
  return (
    <div className="loader-container">
      <div className="loader"></div>
      <p>Chargement...</p>
    </div>
  );
};

export default LoaderData;
