import React from 'react';

const Service = () => {
  return (
    <>
      {/* <!-- Service Start --> */}
      <div className="container-fluid service py-5" id='service'>
        <div className="container py-5">
          <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{ maxWidth: '800px' }}>
            <h4 className="text-primary">Nos Services</h4>
            <h1 className="display-4 mb-4">Des Services d'Excellence</h1>
            <p className="mb-0">
              Nous offrons une gamme de services spécialisés pour répondre à vos besoins avec une expertise inégalée et une attention aux détails.
            </p>
          </div>
          <div className="row g-4 justify-content-center">
            <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.2s">
              <div className="service-item">
                <div className="service-img">
                  <img src="img/blog-1.jpg" className="img-fluid rounded-top w-100" alt="" />
                  <div className="service-icon p-3">
                    <i className="fa fa-users fa-2x"></i>
                  </div>
                </div>
                <div className="service-content p-4">
                  <div className="service-content-inner">
                    <p className="d-inline-block h4 mb-4">Placement à Court Terme</p>
                    <p className="mb-4">Profitez de placements à court terme avec un taux d'intérêt compétitif de 12% par an, sur des périodes allant de 6 à 36 mois.</p>
                    {/* <a className="btn btn-primary rounded-pill py-2 px-4" href="#">En savoir plus</a> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.4s">
              <div className="service-item">
                <div className="service-img">
                  <img src="img/blog-2.png" className="img-fluid rounded-top w-100" alt="" />
                  <div className="service-icon p-3">
                    <i className="fa fa-hospital fa-2x"></i>
                  </div>
                </div>
                <div className="service-content p-4">
                  <div className="service-content-inner">
                    <p className="d-inline-block h4 mb-4">Carnet Bousanm</p>
                    <p className="mb-4">Un carnet de gestion de portefeuille vous permettant de suivre vos investissements en toute simplicité, avec des options de dépôt et de retrait flexibles.</p>
                    {/* <a className="btn btn-primary rounded-pill py-2 px-4" href="#">En savoir plus</a> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.6s">
              <div className="service-item">
                <div className="service-img">
                  <img src="img/blog-3.png" className="img-fluid rounded-top w-100" alt="" />
                  <div className="service-icon p-3">
                    <i className="fa fa-car fa-2x"></i>
                  </div>
                </div>
                <div className="service-content p-4">
                  <div className="service-content-inner">
                    <p className="d-inline-block h4 mb-4">Mutuelle</p>
                    <p className="mb-4">Nous proposons des services de mutuelle pour sécuriser vos investissements tout en permettant une gestion collective de vos fonds.</p>
                    {/* <a className="btn btn-primary rounded-pill py-2 px-4" href="#">En savoir plus</a> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.8s">
              <div className="service-item">
                <div className="service-img">
                  <img src="img/blog-4.png" className="img-fluid rounded-top w-100" alt="" />
                  <div className="service-icon p-3">
                    <i className="fa fa-home fa-2x"></i>
                  </div>
                </div>
                <div className="service-content p-4">
                  <div className="service-content-inner">
                    <p className="d-inline-block h4 mb-4">Gestion de Patrimoine</p>
                    <p className="mb-4">Maximisez la valeur de vos actifs grâce à nos services de gestion de patrimoine, avec des conseils personnalisés pour optimiser vos investissements à long terme.</p>
                    {/* <a className="btn btn-primary rounded-pill py-2 px-4" href="#">En savoir plus</a> */}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-12 text-center wow fadeInUp" data-wow-delay="0.2s">
              <a className="btn btn-primary rounded-pill py-3 px-5" href="#">Voir tous nos services</a>
            </div> */}
          </div>
        </div>
      </div>
      {/* <!-- Service End --> */}
    </>
  )
}

export default Service;
