import React, { createContext, useContext } from "react";
import AuthContext from "./AuthContext";
import { useHistory } from "react-router-dom";
import { baseUrlAccount, baseUrlAccount1 } from "./BaseUrl";
const swal = require("sweetalert2");

const baseUrl = baseUrlAccount;
const baseUrl1 = baseUrlAccount1;

const AccountContext = createContext();

const AccountProvider = ({ children }) => {
  // const [isLoading, setIsLoading] = useState(false);
  const { authTokens, logoutUser } = useContext(AuthContext);

  const history = useHistory();
  // Fonctions de gestion des professeurs

  const getAccounts = async () => {
    const response = await fetch(`${baseUrl}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
    });
    const data = await response.json();
    return data;
  };
  const getAccountById = async (AccountId) => {
    const response = await fetch(`${baseUrl}${AccountId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
    });
    const data = await response.json();
    return data;
  };

  const getAccountByCode = async (formData) => {
    const response = await fetch(`${baseUrl}get-Account-by-code/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: formData,
    });

    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else if (response.status === 404) {
      console.log(response.status);
      console.log("there was a server issue");
      swal.fire({
        title: "Not Found " + response.status,
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } else {
      console.log("there was a server issue");
    }
  };

  const deleteAccounts = async (AccountId) => {
    const response = await fetch(`${baseUrl}${AccountId}/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
    });
    if (response.status === 204) {
      swal.fire({
        title: "User Deleted Successfully",
        icon: "success",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } else {
      console.log(response.status);
      console.log("there was a server issue");
      swal.fire({
        title: "An Error Occured " + response.status,
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  const ResetPasswordCustomer = async (code) => {
    const response = await fetch(`${baseUrl}reset-password/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: JSON.stringify({ code }),
    });
    if (response.status === 204) {
      swal.fire({
        title: "Réinisialié",
        icon: "success",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } else {
      console.log(response.status);
      console.log("there was a server issue");
      swal.fire({
        title: "An Error Occured " + response.status,
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  const updateAccounts = async (AccountId, formData) => {
    const response = await fetch(`${baseUrl}${AccountId}/`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: formData,
    });
    if (response.status === 200) {
      swal.fire({
        title: "Account Updated Successfully",
        icon: "success",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      history.push("/List");
    } else {
      console.log(response.status);
      console.log("there was a server issue");
      swal.fire({
        title: "An Error Occured " + response.status + "\n" + response.error,
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  // const createAccount = async (formData) => {
  //     const response = await fetch(`${baseUrl}`, {
  //         method: "POST",
  //         headers: {
  //             "Authorization": `Bearer ${authTokens.access}`
  //         },
  //         body: formData
  //     });

  //     if (response.status === 201) {
  //         swal.fire({
  //             title: "Inscription Created Successfully",
  //             icon: "success",
  //             toast: true,
  //             timer: 3000,
  //             position: 'top-right',
  //             timerProgressBar: true,
  //             showConfirmButton: false,
  //         });
  //         history.push("/List");
  //     } else {
  //         console.log(response.status);
  //         console.log("there was a server issue");
  //         swal.fire({
  //             title: "An Error Occured " + response.status,
  //             icon: "error",
  //             toast: true,
  //             timer: 3000,
  //             position: 'top-right',
  //             timerProgressBar: true,
  //             showConfirmButton: false,
  //         });
  //     }
  // };
  const createAccount = async (formData) => {
    try {
      // Appel pour créer le compte
      const response = await fetch(`${baseUrl}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: formData,
      });

      if (response.status === 201) {
        const accountData = await response.json(); // Obtenir les infos du compte créé (email, username, password)

        // Afficher un message de succès
        swal.fire({
          title: "Compte créé avec succès",
          icon: "success",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });

        // Appel API pour envoyer l'email
        const emailResponse = await fetch(`${baseUrl}send-account-email/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens.access}`,
          },
          body: JSON.stringify({
            email: accountData.email,
            username: accountData.username,
            password: accountData.pass_send,
          }),
        });

        if (emailResponse.status === 200) {
          swal.fire({
            title: "E-mail envoyé avec succès",
            icon: "success",
            toast: true,
            timer: 3000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        } else {
          console.error("Erreur lors de l'envoi de l'e-mail");
          swal.fire({
            title: "Erreur lors de l'envoi de l'e-mail",
            icon: "error",
            toast: true,
            timer: 3000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        }

        // Rediriger vers la liste après la création
        history.push("/List");
      } else {
        console.error("Erreur lors de la création du compte:", response.status);
        swal.fire({
          title: "Une erreur est survenue: " + response.status,
          icon: "error",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error("Erreur lors de la création du compte:", error);
      swal.fire({
        title: "Erreur lors de la création du compte",
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  const activateDeactivateAccount = async (userId) => {
    try {
      const response = await fetch(
        `${baseUrl}activate-deactivate-accounts/${userId}/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      );

      if (!response.ok) {
        if (response.status === 403) {
          swal.fire({
            title:
              "Permission Denied: \nYou cannot deactivate your own account.",
            icon: "error",
            toast: true,
            timer: 3000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        } else {
          throw new Error("Failed to activate/deactivate user");
        }
      } else {
        history.push("/List");
        const data = await response.json();
        swal.fire({
          title: data.message,
          icon: "success",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error(error);
      swal.fire({
        title: "Failed to activate/deactivate user",
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  const copyAccount = async (formData) => {
    const response = await fetch(`${baseUrl}copy-account/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: formData,
    });

    if (response.status === 201) {
      swal.fire({
        title: "Account Copied Successfully",
        icon: "success",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      history.push("/List");
    } else {
      console.log(response.status);
      console.log("there was a server issue");
      swal.fire({
        title: "An Error Occured " + response.status,
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  const getClientAccounts = async (email) => {
    try {
      const response = await fetch(`${baseUrl}client-accounts/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify({ email }),
      });

      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        throw new Error("Failed to fetch client accounts");
      }
    } catch (error) {
      console.error(error);
      return { error: "Failed to fetch client accounts" };
    }
  };
  const changePassword = async (email, newPassword) => {
    try {
      const response = await fetch(`${baseUrl}change_password/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify({ email, new_password: newPassword }),
      });

      if (response.ok) {
        const data = await response.json();
        swal.fire({
          title: data.message,
          icon: "success",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
        await logoutUser();
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error || "An error occurred");
      }
    } catch (error) {
      console.error("Error changing password:", error);
      swal.fire({
        title: "Failed to change password",
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  const changePasswordCustomer = async (
    code,
    new_password,
    confirm_new_password
  ) => {
    try {
      const response = await fetch(`${baseUrl}change-password-customer/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify({ code, new_password, confirm_new_password }),
      });

      if (response.ok) {
        const data = await response.json();
        swal.fire({
          title: data.message,
          icon: "success",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
        await logoutUser();
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error || "An error occurred");
      }
    } catch (error) {
      console.error("Error changing password:", error);
      swal.fire({
        title: "Failed to change password",
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  const fetchNextInterest = async (accountId) => {
    try {
      const response = await fetch(`${baseUrl1}calculate_plasman_interest/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify({ account: accountId }), // Envoyez l'ID du compte
      });
      const data = await response.json();
      console.log("API Response:", data); // Ajoutez ceci pour vérifier la réponse
      if (data.nextInterest) {
        return data.nextInterest;
      } else {
        console.error("Error calculating interest:", data.error);
        return null;
      }
    } catch (error) {
      console.error("Error fetching next interest:", error);
      return null;
    }
  };

  // Ajoutez cette fonction à `contextData` dans votre `AccountProvider`
  const contextData = {
    createAccount,
    getAccounts,
    getAccountById,
    updateAccounts,
    deleteAccounts,
    getAccountByCode,
    activateDeactivateAccount,
    copyAccount,
    getClientAccounts,
    fetchNextInterest,
    changePassword,
    changePasswordCustomer,
    ResetPasswordCustomer,
  };

  return (
    <AccountContext.Provider value={contextData}>
      {children}
    </AccountContext.Provider>
  );
};

export { AccountContext, AccountProvider };
