/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import "./loopple.css";
import "./theme.css";
import AuthContext from "../../context/AuthContext";
import { CustomerContext } from "../../context/CustomerContext";
import LoaderData from "../../Include/loaderData/loaderData";
import DataTable from "react-data-table-component";
import { customStyles } from "../../data/Utility";
import { Modal, Button, Form } from "react-bootstrap"; // Importer les composants Bootstrap
import { AccountContext } from "../../context/AccountsContext";
const swal = require("sweetalert2");

const ProfilSol = () => {
  const [selectedAccount, setSelectedAccount] = useState(null);
  const { getCustomerBySurnom } = useContext(CustomerContext);
  const { changePasswordCustomer } = useContext(AccountContext);
  const { getUserCodeFromLocalStorage, logoutUser } = useContext(AuthContext);
  const code = getUserCodeFromLocalStorage();
  const [newPassword, setNewPassword] = useState("");
  const [passwordError, setPasswordError] = useState(""); // Pour stocker le message d'erreur
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(true);
  const [records, setRecords] = useState([]);
  const [showModal, setShowModal] = useState(false); // Pour contrôler l'affichage du modal
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  useEffect(() => {
    const fetchAccountInfo = async () => {
      setLoading(true);
      try {
        const data = await getCustomerBySurnom(code);
        if (!data.error) {
          setSelectedAccount(data);
          // Vérifier si les transactions existent avant de les définir dans records
          if (data.transaction && data.transaction.historique_transactions) {
            setRecords(data.transaction.historique_transactions);
          } else {
            setRecords([]); // Si aucune transaction n'existe
          }
        } else {
          // alert("Erreur lors de la récupération des données du compte.");
        }
      } catch (error) {
        alert(`Erreur: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchAccountInfo();
    const handleClickOutside = (event) => {
      const sidebarElement = document.getElementById("sidenav-1-main");
      if (sidebarElement && !sidebarElement.contains(event.target)) {
        setIsSidebarVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [code, getCustomerBySurnom]);

  const columnsHistory = [
    {
      name: "Date",
      selector: (row) => row?.date,
      sortable: true,
    },
    {
      name: "Montant",
      selector: (row) => row?.montant,
      sortable: true,
    },
    // Ajoutez d'autres colonnes selon vos besoins
  ];
  const derniereTransaction = selectedAccount?.transaction;

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  // Fonction pour soumettre le mot de passe
  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    if (newPassword !== confirmPassword) {
      setPasswordError("Les mots de passe ne correspondent pas.");
      setLoading(false);
      return;
    }

    // Logique de changement de mot de passe

    try {
      await changePasswordCustomer(
        selectedAccount?.client_info.code,
        newPassword,
        confirmPassword
      );
    } catch (error) {
      alert(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
    console.log("Nouveau mot de passe soumis :", newPassword);

    // Réinitialiser les champs et fermer le modal
    setNewPassword("");
    setConfirmPassword("");
    setPasswordError("");
    setShowModal(false);
  };

  // Réinitialiser les champs et l'erreur à la fermeture du modal
  const handleCloseModal = () => {
    setShowModal(false);
    setNewPassword("");
    setConfirmPassword("");
    setPasswordError("");
  };
  return (
    <div>
      <nav
        // className="sidenav-1 navbar-1 navbar-1-vertical fixed-left navbar-1-expand-xs navbar-1-light bg-white-1 loopple-fixed-start"
        // id="sidenav-1-main"
        className={`sidenav-1  navbar-1 navbar-1-vertical fixed-left 
          navbar-1-expand-xs navbar-1-light bg-white-1 loopple-fixed-start ${
            isSidebarVisible ? "active" : ""
          }`}
        id="sidenav-1-main"
      >
        <div className="navbar-1-inner">
          <button
            className="navbar-toggler d-md-none"
            type="button"
            data-toggle="collapse"
            data-target="#sidenav-1-collapse-main"
            aria-controls="sidenav-1-collapse-main"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggleSidebar}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-1-collapse"
            id="sidenav-1-collapse-main"
          >
            <ul className="navbar-1-nav">
              <li className="nav-item-1 mb-3 mt-0">
                <a
                  className="nav-link-1 active bg-primary text-white"
                  href="javascript:"
                >
                  <i className="fa fa-desktop text-primary"></i>
                  <span className="nav-link-1-text fw-bold">Bousanm</span>
                </a>
              </li>
              <li className="nav-item-1">
                <a className="nav-link-1 active fw-bold" href="javascript:">
                  <i className="fa fa-desktop text-primary"></i>
                  <span className="nav-link-1-text fw-bold">Vos Comptes </span>
                </a>
              </li>
              <li className="nav-item-1">
                <a className="nav-link-1" href="javascript:">
                  <i className="fa fa-lock text-danger"></i>
                  <span className="nav-link-1-text">
                    {" "}
                    {selectedAccount
                      ? `${selectedAccount?.client_info.code} `
                      : "Numero Compte"}
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div className="main-content-1" id="panel">
        <nav
          className="navbar-1 navbar-1-top navbar-1-expand navbar-1-dark border-bottom bg-primary"
          id="navbar-1Top"
        >
          <div className="container-fluid-1">
            <div
              className="collapse navbar-1-collapse"
              id="navbar-1SupportedContent"
            >
              <ul className="navbar-1-nav align-items-center ml-md-auto">
                <li className="nav-item-1 d-xl-none">
                  <div
                    className="pr-3 sidenav-1-toggler sidenav-1-toggler-dark active"
                    onClick={toggleSidebar}
                  >
                    <div className="sidenav-1-toggler-inner">
                      <i className="sidenav-1-toggler-line"></i>
                      <i className="sidenav-1-toggler-line"></i>
                      <i className="sidenav-1-toggler-line"></i>
                    </div>
                  </div>
                </li>
              </ul>
              <ul className="navbar-1-nav align-items-center ml-auto ml-md-0">
                <li className="nav-item-1 dropdown">
                  <a
                    className="nav-link-1 pr-0"
                    href="#"
                    role="button"
                    onClick={toggleDropdown}
                    aria-haspopup="true"
                    aria-expanded={isDropdownOpen ? "true" : "false"}
                  >
                    <div className="media align-items-center">
                      <span className="avatar avatar-sm rounded-circle">
                        <img alt="Image placeholder" src="/img/team-4.jpg" />
                      </span>
                      <div className="media-body ml-2 d-none d-lg-block">
                        <span className="mb-0 text-sm font-weight-bold">
                          {selectedAccount
                            ? `${selectedAccount?.client_info.surnom} `
                            : "Surnom"}
                        </span>
                      </div>
                    </div>
                  </a>
                  <div
                    className={`dropdown-menu dropdown-menu-right ${
                      isDropdownOpen ? "show" : ""
                    }`}
                  >
                    <div className="dropdown-header noti-title">
                      <h6 className="text-overflow m-0">Bienvenue!</h6>
                    </div>
                    <a
                      href="#!"
                      onClick={() => setShowModal(true)}
                      className="dropdown-item"
                    >
                      <i className="fas fa-lock"></i>
                      <span>Changer mot de passe</span>
                    </a>
                    <div className="dropdown-divider"></div>
                    <a onClick={logoutUser} className="dropdown-item">
                      <i className="fas fa-sign-out-alt"></i>
                      <span>Déconnecter</span>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div className="container-fluid-1 pt-3">
          {/* Autres contenus */}

          {/* Modal pour changer le mot de passe */}
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Changer le mot de passe</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="formNewPassword">
                  <Form.Label>Nouveau mot de passe</Form.Label>
                  <Form.Control
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="Entrez le nouveau mot de passe"
                  />
                </Form.Group>

                <Form.Group controlId="formConfirmPassword">
                  <Form.Label>Confirmer le mot de passe</Form.Label>
                  <Form.Control
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirmez le mot de passe"
                    isInvalid={!!passwordError} // Style si erreur
                    style={{
                      borderColor: passwordError ? "red" : "",
                    }}
                  />
                  {passwordError && (
                    <Form.Text style={{ color: "red" }}>
                      {passwordError}
                    </Form.Text>
                  )}
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Fermer
              </Button>
              <Button variant="primary" onClick={handleSubmit}>
                Enregistrer
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        <div className="container-fluid-1 pt-3">
          <div className="row removable">
            <div className="col-lg-4">
              <div className="card-1 card-1-profile">
                <img
                  src="/img/h-bg.png"
                  alt="Image placeholder"
                  className="card-1-img-top-1"
                />
                <div className="row justify-content-center">
                  <div className="col-lg-3 order-lg-2">
                    <div className="card-1-profile-1-image">
                      <a href="#">
                        <img src="/img/team-4.jpg" className="rounded-circle" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="card-1-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                  <div className="d-flex justify-content-between">
                    <a href="#" className="btn btn-sm mr-4 bg-primary fw-bold">
                      Sol :{" "}
                      {selectedAccount
                        ? `${selectedAccount?.client_info.sol}`
                        : "Sol"}
                      <span className="fw-normal fs-10">gdes</span>
                    </a>
                    <a href="#" className="btn btn-sm float-right btn-success">
                      {selectedAccount
                        ? `${selectedAccount?.client_info.nb_main} `
                        : "Main"}{" "}
                      <span className="fw-normal fs-10">main</span>
                    </a>
                  </div>
                </div>
                <div className="card-1-body pt-0">
                  <div className="row">
                    <div className="col">
                      <div className="text-center">
                        <h5 className="h3">
                          {selectedAccount
                            ? `${selectedAccount?.client_info.nom || ""} ${
                                selectedAccount?.client_info.prenom || ""
                              }`
                            : "Nom Complet"}
                          <span className="font-weight-light"></span>
                        </h5>
                        <h5></h5>
                      </div>
                      <div className="card-1-profile-1-stats d-flex justify-content-center">
                        <div>
                          <span className="heading">
                            {selectedAccount
                              ? `${selectedAccount?.client_info.balance || 0} `
                              : "Balance"}
                            gdes
                          </span>
                          <span className="description">Solde</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-8">
              {/* card-1 for Transactions .transactions[0].date*/}
              <div className="card-1 card-1-stats">
                <div className="card-1-body">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-1-title text-uppercase text-muted mb-0">
                        Derniere Transaction
                      </h5>
                      <span className="h2 font-weight-bold mb-0">
                        {" "}
                        {selectedAccount
                          ? `${
                              derniereTransaction?.derniere_transaction
                                ?.montant || 0
                            } `
                          : "Montant"}
                      </span>{" "}
                      gdes
                    </div>
                    <div className="col-auto">
                      <div className="icon icon-shape text-white rounded-circle shadow bg-gradient-warning">
                        Depo
                      </div>
                    </div>
                  </div>
                  <p className="mt-3 mb-0 text-sm">
                    <span className="text-success mr-2">
                      <i className="fa fa-arrow-up"></i>{" "}
                      {selectedAccount
                        ? `${
                            derniereTransaction?.derniere_transaction?.date || 0
                          } `
                        : "Montant"}
                    </span>
                    {/* <span className="text-nowrap">04:34:09 PM</span> */}
                  </p>
                </div>
              </div>
              {/* card-1 for Interests nb_jours_payer*/}
              <div className="card-1 card-1-stats">
                <div className="card-1-body">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-1-title text-uppercase text-muted mb-0">
                        Nombre de jours payé
                      </h5>
                      <span className="h2 font-weight-bold mb-0">
                        {" "}
                        {selectedAccount
                          ? `${derniereTransaction?.nb_jours_payer || 0} `
                          : "Jrs_payé"}
                      </span>
                    </div>
                    <div className="col-auto">
                      <h5 className="card-1-title text-uppercase text-muted mb-0 ">
                        Sur
                      </h5>
                      <div className="icon icon-shape text-white rounded-circle shadow bg-gradient-success fw-bold">
                        {selectedAccount
                          ? `${derniereTransaction?.total_jours_sol || 0} `
                          : "Jrs"}
                      </div>
                    </div>
                  </div>
                  <p className="mt-3 mb-0 text-sm">
                    <span className="text-success mr-2">
                      <i className="fa fa-arrow-up"></i>
                    </span>
                    <span className="text-nowrap"></span>
                  </p>
                </div>
              </div>

              <div class="card-1 card-1-stats">
                <div class="card-1-body">
                  <div class="row">
                    <div class="col">
                      <h5 class="card-1-title text-uppercase text-muted mb-0">
                        Nombre de jours non payé
                      </h5>
                      <span class="h2 font-weight-bold mb-0">
                        {" "}
                        {selectedAccount
                          ? `${derniereTransaction?.nb_jours_non_payer || 0} `
                          : "Jrs"}
                      </span>{" "}
                    </div>
                    <div class="col-auto">
                      <div class="icon icon-shape bg-orange text-white rounded-circle shadow">
                        <i class="fa fa-inbox"></i>
                      </div>
                    </div>
                  </div>
                  <p class="mt-3 mb-0 text-sm">
                    <span class="text-danger mr-2">
                      <i class="fa fa-arrow-down"></i>&nbsp;{" "}
                      {selectedAccount
                        ? `${derniereTransaction?.montant_non_payer || 0} `
                        : "Jrs"}
                    </span>
                    <span class="text-nowrap">Gourdes</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row removable">
            <div className="col-lg-4">
              <div className="card-1 card-1-profile">
                <div className="row justify-content-center">
                  <div className="col-lg-3 order-lg-2">
                    <div className="card-1-profile-1-image">
                      <a href="#"></a>
                    </div>
                  </div>
                </div>
                <div className="card-1-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                  <div className="d-flex justify-content-between">
                    <a href="#" className="btn btn-sm bg-primary fw-bold">
                      Nombre de mains payé :{" "}
                      {selectedAccount
                        ? `${
                            selectedAccount?.transaction?.nb_mains_payees || 0
                          }`
                        : "Sol"}
                      <span className="fw-normal fs-10"></span>
                    </a>
                  </div>
                </div>
                <div className="card-1-body pt-0">
                  <div className="row">
                    <div className="col">
                      <div className="text-center">
                        <h5 className="h3">
                          {/* {selectedAccount
                            ? `${selectedAccount?.client_info.nom || ""} ${
                                selectedAccount?.client_info.prenom || ""
                              }`
                            : "Nom Complet"} */}
                          <span className="font-weight-light"></span>
                        </h5>
                        <h5></h5>
                      </div>
                      <div className="card-1-profile-1-stats d-flex justify-content-center">
                        <div>
                          <span className="heading">
                            {selectedAccount
                              ? `${
                                  selectedAccount?.transaction
                                    ?.montant_total_paye || 0
                                } `
                              : "Balance"}
                            gdes
                          </span>{" "}
                          <span className="description">
                            <i className="fas fa-calendar"></i>{" "}
                            {selectedAccount
                              ? `${
                                  selectedAccount?.transaction
                                    ?.derniere_date_paiement || "date"
                                } `
                              : "Date"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="card-1">
                <div className="card-1-header border-0">
                  <div className="row align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Historique</h3>
                    </div>
                    <div className="col text-right">
                      <a href="#!" className="btn btn-sm btn-primary">
                        <i className="fas fa-history"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  {loading ? (
                    <>
                      <LoaderData />
                    </>
                  ) : (
                    <>
                      <DataTable
                        columns={columnsHistory}
                        data={records}
                        fixedHeader
                        pagination
                        customStyles={customStyles}
                        paginationPerPage="5"
                        paginationRowsPerPageOptions={[5, 10, 30, 40, 50]}
                        paginationComponentOptions={{
                          rowsPerPageText: "Lignes par page:",
                          rangeSeparatorText: "sur",
                          noRowsPerPage: false,
                          selectAllRowsItem: false,
                          selectAllRowsItemText: "Tous",
                        }}
                        noDataComponent="Aucune donnée trouvée"
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilSol;
