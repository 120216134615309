import React, { createContext, useContext } from "react";
import AuthContext from "./AuthContext";
import { useHistory } from "react-router-dom";
import { baseUrlTransactions } from './BaseUrl';
const swal = require("sweetalert2");
const baseUrl = baseUrlTransactions;

const TransactionsContext = createContext();

const TransactionProvider = ({ children }) => {
  // const [isLoading, setIsLoading] = useState(false);
  const { authTokens } = useContext(AuthContext);

  const history = useHistory();
  // Fonctions de gestion des professeurs

  const getTransactions = async () => {
    const response = await fetch(`${baseUrl}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
    });
    const data = await response.json();
    return data;
  };
  const getTransactionById = async (TransactionId) => {
    const response = await fetch(`${baseUrl}${TransactionId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
    });
    const data = await response.json();
    return data;
  };

  const getTransactionByCode = async (formData) => {
    const response = await fetch(`${baseUrl}get-Transaction-by-code/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: formData,
    });

    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else if (response.status === 404) {
      console.log(response.status);
      console.log("there was a server issue");
      swal.fire({
        title: "Not Found " + response.status,
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } else {
      console.log("there was a server issue");
    }
  };

  const deleteTransactions = async (TransactionId) => {
    const response = await fetch(`${baseUrl}${TransactionId}/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
    });
    if (response.status === 204) {
      swal.fire({
        title: "User Deleted Successfully",
        icon: "success",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } else {
      console.log(response.status);
      console.log("there was a server issue");
      swal.fire({
        title: "An Error Occured " + response.status,
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  const updateTransactions = async (TransactionId, formData) => {
    const response = await fetch(`${baseUrl}${TransactionId}/`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: formData,
    });
    if (response.status === 200) {
      swal.fire({
        title: "Transaction Updated Successfully",
        icon: "success",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      history.push("/List");
    } else {
      console.log(response.status);
      console.log("there was a server issue");
      swal.fire({
        title: "An Error Occured " + response.status + "\n" + response.error,
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  const createTransaction = async (formData) => {
    const response = await fetch(`${baseUrl}create/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: formData,
    });

    if (response.status === 201) {
      swal.fire({
        title: "Transaction Created Successfully",
        icon: "success",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      history.push("/transaction");
    } else {
      console.log(response.status);
      console.log("there was a server issue");
      swal.fire({
        title: "An Error Occured " + response.status,
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  const near_end_date = async () => {
    try {
      const response = await fetch(`${baseUrl}near_end_date/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
      });

      if (!response.ok) {
        if (response.status === 403) {
          swal.fire({
            title:
              "Permission Denied: \nYou cannot deactivate your own Transaction.",
            icon: "error",
            toast: true,
            timer: 3000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        } else {
          throw new Error("Failed to activate/deactivate user");
        }
      } else {
        const data = await response.json();
        return data
      }
    } catch (error) {
      console.error(error);
      swal.fire({
        title: "Failed to activate/deactivate user",
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  const calculate_interest = async () => {
    try {
      const response = await fetch(`${baseUrl}calculate-interest/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
      });

      if (!response.ok) {
        if (response.status === 403) {
          swal.fire({
            title:
              "Permission Denied: \nYou cannot deactivate your own Transaction.",
            icon: "error",
            toast: true,
            timer: 3000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        } else {
          throw new Error("Failed to activate/deactivate user");
        }
      } else {
        history.push("/List");
        const data = await response.json();
        swal.fire({
          title: data.message,
          icon: "success",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error(error);
      swal.fire({
        title: "Failed to activate/deactivate user",
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  const renewPlasman = async (account_id, formData) => {
    try {
      const response = await fetch(`${baseUrl}renew-plasman/${account_id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: formData,
      });

      if (!response.ok) {
        if (response.status === 403) {
          swal.fire({
            title:
              "Permission Denied: \nYou cannot deactivate your own Transaction.",
            icon: "error",
            toast: true,
            timer: 3000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        } else {
          throw new Error("Failed to activate/deactivate user");
        }
      } else {
        history.push("/List");
        const data = await response.json();
        swal.fire({
          title: data.message,
          icon: "success",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error(error);
      swal.fire({
        title: "Failed to activate/deactivate user",
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  const renewPlasmanByNumber = async (formData) => {
    try {
      const response = await fetch(`${baseUrl}renew-plasman/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: formData,
      });

      if (!response.ok) {
        if (response.status === 403) {
          swal.fire({
            title:
              "Permission Denied: \nYou cannot deactivate your own Transaction.",
            icon: "error",
            toast: true,
            timer: 3000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        } else {
          throw new Error("Failed to activate/deactivate user");
        }
      } else {
        history.push("/List");
        const data = await response.json();
        swal.fire({
          title: data.message,
          icon: "success",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error(error);
      swal.fire({
        title: "Failed to activate/deactivate user",
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  const InterestWithdrawal = async (formData) => {
    try {
      const response = await fetch(`${baseUrl}renew-plasman/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: formData,
      });

      if (!response.ok) {
        if (response.status === 403) {
          swal.fire({
            title:
              "Permission Denied: \nYou cannot deactivate your own Transaction.",
            icon: "error",
            toast: true,
            timer: 3000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        } else {
          throw new Error("Failed to activate/deactivate user");
        }
      } else {
        history.push("/List");
        const data = await response.json();
        swal.fire({
          title: data.message,
          icon: "success",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error(error);
      swal.fire({
        title: "Failed to activate/deactivate user",
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  const getTransactionHistoryForAccount = async (accountId) => {
    try {
      const response = await fetch(`${baseUrl}transaction-history/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify({ account_id: accountId }), // Envoyez l'ID du compte
      });
      const data = await response.json();
      console.log('API Response:', data); // Ajoutez ceci pour vérifier la réponse
      if (data) {
        return data
      } else {
        console.error('Error :', data.error);
        return null;
      }
    } catch (error) {
      console.error('Error fetching:', error);
      return null;
    }
  };



  // Dashoard
  const fetchWithAuth = async (url, options = {}) => {
    const response = await fetch(url, {
      ...options,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
        ...options.headers,
      },
    });
    if (!response.ok) {
      swal.fire({
        title: `Error ${response.status}`,
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      throw new Error("Network response was not ok");
    }
    return response.json();
  };

  const getTotalAccounts = async () => fetchWithAuth(`${baseUrl}total-accounts/`);
  const getAccountsByType = async () => fetchWithAuth(`${baseUrl}accounts-by-type/`);
  const getTotalUsers = async () => fetchWithAuth(`${baseUrl}total-users/`);
  const getCustomerSolTotal = async () => fetchWithAuth(`${baseUrl}total-costomerSol-count/`);
  const getNbrMainSolTotal = async () => fetchWithAuth(`${baseUrl}total-costomerSol/`);
  const getAccountsStatus = async () => fetchWithAuth(`${baseUrl}accounts-status/`);
  const getRecentAccounts = async () => fetchWithAuth(`${baseUrl}recent-accounts/`);
  const getTotalBalance = async () => fetchWithAuth(`${baseUrl}total-balance/`);



  // Ajoutez cette fonction à `contextData` dans votre `TransactionProvider`
  const contextData = {
    createTransaction, getTransactions, getTransactionById, updateTransactions, deleteTransactions, getTransactionByCode,
    calculate_interest, renewPlasman, near_end_date, InterestWithdrawal, renewPlasmanByNumber,
    getTransactionHistoryForAccount, getTotalAccounts, getAccountsByType, getTotalUsers, getAccountsStatus,
    getRecentAccounts, getTotalBalance, getCustomerSolTotal, getNbrMainSolTotal,
  };

  return (
    <TransactionsContext.Provider value={contextData}>
      {children}
    </TransactionsContext.Provider>
  );
};

export { TransactionsContext, TransactionProvider };
