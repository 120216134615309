/* eslint-disable */
import React, { useEffect } from 'react';
const About = () => {
  useEffect(() => {
    const loadCounterUp = async () => {
      const $ = window.jQuery;

      // Check if jQuery and CounterUp are loaded
      if ($ && $.fn.counterUp) {
        $('.counter').counterUp({
          delay: 10,
          time: 1000
        });
      } else {
        console.error("jQuery or counterUp not loaded");
      }
    };

    // Delay the execution to ensure the DOM is fully loaded
    const timeoutId = setTimeout(loadCounterUp, 1000); // 1 second delay

    return () => clearTimeout(timeoutId); // Cleanup the timeout on unmount
  }, []);
  return (
    <>
      {/* <!-- About Start --> */}
      <div className="container-fluid bg-light about pb-5" id="about">
        <div className="container pb-5">
          <div className="row g-5">
            <div className="col-xl-6 wow fadeInLeft" data-wow-delay="0.2s">
              <div className="about-item-content bg-white rounded p-5 h-100">
                <h4 className="text-primary">À propos de notre entreprise</h4>
                <h1 className="display-4 mb-4">Votre situation financière est notre Priorité.</h1>
                <p className='text-justify'>
                  Notre mission est de créer un environnement fiable et favorable à l’investissement, où les investisseurs peuvent prospérer en toute confiance. Nous nous engageons à fournir des opportunités d’investissement attrayantes, soutenues par une transparence totale, une intégrité inébranlable et un engagement envers l'excellence. En travaillant en partenariat avec nos investisseurs.
                </p>
                <p className='text-justify'>
                  Notre équipe dévouée travaille sans relâche pour vous garantir une expérience fluide, que ce soit pour vos transactions ou vos échanges, tout en maintenant des standards élevés de sécurité et de performance.
                </p>

                <p className="text-dark">
                  <i className="fa fa-check text-primary me-3"></i>Nous vous aidons à économiser.
                </p>
                <p className="text-dark">
                  <i className="fa fa-check text-primary me-3"></i>Production ou commerce de biens.
                </p>
                <p className="text-dark mb-4">
                  <i className="fa fa-check text-primary me-3"></i>Pas de frais cachés.
                </p>
                <p className="text-dark mb-4">
                  <i className="fa fa-check text-primary me-3"></i>Équipe dédiée.
                </p>
                <p className="text-dark mb-4">
                  <i className="fa fa-check text-primary me-3"></i>Disponible 24h/24 et 7j/7.
                </p>
                {/* <a className="btn btn-primary rounded-pill py-3 px-5" href="#">
                  Plus d'informations
                </a> */}
              </div>
            </div>
            <div className="col-xl-6 wow fadeInRight" data-wow-delay="0.2s">
              <div className="bg-white rounded p-5 h-100">
                <div className="row g-4 justify-content-center">
                  <div className="col-12">
                    <div className="rounded bg-light">
                      <img src="img/about-1.png" className="img-fluid rounded w-100" alt="About image" />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="counter-item bg-light rounded p-3 h-100">
                      <div className="counter-counting">
                        <span className="counter text-primary fs-2 fw-bold" data-toggle="counter-up">400</span>
                        <span className="h1 fw-bold text-primary">+</span>
                      </div>
                      <h4 className="mb-0 text-dark">Membres adhérent</h4>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="counter-item bg-light rounded p-3 h-100">
                      <div className="counter-counting">
                        <span className="counter text-primary fs-2 fw-bold" data-toggle="counter-up">11</span>
                        <span className="h1 fw-bold text-primary">+</span>
                      </div>
                      <h4 className="mb-0 text-dark">Entreprises Affiliées</h4>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="counter-item bg-light rounded p-3 h-100">
                      <div className="counter-counting">
                        <span className="counter text-primary fs-2 fw-bold" data-toggle="counter-up">12</span>
                        <span className="h1 fw-bold text-primary"> ans</span>
                      </div>
                      <h4 className="mb-0 text-dark">Expériences récompensées</h4>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="counter-item bg-light rounded p-3 h-100">
                      <div className="counter-counting">
                        <span className="counter text-primary fs-2 fw-bold" data-toggle="counter-up">15</span>
                        <span className="h1 fw-bold text-primary">+</span>
                      </div>
                      <h4 className="mb-0 text-dark">Membres de l'équipe</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- About End --> */}
    </>
  );
}

export default About;
