import React, { useState } from 'react'
import Sidebar from '../../Include/Sidebar';
import { Link } from 'react-router-dom';
import Footer from '../../Include/Footer';
import { DataTransactions } from '../../data/DataTransactions';
import { Button, Modal } from "react-bootstrap";

function ListTransaction() {
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => {
    setShowModal(false); // Hide the modal
  };
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the form from submitting
    setShowModal(true); // Show the modal
  };

  return (
    <>
      <Sidebar />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6 d-flex align-items-center">
                <div className="col-sm-1 bg-blue rounded-lg p-2 text-center">
                  <i className="fas fa-exchange-alt"></i>
                </div>
                <div className="ml-2">
                  <h3 className="display fw-500 fw-bold">Gestions des Transactions</h3>
                </div>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><Link to="/dashboard">Acceuil</Link></li>
                  <li className="breadcrumb-item">Liste des Transactions</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid col-12 text-end ">
            <Link className="col-6 align-items-right" to='/add_transaction'>
            </Link>
            <Link className='btn btn-warning me-1' to='/add-renewal'>Renouvellement</Link>
            <button className='btn btn-primary me-1' onClick={handleSubmit}>Retrait Interet</button>
            <Link className='btn btn-success ' to='/add-transaction'>Nouvelle Transaction</Link>
            <hr />
            <div className="row mb-3">
                  <DataTransactions />
              
            </div>
              </div>
            </section>

          <Modal
            className="mt-0"
            show={showModal}
            onHide={closeModal}
            size="lg"
            top="true"
          >
            <Modal.Header closeButton>
              <Modal.Title> Retraits Intérêt</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="col-xl-12 col-lg-5">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 className="m-0 font-weight-bold text-primary">
                                        Retraits Intérêt
                                        </h6>
                                    </div>
                                    <div className="card-body">
                                        <form className="row g-3 mx-auto" encType="multipart/form-data">

                                            <div className="col-md-4 ">
                                            
                                            </div>
                                            <div className="col-md-7 ">
                                                <label htmlFor="new_balance" className="form-label">Entrer le numero du Compte</label>
                                                <div className="input-group">
                                                    <span className="input-group-text " id="basic-addon3"><span className="material-symbols-outlined">
                                                        tag
                                                    </span></span>
                                                    <input type="number" placeholder="Rechercher" name='new_balance' required className="form-control " id="new_balance" aria-label="name" aria-describedby="basic-addon3" />
                                                </div>
                                            </div>

                                            <div className="col-md-1">
                                                <label htmlFor="new_balance" className="form-label text-white">.</label>
                                                <div className="input-group">
                                                    <button className='btn btn-primary'><i className='fas fa-search'></i></button>
                                                    </div>
                                            </div>

                                            <div className="col-md-12">
                                                <label htmlFor="new_balance" className="form-label">Nom Complet</label>
                                                <div className="input-group">
                                                    <span className="input-group-text" id="basic-addon3"><span class="material-symbols-outlined">
                                                        toggle_on
                                                    </span></span>
                                                    <input type="text" disabled placeholder="Actif" name='new_balance' required className="form-control" id="new_balance" aria-label="name" aria-describedby="basic-addon3" />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <label htmlFor="new_balance" className="form-label">Balance</label>
                                                <div className="input-group">
                                                    <span className="input-group-text" id="basic-addon3"><span class="material-symbols-outlined">
                                                        account_balance_wallet
                                                    </span></span>
                                                    <input type="text" disabled placeholder="Balance" name='new_balance' required className="form-control" id="new_balance" aria-label="name" aria-describedby="basic-addon3" />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <label htmlFor="new_balance" className="form-label">Interet</label>
                                                <div className="input-group">
                                                    <span className="input-group-text" id="basic-addon3"><span class="material-symbols-outlined">
                                                        money
                                                    </span></span>
                                                    <input type="text" disabled placeholder="Interet" name='new_balance' required className="form-control" id="new_balance" aria-label="name" aria-describedby="basic-addon3" />
                                                </div>
                                            </div>
                                            <button type="button" class="btn btn-primary" data-mdb-ripple-init>Retirer</button>

                                        </form>
                            </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="btn btn-danger" onClick={closeModal}>
                <i className="fas fa-trash"></i> Fermer
              </Button>
            </Modal.Footer>
          </Modal>
          </div>
      <Footer />
    </>
  )
}

export default ListTransaction