/*eslint-disable*/
import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../Include/Sidebar";
import { useParams, Link } from "react-router-dom";
import Footer from "../../Include/Footer";
import { CustomerContext } from "../../context/CustomerContext";

function CustomerUpdate() {
  const { getCustomerById, updateCustomers } = useContext(CustomerContext);
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState({
    nom: "",
    prenom: "",
    surnom: "",
    tel: "",
    sol: "",
    nb_main: "",
    montant_jours: "",
  });
  const { accounts_id } = useParams();
  const user = { user_id: 1 }; // Assurez-vous que `user` est bien défini dans votre contexte ou état

  // Utilisation de getCustomerById pour récupérer les données du client
  useEffect(() => {
    getCustomerById(accounts_id).then((data) => {
      if (data) {
        setRecords({
          code: data.code || "",
          nom: data.nom || "",
          prenom: data.prenom || "",
          surnom: data.surnom || "",
          tel: data.tel || "",
          sol: data.sol || "",
          nb_main: data.nb_main || "",
          montant_jours: data.montant_jours || "",
        });
      }
    });
  }, [getCustomerById, accounts_id]);

  // Fonction pour gérer les changements dans les inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setRecords((prevRecords) => ({
      ...prevRecords,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { nom, prenom, tel, surnom, nb_main, sol, montant_jours } = records;

    let formData = new FormData();
    formData.append("nom", nom);
    formData.append("prenom", prenom);
    formData.append("tel", tel);
    formData.append("surnom", surnom);
    formData.append("nb_main", nb_main);
    formData.append("sol", sol);
    formData.append("montant_jours", montant_jours);
    formData.append("created_by", user.user_id); // Assurez-vous que `user.user_id` est correct

    if (surnom.length > 0) {
      setLoading(true); // Activer le loader

      try {
        await updateCustomers(accounts_id, formData); // Attendre la fin de l'opération
      } catch (error) {
        console.error("Erreur de connexion :", error);
      } finally {
        setLoading(false); // Désactiver le loader seulement après la fin de l'opération
      }
    }
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar />
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6 d-flex align-items-center">
                  <div className="col-sm-1 bg-blue rounded-lg p-2 text-center">
                    <i className="fas fa-info"></i>
                  </div>
                  <div className="ml-2">
                    <h3 className="display fw-12 text-black">
                      Détails du Compte:
                      <span className="fw-bold"> {records?.code || ""}</span>
                    </h3>
                  </div>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Accueil</Link>
                    </li>
                    <li className="breadcrumb-item">Compte</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <h5></h5>
                <div className="clearfix hidden-md-up"></div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Information du client</h3>

                      <div className="card-tools">
                        <Link
                          to="/customers"
                          type="button"
                          className="btn btn-success btn-sm"
                        >
                          <i className="fas fa-user-plus"></i> Liste des Clients
                        </Link>
                      </div>
                    </div>

                    <div className="card-body p-1">
                      <form className="forms-sample" onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="nom">Nom</label>
                              <input
                                type="text"
                                className="form-control"
                                id="nom"
                                placeholder="Nom"
                                name="nom"
                                value={records.nom}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="prenom">Prénom</label>
                              <input
                                type="text"
                                className="form-control"
                                id="prenom"
                                placeholder="Prénom"
                                name="prenom"
                                value={records.prenom}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="surnom">Surnom</label>
                              <input
                                type="text"
                                className="form-control"
                                id="surnom"
                                placeholder="Surnom"
                                required
                                name="surnom"
                                value={records.surnom}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="tel">Téléphone</label>
                              <input
                                type="number"
                                className="form-control"
                                id="tel"
                                placeholder="Téléphone"
                                name="tel"
                                value={records.tel}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <label htmlFor="sol" className="form-label">
                              Sol
                            </label>
                            <select
                              className="form-select"
                              name="sol"
                              required
                              id="sol"
                              value={records.sol}
                              onChange={handleChange}
                            >
                              <option value="">Choisir sol</option>
                              <option value="250">250 Gdes</option>
                              <option value="500">500 Gdes</option>
                              <option value="1000">1000 Gdes</option>
                              <option value="1500">1500 Gdes</option>
                            </select>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="nb_main">Nombre de main</label>
                              <input
                                type="number"
                                className="form-control"
                                id="nb_main"
                                placeholder="Nombre de main"
                                required
                                name="nb_main"
                                value={records.nb_main}
                                step="0.01"
                                min="0.5"
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="montant_jours">
                                Montant par jour
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="montant_jours"
                                placeholder="Montant/jour"
                                required
                                name="montant_jours"
                                value={records.montant_jours}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-md-12 mt-4 text-center">
                            <div className="form-group pt-1">
                              <button
                                type="submit"
                                className="btn btn-warning btn-sm px-3"
                                disabled={loading}
                              >
                                {loading ? (
                                  <>
                                    <i className="fas fa-spinner fa-spin"></i>{" "}
                                    Modification en cours...
                                  </>
                                ) : (
                                  <>
                                    <i className="fas fa-save"></i> Modifier
                                  </>
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>

                    <div className="card-footer text-center"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default CustomerUpdate;
