import React from 'react'
import Sidebar from '../../Include/Sidebar';
import { Link } from 'react-router-dom';
import Footer from '../../Include/Footer';
import { DataTransactionslocal } from '../../data/DataTransactionslocal';

function ListTransactionlocal() {

    return (
        <>
            <Sidebar />
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 d-flex align-items-center">
                                <div className="col-sm-1 bg-blue rounded-lg p-2 text-center">
                                    <i className="fas fa-exchange-alt"></i>
                                </div>
                                <div className="ml-2">
                                    <h3 className="display fw-500 fw-bold">Gestions des Transactions Local</h3>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Acceuil</Link></li>
                                    <li className="breadcrumb-item">Liste des Transactions Local</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid col-12 text-end ">
                        <Link className='btn btn-success ' to='/add-transactionlocal'>Nouvelle Transaction</Link>
                        <hr />
                        <div className="row mb-3">
                            <DataTransactionslocal />

                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    )
}

export default ListTransactionlocal