
const baseUrlAuth = 'https://ziginvestment.pythonanywhere.com/api/';
const baseUrlAccount = 'https://ziginvestment.pythonanywhere.com/api/accounts/';
const baseUrlAccount1 = 'https://ziginvestment.pythonanywhere.com/api/transactions/';
const baseUrlCustomers = 'https://ziginvestment.pythonanywhere.com/api/accounts/';
const baseUrlTimer = "https://ziginvestment.pythonanywhere.com/api/transactions/";
const baseUrlTransactions = "https://ziginvestment.pythonanywhere.com/api/transactions/";
const baseUrlTransactionsSol = 'https://ziginvestment.pythonanywhere.com/api/sol/';
const BaseUrlPhoto = 'https://ziginvestment.pythonanywhere.com';

// const baseUrlAuth = 'http://127.0.0.1:8000/api/';
// const baseUrlAccount = 'http://127.0.0.1:8000/api/accounts/';
// const baseUrlAccount1 = 'http://127.0.0.1:8000/api/transactions/';
// const baseUrlCustomers = 'http://127.0.0.1:8000/api/accounts/';
// const baseUrlTimer = 'http://127.0.0.1:8000/api/transactions/';
// const baseUrlTransactions = 'http://127.0.0.1:8000/api/transactions/';
// const baseUrlTransactionsSol = 'http://127.0.0.1:8000/api/sol/';
// const BaseUrlPhoto = 'http://127.0.0.1:8000';


export {
  baseUrlAuth,
  baseUrlAccount,
  baseUrlAccount1,
  baseUrlCustomers,
  baseUrlTimer,
  baseUrlTransactions,
  baseUrlTransactionsSol, BaseUrlPhoto
} 