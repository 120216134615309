/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import DataTable from "react-data-table-component";
import { customStyles } from "./Utility";
import AuthContext from "../context/AuthContext";
import LoaderData from "../Include/loaderData/loaderData";
const swal = require("sweetalert2");

const DataUsers = () => {
  const { getUsers, activateDeactivateUser } = useContext(AuthContext); // Récupérez la fonction getUsers du contexte d'authentification
  const [originalRecords, setOriginalRecords] = useState([]); // Initialisez avec un tableau vide
  const [records, setRecords] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUsers();
        setOriginalRecords(data); // Stockez les données récupérées dans originalRecords
        setRecords(data);
        setLoading(false);
        // console.log(data);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchData();
  }, [getUsers]);

  if (loading) {
    return <LoaderData />; // Affiche le loader tant que les données sont en cours de chargement
  }
  const columnsUser = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
      maxWidth: "30px",
    },
    {
      name: "Nom Complet",
      selector: (row) => (
        <div className="d-flex align-items-center">
          <img
            src={row.image}
            alt="Photo"
            style={{
              width: 30,
              height: 30,
              borderRadius: "50%",
              marginRight: 5,
            }}
          />
          {row.first_name + " " + row.last_name}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Nom Utilisateur",
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row.role,
      maxwidth: "50px",
      sortable: true,
      paddingRight: "0px",
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="btn-group" role="group">
          <Link
            to={"/detail-user/" + row.id}
            className="btn btn-primary btn-sm me-1"
          >
            <i className="fas fa-eye"></i>
          </Link>
          <button
            className={`btn btn-${
              row.is_active ? "success" : "danger"
            } btn-sm w-100 me-1`}
            onClick={() => handleActivateDeactivate(row.id)}
          >
            {row.is_active ? "Désactiver" : "Activer"}
          </button>
        </div>
      ),
    },
  ];
  const handleActivateDeactivate = async (id) => {
    try {
      await activateDeactivateUser(id);
      await refreshData();
    } catch (error) {
      console.error(error);
    }
  };
  const refreshData = async () => {
    try {
      const data = await getUsers();
      setOriginalRecords(data);
      setRecords(data);
    } catch (error) {
      console.error(error);
    }
  };
  function handleFilter(event) {
    const { value } = event.target;
    if (value === "") {
      setRecords(originalRecords); // Réinitialiser les données à leur valeur d'origine
      return;
    }

    const newData = originalRecords.filter((row) => {
      const nomLower = row.first_name.toLowerCase();
      const prenomLower = row.last_name.toLowerCase();
      const codeLower = row.username.toLowerCase();
      const searchLower = value.toLowerCase();
      return (
        nomLower.includes(searchLower) ||
        prenomLower.includes(searchLower) ||
        codeLower.includes(searchLower)
      );
    });

    setRecords(newData);
  }

  return (
    <>
      <div
        className="container-lg wow fadeIn rounded-lg"
        data-wow-delay="0.1s "
      >
        <div className="row">
          <div className="col-md-6 text-start d-flex align-items-center"></div>

          <div className="col-md-6 ">
            <input
              className="form-control mb-2"
              type="text"
              placeholder="Search"
              onChange={handleFilter}
            />
          </div>

          <div className="col-md-12 ">
            <DataTable
              columns={columnsUser}
              data={records}
              fixedHeader
              pagination
              customStyles={customStyles}
              paginationPerPage="10"
              paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              paginationComponentOptions={{
                rowsPerPageText: "Lignes par page:",
                rangeSeparatorText: "sur",
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: "Tous",
              }}
              subHeader
              noDataComponent="Aucune donnée trouvée"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default DataUsers;
