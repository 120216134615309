/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import DataTable from "react-data-table-component";
import { ExportDropdown, customStyles } from "./Utility";
import { Dropdown } from "react-bootstrap";
import { TransactionsContext } from "../context/TransactionsContext";
import { TransactionSolContext } from "../context/TransactionSolContext";
import LoaderData from "../Include/loaderData/loaderData";
const swal = require("sweetalert2");

const DataTransactionslocal = () => {
  const { getTransactions } = useContext(TransactionsContext); // Récupérez la fonction getAccounts du contexte d'authentification
  const [originalRecords, setOriginalRecords] = useState([]); // Initialisez avec un tableau vide
  const [records, setRecords] = useState();
  const [selectedTransactions, setSelectedTransactions] = useState("Type"); // État pour suivre la classe sélectionnée

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getTransactions();
        setOriginalRecords(data); // Stockez les données récupérées dans originalRecords
        setRecords(data);
        setLoading(false);
        // console.log(data);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchData();
  }, [getTransactions]);

  if (loading) {
    return <LoaderData />; // Affiche le loader tant que les données sont en cours de chargement
  }
  const columnsTransactions = [
    {
      name: "Categories",
      selector: (row) => row.num_compte,
      sortable: true,
    },
    {
      name: "Type Transaction",
      selector: (row) => row.type_transaction,
      sortable: true,
    },
    {
      name: "Montant",
      selector: (row) => row.montant,
      sortable: true,
    },
    {
      name: "Commision",
      selector: (row) => row.montant,
      sortable: true,
    },
    {
      name: "Ajouter par",
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.created_at,
      sortable: true,
    },
    // {
    //     name: "Actions",
    //     cell: (row) => (
    //         <div className="btn-group " role="group">
    //             <Link
    //                 to={"/views-Transactions/" + row.id}
    //                 className="btn btn-primary btn-sm me-1"
    //             >
    //                 <i className="fas fa-eye"></i>
    //             </Link>
    //         </div>
    //     ),
    //     maxWidth: "50px",
    // },
  ];

  const handleFilter = (event) => {
    const { value } = event.target;
    if (value === "") {
      setRecords(originalRecords); // Réinitialiser les données à leur valeur d'origine
      return;
    }
    const newData = originalRecords.filter((row) => {
      const nomLower = row.nom.toLowerCase();
      const num_compteLower = row.num_compte.toLowerCase();
      const searchLower = value.toLowerCase();
      return (
        nomLower.includes(searchLower) || num_compteLower.includes(searchLower)
      );
    });

    setRecords(newData);
  };

  const handleFilterByTransactions = (type_transaction) => {
    setSelectedTransactions(type_transaction);
    if (type_transaction === "Tous") {
      setRecords(originalRecords);
    } else {
      const newData = originalRecords.filter(
        (row) =>
          row.type_transaction.toLowerCase() === type_transaction.toLowerCase()
      );
      setRecords(newData);
    }
  };

  return (
    <>
      <div className="container-lg wow fadeIn " data-wow-delay="0.1s ">
        <div className="row">
          <div className="col-md-6 text-start d-flex align-items-center">
            <ExportDropdown
              id="dropdown-item-button"
              title={`Trier par ${selectedTransactions}`}
              menualign="right"
              variant="outline-primary btn btn-primary text-white btn-sm mb-0"
            >
              {/* <Dropdown.ItemText>Selectionner un type</Dropdown.ItemText> */}
              <Dropdown.Item
                as="button"
                onClick={() => handleFilterByTransactions("Type")}
              >
                Tous
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                onClick={() => handleFilterByTransactions("Zelle")}
              >
                Zelle
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                onClick={() => handleFilterByTransactions("Natcash")}
              >
                Natcash
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                onClick={() => handleFilterByTransactions("Moncash")}
              >
                Moncash
              </Dropdown.Item>
            </ExportDropdown>
          </div>

          <div className="col-md-6 ">
            <input
              className="form-control mb-2"
              type="text"
              placeholder="Search"
              onChange={handleFilter}
            />
          </div>

          <div className="col-md-12 ">
            <DataTable
              columns={columnsTransactions}
              data={records}
              fixedHeader
              pagination
              customStyles={customStyles}
              paginationPerPage="10"
              paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              paginationComponentOptions={{
                rowsPerPageText: "Lignes par page:",
                rangeSeparatorText: "sur",
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: "Tous",
              }}
              subHeader
              noDataComponent="Aucune donnée trouvée"
            />
          </div>
        </div>
      </div>
    </>
  );
};
const TransactionHistory = ({ AccountId }) => {
  const { getTransactionHistoryForAccount } = useContext(TransactionsContext);
  const [originalRecords, setOriginalRecords] = useState([]);
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getTransactionHistoryForAccount(AccountId); // Passez AccountId à la fonction
        setOriginalRecords(data);
        setRecords(data);
        setLoading(false);
        // console.log(data);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchData();
  }, [getTransactionHistoryForAccount, AccountId]); // Assurez-vous de dépendre de AccountId pour recharger les données en cas de changement

  if (loading) {
    return <LoaderData />; // Affiche le loader tant que les données sont en cours de chargement
  }
  const columnsTransactions = [
    {
      name: "Type Transaction",
      selector: (row) => (
        <div className="d-flex align-items-center">
          {row.type_transaction === "PL" ? "Plasman" : row.type_transaction}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Montant",
      selector: (row) => row.montant,
      sortable: true,
    },
    // {
    //   name: "Effectuer par",
    //   selector: (row) => row.username,
    //   sortable: true,
    // },
    {
      name: "Date",
      selector: (row) => row.created_at,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="btn-group " role="group">
          <Link
            // to={"/views-Transactions/" + row.id}
            className="btn btn-primary btn-sm me-1"
          >
            <i className="fas fa-eye"></i>
          </Link>
        </div>
      ),
      maxWidth: "50px",
    },
  ];

  const handleFilter = (event) => {
    const { value } = event.target;
    if (value === "") {
      setRecords(originalRecords); // Réinitialiser les données à leur valeur d'origine
      return;
    }
    const newData = originalRecords.filter((row) => {
      const nomLower = row.type_transaction.toLowerCase();
      const num_compteLower = row.montant.toLowerCase();
      const created_atLower = row.created_at.toLowerCase();
      const searchLower = value.toLowerCase();
      return (
        nomLower.includes(searchLower) ||
        num_compteLower.includes(searchLower) ||
        created_atLower.includes(searchLower)
      );
    });

    setRecords(newData);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-5 text-start d-flex align-items-center"></div>

        <div className="col-md-7">
          <input
            className="form-control"
            type="text"
            placeholder="Rechercher par Type, Date ou montant"
            onChange={handleFilter}
          />
        </div>

        <div className="col-12">
          <DataTable
            columns={columnsTransactions}
            data={records}
            fixedHeader
            pagination
            customStyles={customStyles}
            paginationPerPage="10"
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
            paginationComponentOptions={{
              rowsPerPageText: "Lignes par page:",
              rangeSeparatorText: "sur",
              noRowsPerPage: false,
              selectAllRowsItem: false,
              selectAllRowsItemText: "Tous",
            }}
            subHeader
            noDataComponent="Aucune donnée trouvée"
          />
        </div>
      </div>
    </>
  );
};
export { DataTransactionslocal, TransactionHistory };
