import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { baseUrlTimer } from './BaseUrl';
const baseUrl = baseUrlTimer;

const TimerContext = createContext();

export const TimerProvider = ({ children }) => {
  const [timeLeft, setTimeLeft] = useState({ hours: 0, minutes: 0, seconds: 0 });

  const fetchTimerData = async () => {
    try {
      const response = await axios.get(`${baseUrl}get-time-until-next-run/`);
      const newData = response.data;
      setTimeLeft(newData);
    } catch (error) {
      console.error('Erreur lors de l\'appel API', error);
    }
  };

  useEffect(() => {
    fetchTimerData();
    const apiInterval = setInterval(fetchTimerData, 3600000);

    return () => clearInterval(apiInterval);
  }, []);

  // useEffect(() => {
  //   const addInterestToAccount = async () => {
  //     try {
  //       await axios.post(`${baseUrl}add-interest/`);
  //       console.log('Intérêts ajoutés avec succès');
  //       // Fetch the timer data again to reset the timer
  //       fetchTimerData();
  //     } catch (error) {
  //       console.error('Erreur lors de l\'ajout des intérêts', error);
  //     }
  //   };

  //   const countdownInterval = setInterval(() => {
  //     setTimeLeft((prevTime) => {
  //       if (prevTime.seconds > 0) {
  //         return { ...prevTime, seconds: prevTime.seconds - 1 };
  //       } else {
  //         if (prevTime.minutes > 0) {
  //           return { ...prevTime, minutes: prevTime.minutes - 1, seconds: 59 };
  //         } else {
  //           if (prevTime.hours > 0) {
  //             return { ...prevTime, hours: prevTime.hours - 1, minutes: 59, seconds: 59 };
  //           } else {
  //             // Timer has reached 0, call add_interest_to_account
  //             addInterestToAccount();
  //             return { hours: 0, minutes: 0, seconds: 0 };
  //           }
  //         }
  //       }
  //     });
  //   }, 1000);

  //   return () => clearInterval(countdownInterval);
  // }, []);

  return (
    <TimerContext.Provider value={{ timeLeft, setTimeLeft }}>
      {children}
    </TimerContext.Provider>
  );
};

export const useTimer = () => useContext(TimerContext);
