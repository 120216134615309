import React from 'react'
import Footer from '../../Include/Footer'
import Sidebar from '../../Include/Sidebar'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

function WithdrawInterest() {
    return (
        <>
            <Sidebar />
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 d-flex align-items-center">
                                <div className="col-sm-1 bg-blue rounded-lg p-2 text-center">
                                    <span class="material-symbols-outlined"> currency_exchange</span>
                                </div>
                                <div className="ml-2">

                                    <h3 className="display fw-500 fw-bold">Retraits Intérêt</h3>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Tableau de Bord</Link></li>
                                    <li className="breadcrumb-item">Retraits Intérêt</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid col-12 text-end ">
                        <Link className="col-6 align-items-right" to='/transaction'>
                            <button className='btn btn-success'>Afficher</button>
                        </Link>
                        <hr />
                        <div className="row text-start">
                            <div className="col-xl-6 col-lg-5">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 className="m-0 font-weight-bold text-primary">Liste des comptes</h6>
                                    </div>
                                    <div className="card-body">
                                        <form className="row g-1 mx-auto" encType="multipart/form-data">

                                            <div className="col-md-9">
                                                <label htmlFor="num_compte" className="form-label">Numero Compte</label>
                                                <div className="input-group">
                                                    <span className="input-group-text" id="basic-addon3"><span className="material-symbols-outlined">
                                                        tag
                                                    </span></span>
                                                    <input type="text" placeholder="Numero Compte" name='num_compte' required className="form-control" id="num_compte" aria-label="name" aria-describedby="basic-addon3" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="name" className="form-label text-white">.</label>
                                                <div className="input-group">
                                                    <button type="submit" className="btn btn-primary">Rechercher</button>
                                                </div>
                                            </div>

                                        </form>
                                        <hr />
                                    </div>

                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-5">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 className="m-0 font-weight-bold text-primary">
                                        Retraits Intérêt
                                        </h6>
                                    </div>
                                    <div className="card-body">
                                        <form className="row g-3 mx-auto" encType="multipart/form-data">
                                            <div className="col-md-12">
                                                <label htmlFor="type_transaction" className="form-label">Duree</label>
                                                <div className="input-group">
                                                    <span className="input-group-text" id="basic-addon3"><span class="material-symbols-outlined">
                                                        timelapse
                                                    </span></span>
                                                    <select className="form-select" name='type_transaction' required aria-label="type_transaction" id="type_transaction" >
                                                        <option value="">Selectionner la duree</option>
                                                        <option value="truemonth">3 mois</option>
                                                        <option value="sixmonth">6 mois</option>
                                                        <option value="ninemonth">9 mois</option>
                                                        <option value="twelvemonth">12 mois</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <label htmlFor="new_balance" className="form-label">Numero Compte</label>
                                                <div className="input-group">
                                                    <span className="input-group-text" id="basic-addon3"><span className="material-symbols-outlined">
                                                        tag
                                                    </span></span>
                                                    <input type="text" disabled placeholder="0000-00000-0000" name='new_balance' required className="form-control" id="new_balance" aria-label="name" aria-describedby="basic-addon3" />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <label htmlFor="new_balance" className="form-label">Type Compte</label>
                                                <div className="input-group">
                                                    <span className="input-group-text" id="basic-addon3"><span class="material-symbols-outlined">
                                                        account_balance
                                                    </span></span>
                                                    <input type="text" disabled placeholder="Bousanm" name='new_balance' required className="form-control" id="new_balance" aria-label="name" aria-describedby="basic-addon3" />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <label htmlFor="new_balance" className="form-label">Etat du Compte</label>
                                                <div className="input-group">
                                                    <span className="input-group-text" id="basic-addon3"><span class="material-symbols-outlined">
                                                        toggle_on
                                                    </span></span>
                                                    <input type="text" disabled placeholder="Actif" name='new_balance' required className="form-control" id="new_balance" aria-label="name" aria-describedby="basic-addon3" />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <label htmlFor="new_balance" className="form-label">Balance</label>
                                                <div className="input-group">
                                                    <span className="input-group-text" id="basic-addon3"><span class="material-symbols-outlined">
                                                        account_balance_wallet
                                                    </span></span>
                                                    <input type="text" disabled placeholder="Balance" name='new_balance' required className="form-control" id="new_balance" aria-label="name" aria-describedby="basic-addon3" />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <label htmlFor="new_balance" className="form-label">Interet</label>
                                                <div className="input-group">
                                                    <span className="input-group-text" id="basic-addon3"><span class="material-symbols-outlined">
                                                        money
                                                    </span></span>
                                                    <input type="text" disabled placeholder="Interet" name='new_balance' required className="form-control" id="new_balance" aria-label="name" aria-describedby="basic-addon3" />
                                                </div>
                                            </div>
                                            <button type="button" class="btn btn-primary" data-mdb-ripple-init>Renouveler</button>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    )
}

export default WithdrawInterest
