/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../context/AuthContext";
import { Link } from "react-router-dom";
import Loader from "../Include/loader/loader";
import Carousel from "react-bootstrap/Carousel";
import { Link as ScrollLink } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import { Navbar as BootstrapNavbar, Nav, Container } from "react-bootstrap";
import { AccountContext } from "../context/AccountsContext";
import { useParams } from "react-router-dom";

export default function Register() {
  const { loginUser, isOnline } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "Bousanm | Register";
  }, []);
  const [accountType, setAccountType] = useState("");
  const [duree, setDuree] = useState("N/A"); // Définit la valeur par défaut à 'N/A'
  const { createAccount } = useContext(AccountContext);
  const { user } = useContext(AuthContext);

  const handleAccountTypeChange = (event) => {
    setAccountType(event.target.value);
    if (event.target.value !== "PL") {
      setDuree("N/A"); // Réinitialise la durée si le type de compte n'est pas Plasman
    }
  };

  const handleDureeChange = (event) => {
    setDuree(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const nom = e.target.nom.value;
    const prenom = e.target.prenom.value;
    const date_naissance = e.target.date_naissance.value;
    const lieu_naissance = e.target.lieu_naissance.value;
    const email = e.target.email.value;
    const tel = e.target.tel.value;
    const moncash = e.target.moncash.value;
    const natcash = e.target.natcash.value;
    const adresse = e.target.adresse.value;
    const statut = e.target.statut.value;
    const nif_cin = e.target.nif_cin.value;
    const type = e.target.type.value;
    const sexe = e.target.sexe.value;
    const devise = e.target.devise.value;

    let formData = new FormData();
    formData.append("nom", nom);
    formData.append("prenom", prenom);
    formData.append("date_naissance", date_naissance);
    formData.append("email", email);
    formData.append("lieu_naissance", lieu_naissance);
    formData.append("tel", tel);
    formData.append("moncash", moncash);
    formData.append("natcash", natcash);
    formData.append("adresse", adresse);
    formData.append("statut", statut);
    formData.append("nif_cin", nif_cin);
    formData.append("type", type);
    formData.append("sexe", sexe);
    formData.append("devise", devise);
    formData.append("created_by", user.user_id);

    if (accountType === "PL") {
      formData.append("duree", duree);
    } else {
      formData.append("duree", "N/A");
    }

    const imageFile = e.target.image.files[0];
    if (imageFile) {
      formData.append("type_piece", imageFile);
    }
    setLoading(true);
    try {
      nom.length > 0 && createAccount(formData);
    } catch (error) {
      console.error("Erreur de connexion :", error);
    } finally {
      setLoading(false); // Remettre loading à false après la fin du processus
    }
  };

  if (!isOnline) {
    return <Loader />;
  }

  return (
    <>
      <div
        className="container-fluid topbar px-0 px-lg-4 bg-light py-2 d-none d-lg-block"
        id="navbar"
      >
        <div className="container">
          <div className="row gx-0 align-items-center">
            <div className="col-lg-8 text-center text-lg-start mb-lg-0">
              <div className="d-flex flex-wrap">
                <div className="border-end border-primary pe-3">
                  <a href="#" className="text-muted small">
                    <i className="fa fa-clock text-primary me-2"></i>7/7 | 24/24
                  </a>
                </div>
                <div className="ps-3">
                  <a
                    href="mailto:zig.bousanm@gmail.com"
                    className="text-muted small"
                  >
                    <i className="fas fa-envelope text-primary me-2"></i>
                    zig.bousanm@gmail.com
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 text-center text-lg-end">
              <div className="d-flex justify-content-end">
                <div className="d-flex border-end border-primary pe-3">
                  <a className="btn p-0 text-primary me-3" href="#">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a className="btn p-0 text-primary me-3" href="#">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a
                    className="btn p-0 text-primary me-3"
                    href="https://www.instagram.com/bousanm"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a className="btn p-0 text-primary me-0" href="#">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Topbar End */}

      {/* Navbar & Hero Start */}
      <div className="container-fluid nav-bar px-0 px-lg-4 py-lg-0">
        <Container>
          <BootstrapNavbar expand="lg" className="navbar-light bg-white">
            <BootstrapNavbar.Brand href="#">
              <h1 className="display-7 text-primary mb-0">
                <img src="/img/logo.png" style={{ width: "60px" }} alt="Logo" />
                Bousanm
              </h1>
            </BootstrapNavbar.Brand>
            <BootstrapNavbar.Toggle className="p-1 btn-sm border border-success d-md-none">
              <RouterLink
                to="/"
                className="btn btn-primary ml-auto rounded-pill flex-shrink-0 btn-sm float-end d-md-none"
              >
                <i className="fas fa-home"></i>
              </RouterLink>
            </BootstrapNavbar.Toggle>
          </BootstrapNavbar>
        </Container>
      </div>
      {/*  Contact Start */}
      <div className="container-fluid contact bg-light " id="contact">
        <div className="container py-5">
          <RouterLink
            to="/"
            className="d-none d-sm-inline-block btn btn-primary rounded-pill py-2 px-4 ms-3 btn-sm float-end"
          >
            <i className="fas fa-home"></i>
          </RouterLink>
          <div
            className="text-center mx-auto pb-5 wow fadeInUp"
            data-wow-delay="0.2s"
            style={{ maxWidth: "800px" }}
          >
            <h4 className="display-7 text-primary">Inscription </h4>
          </div>
          <div className="row g-5">
            <div className="col-xl-7 wow fadeInRight" data-wow-delay="0.4s">
              <div>
                {/* <form onSubmit={handleSubmit}>
                                    <div className="row g-3">
                                        <div className="col-lg-12">
                                            <div className="form-floating">
                                                <input type="text" className="form-control border-0" placeholder="Nom Utilisateur" id='name' name='username' required />
                                                <label for="name">Nom Utilisateur</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-floating">
                                                <input type="password" className="form-control border-0" placeholder="Password" id='pass' name='password' required />
                                                <label for="pass">Mot de passe</label>
                                            </div>
                                        </div>
                                        <div className='forgot-pwd'>
                                            <Link className='pwd' to="/forgot_password">Mot de passe <span>oublier ?</span> </Link>
                                        </div>
                                        <div className="col-12">
                                            <button type='submit' className="btn btn-primary w-100 py-2 btn-sm">Se Connecter</button>
                                        </div>
                                    </div>
                                </form> */}

                <form
                  className="row g-3 mx-auto card-body bg-white p-0"
                  onSubmit={handleSubmit}
                  encType="multipart/form-data"
                >
                  <div className="col-md-6">
                    <label htmlFor="name" className="form-label">
                      Nom
                    </label>
                    <div className="input-group">
                      <span className="input-group-text " id="basic-addon3">
                        <span className="material-symbols-outlined ">
                          person
                        </span>
                      </span>
                      <input
                        type="text"
                        placeholder="Joseph"
                        name="nom"
                        required
                        className="form-control "
                        id="name"
                        aria-label="name"
                        aria-describedby="basic-addon3"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="prenom" className="form-label">
                      Prenom
                    </label>
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon4">
                        <span className="material-symbols-outlined">
                          person
                        </span>
                      </span>
                      <input
                        type="text"
                        name="prenom"
                        placeholder="Jeanne"
                        required
                        className="form-control"
                        id="prenom"
                        aria-label="prenom"
                        aria-describedby="basic-addon4"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon5">
                        <span className="material-symbols-outlined">
                          alternate_email
                        </span>
                      </span>
                      <input
                        type="email"
                        placeholder="jeannejoseph@gmail.com"
                        name="email"
                        className="form-control"
                        id="email"
                        aria-label="email"
                        aria-describedby="basic-addon5"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Numero_Whatsapp" className="form-label">
                      Telephone
                    </label>
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon6">
                        <span className="material-symbols-outlined">chat</span>
                      </span>
                      <input
                        type="text"
                        name="tel"
                        placeholder="+50912345678"
                        className="form-control"
                        id="Numero_Whatsapp"
                        aria-label="Numero_Whatsapp"
                        aria-describedby="basic-addon6"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Adresse" className="form-label">
                      Adresse
                    </label>
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon10">
                        <span className="material-symbols-outlined">map</span>
                      </span>
                      <input
                        type="text"
                        name="adresse"
                        placeholder="12 rue 7 P-Ville"
                        className="form-control"
                        id="Adresse"
                        aria-label="Adresse"
                        aria-describedby="basic-addon10"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label for="status" className="form-label">
                      Statut
                    </label>
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon12">
                        <span className="material-symbols-outlined">grade</span>
                      </span>
                      <input
                        type="text"
                        name="statut"
                        required
                        placeholder="Marchand | Etudiant..."
                        className="form-control"
                        id="status"
                        aria-label="status"
                        aria-describedby="basic-addon12"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Type" className="form-label">
                      Devise
                    </label>
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon12">
                        <span className="material-symbols-outlined">
                          credit_card
                        </span>
                      </span>
                      <select
                        className="form-select"
                        name="devise"
                        required
                        aria-label="Devise"
                        id="Type"
                        // onChange={handleAccountTypeChange}
                      >
                        <option value="">Choisir une devise</option>
                        <option value="HTG">HTG</option>
                        <option value="USD">USD</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Type" className="form-label">
                      Type de compte
                    </label>
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon12">
                        <span className="material-symbols-outlined">
                          credit_card
                        </span>
                      </span>
                      <select
                        className="form-select"
                        name="type"
                        required
                        aria-label="Type de compte"
                        id="Type"
                        onChange={handleAccountTypeChange}
                      >
                        <option value="">Choisir un type</option>
                        <option value="BS">Bousanm</option>
                        <option value="PL">Plasman</option>
                      </select>
                    </div>
                  </div>
                  {accountType === "PL" && (
                    <div className="col-md-6">
                      <label htmlFor="duree" className="form-label">
                        Durée
                      </label>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon13">
                          <span className="material-symbols-outlined">
                            timer
                          </span>
                        </span>
                        <select
                          className="form-select"
                          name="duree"
                          aria-label="Durée"
                          id="duree"
                          value={duree}
                          onChange={handleDureeChange}
                        >
                          <option value="N/A">N/A</option>
                          <option value="3">3 Mois</option>
                          <option value="6">6 Mois</option>
                          <option value="9">9 Mois</option>
                          <option value="12">12 Mois</option>
                        </select>
                      </div>
                    </div>
                  )}

                  <div className="col-md-12 mb-2">
                    <button
                      type="submit"
                      className="btn btn-primary btn-sm"
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <i className="fas fa-spinner fa-spin"></i>{" "}
                          Enregistrement en cours...
                        </>
                      ) : (
                        <>
                          <i className="fas fa-save"></i> Enregistrer
                        </>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-xl-5 wow fadeInLeft" data-wow-delay="0.2s">
              <div className="contact-img d-flex justify-content-center">
                <div className="contact-img-inner">
                  <img
                    src="/img/login-img-1.png"
                    className="img-fluid w-100"
                    alt="Image Contact"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
