/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../context/AuthContext';
import { jwtDecode } from 'jwt-decode'
// import AuthContext from '../context/AuthContext'
import { format } from "date-fns";
import { fr } from 'date-fns/locale';
import Logo from './logo1.png'
import { BaseUrlPhoto } from '../context/BaseUrl';
const BaseUrl = BaseUrlPhoto;

function Sidebar() {
  const { logoutUser } = useContext(AuthContext)
  const token = localStorage.getItem("authTokens")
  const [user, setUser] = useState(null);
  // Utilisez useState pour stocker la date et l'heure de la connexion
  const [loginTime, setLoginTime] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false); // Ajout de l'état dropdownOpen
  const currentYear = new Date().getFullYear();
  // Calculer l'année académique en fonction de l'année actuelle
  const academicYear = `${currentYear}-${currentYear + 1}`;

  if (token) {
    const decoded = jwtDecode(token)
    var user_id = decoded.user_id
    var username = decoded.username
    var role = decoded.role
    var image = decoded.image
  }

  const getUserFromLocalStorage = () => {
    if (token) {
      return JSON.parse(token).user;
    }
    return null;
  };

  useEffect(() => {
    const userData = getUserFromLocalStorage();
    if (userData) {
      setUser(userData);
    }
  }, []);

  useEffect(() => {
    if (token) {
      const decoded = jwtDecode(token);
      const loginDate = new Date(decoded.iat * 1000);
      const formattedDate = format(loginDate, "EE dd MMMM HH:mm", { locale: fr });

      const parts = formattedDate.split(' ');
      const capitalizedDay = parts[0].charAt(0).toUpperCase() + parts[0].slice(1);
      const capitalizedMonth = parts[2].charAt(0).toUpperCase() + parts[2].slice(1);

      const finalFormattedDate = `${capitalizedDay} ${parts[1]} ${capitalizedMonth} | ${parts[3]}`;

      setLoginTime(finalFormattedDate);
    }
  }, [token]);

  const imageUrl = user && user.image ? `${BaseUrl}${user.image}` : '';

  const [MenuOpen1, setMenuOpen1] = useState(false);
  const toggleMenu1 = () => {
    setMenuOpen1(!MenuOpen1);
  };

  const [MenuOpen2, setMenuOpen2] = useState(false);
  const toggleMenu2 = () => {
    setMenuOpen2(!MenuOpen2);
  };

  const [MenuOpen3, setMenuOpen3] = useState(false);
  const toggleMenu3 = () => {
    setMenuOpen3(!MenuOpen3);
  };

  const [MenuOpen4, setMenuOpen4] = useState(false);
  const toggleMenu4 = () => {
    setMenuOpen4(!MenuOpen3);
  };

  return (
    <>
      {/* Nav */}
      <nav className="main-header pt-2 pe-3 navbar-expand navbar-light " style={{ display: 'flex', height: '70px', width: "auto" }}>
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <Link to="/dashboard" className="nav-link">Tableau de Bord</Link>
          </li>
        </ul>

        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown">
            {user && (
              <img src={imageUrl}
                alt="User Avatar" className="img-size-32 mr-3 mt-1 img-circle" onClick={() => setDropdownOpen(!dropdownOpen)} />
            )}
            <div className={`dropdown-menu dropdown-menu-xl dropdown-menu-right ${dropdownOpen ? 'show' : ''}`} style={{ width: "220px" }}>
              <Link to={'/user-profile/' + user_id}
                className="dropdown-item">
                <div className="media ">
                  <div className="media-body">
                    <h2 className="dropdown-item-title fw-bold ">
                      {user && user.username}
                      <span className="float-right text-sm text-success"><i className="fas fa-sign-in-alt"></i></span>
                    </h2>
                    <p className="text-sm text-success text-center">Connecté le :</p>
                    <p className="text-sm text-muted"><i className="far fa-clock mr-1"></i> {loginTime}</p>
                  </div>
                </div>
              </Link>
              <Link to='#' onClick={logoutUser}
                className="dropdown-item dropdown-footer bg-danger btn-sm text-white text-center fw-bold">
                <i className='fas fa-sign-out-alt'></i> Déconnecter
              </Link>
            </div>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-widget="fullscreen" href="#" role="button">
              <i className="fas fa-expand-arrows-alt"></i>
            </a>
          </li>
        </ul>
      </nav>
      {/* Nav */}
      {/* Sidebar */}
      <aside className="main-sidebar sidebar-dark-primary elevation-4 bg-primary">
        <Link to="/dashboard" style={{ height: '60px' }}
          className="brand-link">
          <img src={Logo} alt="Logo" className="brand-image  " style={{ opacity: '.9', width: "50px", height: '100px' }} />
          <h4><span className="brand-text fw-bold text-white">Bousanm</span></h4>
          <hr />
        </Link>

        <div className="sidebar">
          <nav className="mt-2 ">
            <ul className="nav nav-pills nav-sidebar flex-column " data-widget="treeview" role="menu" data-accordion="false">
              <li className="nav-item">
                <Link to="/dashboard" className="nav-link">
                  <i className="nav-icon fas fa-tachometer-alt text-white"></i>
                  <p className='fw-bold text-white'>
                    Tableau de bord
                  </p>
                </Link>
              </li>
              <>
                <li className="nav-header fw-bold text-white">COMPTES</li>
                <li className={`nav-item ${MenuOpen1 ? 'menu-open' : ''}`}>
                  <a href="#" className="nav-link" onClick={toggleMenu1}>
                    <i className="nav-icon fas fa-file-invoice text-white"></i>
                    <p className='text-white'>Gestion Comptes<i className="fas fa-angle-left right text-white"></i></p>
                  </a>
                  <ul className={`nav nav-treeview ${MenuOpen1 ? 'slide-in' : 'slide-out'}`}>
                    <li className="nav-item">
                      <Link to="/List" className="nav-link">
                        <i className="fas fa-money-bill nav-icon text-white"></i>
                        <p className='text-white'>Nouveau Compte</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/transaction" className="nav-link">
                        <i className="fas fa-exchange-alt nav-icon text-white"></i>
                        <p className='text-white'>Gestion Transactions </p>
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
              <>
                <li className="nav-header fw-bold text-white">MUTUELLE</li>
                <li className={`nav-item ${MenuOpen2 ? 'menu-open' : ''}`}>
                  <a href="#" className="nav-link" onClick={toggleMenu2}>
                    <i className="nav-icon fas fa-file-invoice-dollar text-white"></i>
                    <p className='text-white'>Gestion Mutuelle<i className="fas fa-angle-left right"></i></p>
                  </a>
                  <ul className={`nav nav-treeview ${MenuOpen2 ? 'slide-in' : 'slide-out'}`}>
                    <li className="nav-item">
                      <Link to="/customers" className="nav-link">
                        <i className="fas fa-user-plus nav-icon text-white"></i>
                        <p className='text-white'>Clients Sol</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/customers-mutuelle" className="nav-link">
                        <i className="fas fa-user-plus nav-icon text-white"></i>
                        <p className='text-white'>Clients Mutuelle</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/sol-transactions" className="nav-link">
                        <i className="fas fa-list nav-icon text-white"></i>
                        <p className='text-white'>Transactions Sol</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/mutuelle-transactions" className="nav-link">
                        <i className="fas fa-list nav-icon text-white"></i>
                        <p className='text-white'>Transactions Mutuelle</p>
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
              <>
                <li className="nav-header fw-bold text-white">ZELLE | MONCASH | NATCASH</li>
                <li className={`nav-item ${MenuOpen3 ? 'menu-open' : ''}`}>
                  <a href="#" className="nav-link" onClick={toggleMenu3}>
                    <i className="fas fa-exchange-alt nav-icon text-white"></i>
                    <p className='text-white'>Transac. Local | Int.<i className="fas fa-angle-left right"></i></p>
                  </a>
                  <ul className={`nav nav-treeview ${MenuOpen3 ? 'slide-in' : 'slide-out'}`}>
                    <li className="nav-item">
                      <Link to="/transactionlocal" className="nav-link">
                        <i className="fas fa-exchange-alt nav-icon text-white"></i>
                        <p className='text-white'>Nouv. Transaction</p>
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                      <Link to="/customers-mutuelle" className="nav-link">
                        <i className="fas fa-user-plus nav-icon text-white"></i>
                        <p className='text-white'>Clients Mutuelle</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/sol-transactions" className="nav-link">
                        <i className="fas fa-list nav-icon text-white"></i>
                        <p className='text-white'>Transactions Sol</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/mutuelle-transactions" className="nav-link">
                        <i className="fas fa-list nav-icon text-white"></i>
                        <p className='text-white'>Transactions Mutuelle</p>
                      </Link>
                    </li>*/}
                  </ul>
                </li>
              </>
              <>
                <li className="nav-header fw-bold text-white">ADMINISTRATION</li>
                <li className={`nav-item ${MenuOpen4 ? 'menu-open' : ''}`}>
                  <a href="#" className="nav-link" onClick={toggleMenu4}>
                    <i className="nav-icon fas fa-cog text-white"></i>
                    <p className='text-white'>Paramètres <i className="fas fa-angle-left right text-white"></i></p>
                  </a>
                  <ul className={`nav nav-treeview ${MenuOpen4 ? 'slide-in' : 'slide-out'}`}>
                    <li className="nav-item">
                      <Link to="/users" className="nav-link">
                        <i className="fas fa-user-plus nav-icon text-white"></i>
                        <p className='text-white'>Gestion Utilisateurs</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/" className="nav-link">
                        <i className="fas fa-user-plus nav-icon text-white"></i>
                        <p className='text-white'>Gestion Sol</p>
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            </ul>
          </nav>
        </div>
      </aside>
      {/* End Sidebar */}
    </>
  )
}

export default Sidebar
