import React, { useContext, useState } from "react";
import Sidebar from "../../Include/Sidebar";
import { Link } from "react-router-dom";
import { AccountContext } from "../../context/AccountsContext";
import AuthContext from "../../context/AuthContext";
import Footer from "../../Include/Footer";

function AddAcount() {
  const [accountType, setAccountType] = useState("");
  const [duree, setDuree] = useState("N/A"); // Définit la valeur par défaut à 'N/A'
  const { createAccount } = useContext(AccountContext);
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const handleAccountTypeChange = (event) => {
    setAccountType(event.target.value);
    if (event.target.value !== "PL") {
      setDuree("N/A"); // Réinitialise la durée si le type de compte n'est pas Plasman
    }
  };

  const handleDureeChange = (event) => {
    setDuree(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const nom = e.target.nom.value;
    const prenom = e.target.prenom.value;
    const date_naissance = e.target.date_naissance.value;
    const lieu_naissance = e.target.lieu_naissance.value;
    const email = e.target.email.value;
    const tel = e.target.tel.value;
    const moncash = e.target.moncash.value;
    const natcash = e.target.natcash.value;
    const adresse = e.target.adresse.value;
    const statut = e.target.statut.value;
    const nif_cin = e.target.nif_cin.value;
    const type = e.target.type.value;
    const sexe = e.target.sexe.value;
    const devise = e.target.devise.value;

    let formData = new FormData();
    formData.append("nom", nom);
    formData.append("prenom", prenom);
    formData.append("date_naissance", date_naissance);
    formData.append("email", email);
    formData.append("lieu_naissance", lieu_naissance);
    formData.append("tel", tel);
    formData.append("moncash", moncash);
    formData.append("natcash", natcash);
    formData.append("adresse", adresse);
    formData.append("statut", statut);
    formData.append("nif_cin", nif_cin);
    formData.append("type", type);
    formData.append("sexe", sexe);
    formData.append("devise", devise);
    formData.append("created_by", user.user_id);

    if (accountType === "PL") {
      formData.append("duree", duree);
    } else {
      formData.append("duree", "N/A");
    }

    const imageFile = e.target.image.files[0];
    if (imageFile) {
      formData.append("type_piece", imageFile);
    }

    setLoading(true);

    if (nom.length > 0) {
      try {
        await createAccount(formData); // Attendre la fin de la requête
      } catch (error) {
        console.error("Erreur lors de la création du compte :", error);
      }
    }

    setLoading(false); // Désactiver le loader après l'exécution de createAccount
  };

  return (
    <>
      <Sidebar />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6 d-flex align-items-center">
                <div className="col-sm-1 bg-blue rounded-lg p-2 text-center">
                  <i className="fas fa-money-bill"></i>
                </div>
                <div className="ml-2">
                  <h3 className="display fw-12">Enregistrement des Comptes</h3>
                </div>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Acceuil</Link>
                  </li>
                  <li className="breadcrumb-item">Nouveau Comptes</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="clearfix hidden-md-up"></div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">Formulaire d'enregistrement</h5>

                    <div className="card-tools">
                      <Link
                        className="col-6 align-items-right"
                        to="/View-account"
                      >
                        <button className="btn btn-success">Afficher</button>
                      </Link>
                    </div>
                  </div>

                  <div className="card-body p-1">
                    <div className="text-start">
                      <form
                        className="row g-3 mx-auto"
                        onSubmit={handleSubmit}
                        encType="multipart/form-data"
                      >
                        <div className="col-md-6">
                          <label htmlFor="name" className="form-label">
                            Nom
                          </label>
                          <div className="input-group">
                            <span
                              className="input-group-text"
                              id="basic-addon3"
                            >
                              <span className="material-symbols-outlined">
                                person
                              </span>
                            </span>
                            <input
                              type="text"
                              placeholder="Joseph"
                              name="nom"
                              required
                              className="form-control"
                              id="name"
                              aria-label="name"
                              aria-describedby="basic-addon3"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="prenom" className="form-label">
                            Prenom
                          </label>
                          <div className="input-group">
                            <span
                              className="input-group-text"
                              id="basic-addon4"
                            >
                              <span className="material-symbols-outlined">
                                person
                              </span>
                            </span>
                            <input
                              type="text"
                              name="prenom"
                              placeholder="Jeanne"
                              required
                              className="form-control"
                              id="prenom"
                              aria-label="prenom"
                              aria-describedby="basic-addon4"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="Date_Nais" className="form-label">
                            Date Naissance
                          </label>
                          <div className="input-group">
                            <span
                              className="input-group-text"
                              id="basic-addon2"
                            >
                              <span className="material-symbols-outlined">
                                calendar_month
                              </span>
                            </span>
                            <input
                              type="date"
                              name="date_naissance"
                              required
                              className="form-control"
                              id="Date_Nais"
                              aria-label="Date_Nais"
                              aria-describedby="basic-addon2"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="lieu" className="form-label">
                            Lieu de Naissance
                          </label>
                          <div className="input-group">
                            <span
                              className="input-group-text"
                              id="basic-addon2"
                            >
                              <span className="material-symbols-outlined">
                                map
                              </span>
                            </span>
                            <input
                              type="text"
                              name="lieu_naissance"
                              placeholder="Cap-Haitien"
                              required
                              className="form-control"
                              id="lieu"
                              aria-label="lieu"
                              aria-describedby="basic-addon2"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="Nif_Cin" className="form-label">
                            Sexe
                          </label>
                          <div className="input-group">
                            <span
                              className="input-group-text"
                              id="basic-addon9"
                            >
                              <span className="material-symbols-outlined">
                                Wc
                              </span>
                            </span>
                            <select
                              className="form-select"
                              name="sexe"
                              required
                              aria-label="Type de compte"
                              id="Nif_Cin"
                            >
                              <option value="">Choisir sexe</option>
                              <option value="M">Masculin</option>
                              <option value="F">Feminin</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="email" className="form-label">
                            Email
                          </label>
                          <div className="input-group">
                            <span
                              className="input-group-text"
                              id="basic-addon5"
                            >
                              <span className="material-symbols-outlined">
                                alternate_email
                              </span>
                            </span>
                            <input
                              type="email"
                              placeholder="jeannejoseph@gmail.com"
                              name="email"
                              className="form-control"
                              id="email"
                              aria-label="email"
                              aria-describedby="basic-addon5"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label
                            htmlFor="Numero_Whatsapp"
                            className="form-label"
                          >
                            Numero Whatsapp
                          </label>
                          <div className="input-group">
                            <span
                              className="input-group-text"
                              id="basic-addon6"
                            >
                              <span className="material-symbols-outlined">
                                chat
                              </span>
                            </span>
                            <input
                              type="text"
                              name="tel"
                              placeholder="+50912345678"
                              className="form-control"
                              id="Numero_Whatsapp"
                              aria-label="Numero_Whatsapp"
                              aria-describedby="basic-addon6"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label
                            htmlFor="Numero_Moncash"
                            className="form-label"
                          >
                            Numero Moncash
                          </label>
                          <div className="input-group">
                            <span
                              className="input-group-text"
                              id="basic-addon7"
                            >
                              <span className="material-symbols-outlined">
                                call
                              </span>
                            </span>
                            <input
                              type="text"
                              name="moncash"
                              placeholder="+50912345678"
                              className="form-control"
                              id="Numero_Moncash"
                              aria-label="Numero_Moncash"
                              aria-describedby="basic-addon7"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label
                            htmlFor="Numero_Natcash"
                            className="form-label"
                          >
                            Numero Natcash
                          </label>
                          <div className="input-group">
                            <span
                              className="input-group-text"
                              id="basic-addon8"
                            >
                              <span className="material-symbols-outlined">
                                call
                              </span>
                            </span>
                            <input
                              type="text"
                              name="natcash"
                              placeholder="+50912345678"
                              className="form-control"
                              id="Numero_Natcash"
                              aria-label="Numero_Natcash"
                              aria-describedby="basic-addon8"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="Nif_Cin" className="form-label">
                            Nif Cin
                          </label>
                          <div className="input-group">
                            <span
                              className="input-group-text"
                              id="basic-addon9"
                            >
                              <span className="material-symbols-outlined">
                                credit_card
                              </span>
                            </span>
                            <input
                              type="text"
                              name="nif_cin"
                              placeholder="1234-567-890-1234"
                              required
                              className="form-control"
                              id="Nif_Cin"
                              aria-label="Nif_Cin"
                              aria-describedby="basic-addon9"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="Adresse" className="form-label">
                            Adresse
                          </label>
                          <div className="input-group">
                            <span
                              className="input-group-text"
                              id="basic-addon10"
                            >
                              <span className="material-symbols-outlined">
                                map
                              </span>
                            </span>
                            <input
                              type="text"
                              name="adresse"
                              placeholder="12 rue 7 P-Ville"
                              className="form-control"
                              id="Adresse"
                              aria-label="Adresse"
                              aria-describedby="basic-addon10"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label for="status" className="form-label">
                            Statut
                          </label>
                          <div className="input-group">
                            <span
                              className="input-group-text"
                              id="basic-addon12"
                            >
                              <span className="material-symbols-outlined">
                                grade
                              </span>
                            </span>
                            <input
                              type="text"
                              name="statut"
                              required
                              placeholder="Marchand | Etudiant..."
                              className="form-control"
                              id="status"
                              aria-label="status"
                              aria-describedby="basic-addon12"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="Type" className="form-label">
                            Devise
                          </label>
                          <div className="input-group">
                            <span
                              className="input-group-text"
                              id="basic-addon12"
                            >
                              <span className="material-symbols-outlined">
                                credit_card
                              </span>
                            </span>
                            <select
                              className="form-select"
                              name="devise"
                              required
                              aria-label="Devise"
                              id="Type"
                              // onChange={handleAccountTypeChange}
                            >
                              <option value="">Choisir une devise</option>
                              <option value="HTG">HTG</option>
                              <option value="USD">USD</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="Type" className="form-label">
                            Type de compte
                          </label>
                          <div className="input-group">
                            <span
                              className="input-group-text"
                              id="basic-addon12"
                            >
                              <span className="material-symbols-outlined">
                                credit_card
                              </span>
                            </span>
                            <select
                              className="form-select"
                              name="type"
                              required
                              aria-label="Type de compte"
                              id="Type"
                              onChange={handleAccountTypeChange}
                            >
                              <option value="">Choisir un type</option>
                              <option value="BS">Bousanm</option>
                              <option value="PL">Plasman</option>
                            </select>
                          </div>
                        </div>
                        {accountType === "PL" && (
                          <div className="col-md-6">
                            <label htmlFor="duree" className="form-label">
                              Durée
                            </label>
                            <div className="input-group">
                              <span
                                className="input-group-text"
                                id="basic-addon13"
                              >
                                <span className="material-symbols-outlined">
                                  timer
                                </span>
                              </span>
                              <select
                                className="form-select"
                                name="duree"
                                aria-label="Durée"
                                id="duree"
                                value={duree}
                                onChange={handleDureeChange}
                              >
                                <option value="N/A">N/A</option>
                                <option value="3">3 Mois</option>
                                <option value="6">6 Mois</option>
                                <option value="9">9 Mois</option>
                                <option value="12">12 Mois</option>
                              </select>
                            </div>
                          </div>
                        )}
                        <div className="col-md-6">
                          <label htmlFor="image_piece" className="form-label">
                            Pièce Identification
                          </label>
                          <div className="input-group">
                            <span
                              className="input-group-text"
                              id="basic-addon10"
                            >
                              <span className="material-symbols-outlined">
                                verified
                              </span>
                            </span>{" "}
                            <input
                              type="file"
                              className="form-control"
                              id="exampleInputFile"
                              accept="image/*"
                              name="image"
                            />
                          </div>
                        </div>

                        <div className="col-md-12 text-end">
                          <button
                            type="submit"
                            className="btn btn-primary btn-sm"
                            disabled={loading}
                          >
                            {loading ? (
                              <>
                                <i className="fas fa-spinner fa-spin"></i>{" "}
                                Enregistrement en cours...
                              </>
                            ) : (
                              <>
                                <i className="fas fa-save"></i> Enregistrer
                              </>
                            )}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="card-footer text-center"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default AddAcount;
