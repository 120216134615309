import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../Include/Sidebar";
import { AccountContext } from "../../context/AccountsContext";
import { useParams, Link } from "react-router-dom";
import Footer from "../../Include/Footer";

function ViewsAccount() {
  const { getAccountById } = useContext(AccountContext);
  const [accountType, setAccountType] = useState("");
  const [records, setRecords] = useState({});
  const { accounts_id } = useParams();
  const [profileImageView, setProfileImageView] = useState(null);

  useEffect(() => {
    getAccountById(accounts_id).then((data) => {
      setRecords(data);
      setAccountType(records?.type || "");
      if (data.image) {
        setProfileImageView(data.image);
      }
    });
  }, [getAccountById, accounts_id, records?.type]);
  return (
    <>
      <div className="wrapper">
        <Sidebar />
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6 d-flex align-items-center">
                  <div className="col-sm-1 bg-blue rounded-lg p-2 text-center">
                    <i className="fas fa-info"></i>
                  </div>
                  <div className="ml-2">
                    <h3 className="display fw-12 ">
                      Details du Compte:
                      <span className="fw-bold">
                        {" "}
                        {records?.num_compte || ""}
                      </span>{" "}
                    </h3>
                  </div>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Acceuil</Link>
                    </li>
                    <li className="breadcrumb-item">Compte</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <section className="content">
            <div className="container-fluid col-12 text-start ">
              <div className="card p-3">
                <div className="col-md-12 mb-2">
                  <div
                    className={`btn rounded-lg ${
                      records?.etat === "actif" ? "btn-success" : "btn-danger"
                    }`}
                  >
                    Etat du Compte : {records?.etat || ""}
                  </div>
                  <Link
                    to="/List"
                    className="btn btn-primary rounded-lg float-end"
                  >
                    <i className="fa fa-arrow-left"></i>
                  </Link>
                  <hr />
                </div>
                <form className="row g-3 mx-auto" encType="multipart/form-data">
                  <div className="col-md-6">
                    <label htmlFor="profil">Photo</label>
                    <div className="form-group">
                      <img
                        src={
                          profileImageView ||
                          (records && records.type_piece) ||
                          ""
                        }
                        id="profil"
                        alt="piece"
                        style={{
                          maxWidth: "250px",
                          maxHeight: "100px",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="name" className="form-label">
                      Nom
                    </label>
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon3">
                        <span className="material-symbols-outlined">
                          person
                        </span>
                      </span>
                      <input
                        type="text"
                        placeholder="Joseph"
                        name="nom"
                        readOnly
                        value={records?.nom || ""}
                        onChange={(e) =>
                          setRecords({ ...records, nom: e.target.value })
                        }
                        required
                        className="form-control"
                        id="name"
                        aria-label="name"
                        aria-describedby="basic-addon3"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="prenom" className="form-label">
                      Prenom
                    </label>
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon4">
                        <span className="material-symbols-outlined">
                          person
                        </span>
                      </span>
                      <input
                        type="text"
                        name="prenom"
                        placeholder="Jeanne"
                        readOnly
                        value={records?.prenom || ""}
                        onChange={(e) =>
                          setRecords({ ...records, prenom: e.target.value })
                        }
                        required
                        className="form-control"
                        id="prenom"
                        aria-label="prenom"
                        aria-describedby="basic-addon4"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Date_Nais" className="form-label">
                      Date Naissance
                    </label>
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon2">
                        <span className="material-symbols-outlined">
                          calendar_month
                        </span>
                      </span>
                      <input
                        type="date"
                        name="date_naissance"
                        required
                        readOnly
                        value={records?.date_naissance || ""}
                        onChange={(e) =>
                          setRecords({
                            ...records,
                            date_naissance: e.target.value,
                          })
                        }
                        className="form-control"
                        id="Date_Nais"
                        aria-label="Date_Nais"
                        aria-describedby="basic-addon2"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="lieu" className="form-label">
                      Lieu de Naissance
                    </label>
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon2">
                        <span className="material-symbols-outlined">map</span>
                      </span>
                      <input
                        type="text"
                        name="lieu_naissance"
                        readOnly
                        placeholder="Cap-Haitien"
                        value={records?.lieu_naissance || ""}
                        onChange={(e) =>
                          setRecords({
                            ...records,
                            lieu_naissance: e.target.value,
                          })
                        }
                        required
                        className="form-control"
                        id="lieu"
                        aria-label="lieu"
                        aria-describedby="basic-addon2"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Nif_Cin" className="form-label">
                      Sexe
                    </label>
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon9">
                        <span className="material-symbols-outlined">Wc</span>
                      </span>
                      <select
                        className="form-select"
                        name="sexe"
                        required
                        disabled
                        value={records?.sexe || ""}
                        onChange={(e) =>
                          setRecords({ ...records, sexe: e.target.value })
                        }
                        aria-label="Type de compte"
                        id="Nif_Cin"
                      >
                        <option value="">Choisir sexe</option>
                        <option value="M">Masculin</option>
                        <option value="F">Feminin</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon5">
                        <span className="material-symbols-outlined">
                          alternate_email
                        </span>
                      </span>
                      <input
                        type="email"
                        placeholder="jeannejoseph@gmail.com"
                        name="email"
                        readOnly
                        value={records?.email || ""}
                        onChange={(e) =>
                          setRecords({ ...records, email: e.target.value })
                        }
                        className="form-control"
                        id="email"
                        aria-label="email"
                        aria-describedby="basic-addon5"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Numero_Whatsapp" className="form-label">
                      Numero Whatsapp
                    </label>
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon6">
                        <span className="material-symbols-outlined">chat</span>
                      </span>
                      <input
                        type="text"
                        name="tel"
                        placeholder="+50912345678"
                        value={records?.tel || ""}
                        readOnly
                        onChange={(e) =>
                          setRecords({ ...records, tel: e.target.value })
                        }
                        className="form-control"
                        id="Numero_Whatsapp"
                        aria-label="Numero_Whatsapp"
                        aria-describedby="basic-addon6"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Numero_Moncash" className="form-label">
                      Numero Moncash
                    </label>
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon7">
                        <span className="material-symbols-outlined">call</span>
                      </span>
                      <input
                        type="text"
                        name="moncash"
                        placeholder="+50912345678"
                        value={records?.moncash || ""}
                        readOnly
                        onChange={(e) =>
                          setRecords({ ...records, moncash: e.target.value })
                        }
                        className="form-control"
                        id="Numero_Moncash"
                        aria-label="Numero_Moncash"
                        aria-describedby="basic-addon7"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Numero_Natcash" className="form-label">
                      Numero Natcash
                    </label>
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon8">
                        <span className="material-symbols-outlined">call</span>
                      </span>
                      <input
                        type="text"
                        name="natcash"
                        placeholder="+50912345678"
                        value={records?.natcash || ""}
                        readOnly
                        onChange={(e) =>
                          setRecords({ ...records, natcash: e.target.value })
                        }
                        className="form-control"
                        id="Numero_Natcash"
                        aria-label="Numero_Natcash"
                        aria-describedby="basic-addon8"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Nif_Cin" className="form-label">
                      Nif Cin
                    </label>
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon9">
                        <span className="material-symbols-outlined">
                          credit_card
                        </span>
                      </span>
                      <input
                        type="text"
                        name="nif_cin"
                        placeholder="1234-567-890-1234"
                        value={records?.nif_cin || ""}
                        readOnly
                        onChange={(e) =>
                          setRecords({ ...records, nif_cin: e.target.value })
                        }
                        required
                        className="form-control"
                        id="Nif_Cin"
                        aria-label="Nif_Cin"
                        aria-describedby="basic-addon9"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Adresse" className="form-label">
                      Adresse
                    </label>
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon10">
                        <span className="material-symbols-outlined">map</span>
                      </span>
                      <input
                        type="text"
                        name="adresse"
                        placeholder="12 rue 7 P-Ville"
                        readOnly
                        value={records?.adresse || ""}
                        onChange={(e) =>
                          setRecords({ ...records, adresse: e.target.value })
                        }
                        className="form-control"
                        id="Adresse"
                        aria-label="Adresse"
                        aria-describedby="basic-addon10"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label for="status" className="form-label">
                      Statut
                    </label>
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon12">
                        <span className="material-symbols-outlined">grade</span>
                      </span>
                      <input
                        type="text"
                        name="statut"
                        required
                        readOnly
                        value={records?.statut || ""}
                        onChange={(e) =>
                          setRecords({ ...records, statut: e.target.value })
                        }
                        placeholder="Marchand | Etudiant..."
                        className="form-control"
                        id="status"
                        aria-label="status"
                        aria-describedby="basic-addon12"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="Type" className="form-label">
                      Type de compte
                    </label>
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon12">
                        <span className="material-symbols-outlined">
                          credit_card
                        </span>
                      </span>
                      <select
                        className="form-select"
                        name="type"
                        disabled
                        value={records?.type || ""}
                        required
                        aria-label="Type de compte"
                        id="Type"
                      >
                        <option value="">Choisir un type</option>
                        <option value="BS">Bousanm</option>
                        <option value="PL">Plasman</option>
                      </select>
                    </div>
                  </div>
                  {accountType === "PL" && (
                    <div className="col-md-6">
                      <label htmlFor="duree" className="form-label">
                        Durée
                      </label>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon13">
                          <span className="material-symbols-outlined">
                            timer
                          </span>
                        </span>
                        <select
                          className="form-select"
                          name="duree"
                          aria-label="Durée"
                          disabled
                          id="duree"
                          value={records?.duree || ""}
                        >
                          <option value="N/A">N/A</option>
                          <option value="3">3 Mois</option>
                          <option value="6">6 Mois</option>
                          <option value="9">9 Mois</option>
                          <option value="12">12 Mois</option>
                        </select>
                      </div>
                    </div>
                  )}
                  {/* <div className="col-md-6">
                <label htmlFor="image_piece" className="form-label">
                  Pièce Identification
                </label>
                <div className="input-group">
                  <span className="input-group-text" id="basic-addon10">
                    <span className="material-symbols-outlined">verified</span>
                  </span>{" "}
                  <input
                    type="file"
                    className="form-control"
                    id="exampleInputFile"
                    accept="image/*"
                    name="type_piece"
                  />
                </div>
              </div> */}
                  {/* 
              <div className="col-md-12 text-end">
                 <button
                    type="submit"
                    className="btn btn-success btn-sm"
                    disabled={loading}
                  >
                    {loading ? (
                      <>
                        <i className="fas fa-spinner fa-spin"></i>{" "}
                        Modification en cours...
                      </>
                    ) : (
                      <>
                        <i className="fas fa-save"></i> Modifier
                      </>
                    )}
                  </button>
              </div> */}
                </form>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default ViewsAccount;
