import React from 'react'

function Footer() {
  return (
    <div className="main-footer ">
    <div className="float-right d-none d-sm-block">
      <b>Version</b> 1.0.0
    </div>
    <strong>Copyright &copy; 2024-2025 <a href="#ziginvestment">ZIG Invetsment</a>.</strong> All rights reserved.
  </div>
  )
}

export default Footer