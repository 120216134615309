// AppRoutes.js
import React from "react";
import Login from "../auth/Login";
import Dashboard from "../page/Dashboard";
import AddAcount from "../page/accounts/AddAcount";
import PrivateRoute from "../utils/PrivateRoute";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AuthProvider } from "../context/AuthContext";
import ListAcount from "../page/accounts/ListAcount";
import { AccountProvider } from "../context/AccountsContext";
import ViewsAccount from "../page/accounts/ViewsAccount";
import User from "../page/users/Users";
import UserProfile from "../auth/Profile";
import ForgotPassword from "../auth/ForgotPassword";
import ResetPassword from "../auth/ResetPassword";
import AddTransaction from "../page/transactions/AddTransaction";
import ListTransaction from "../page/transactions/ListTransaction";
import UserEdit from "../auth/UserEdit";

import { TransactionProvider } from "../context/TransactionsContext";
import Renewal from "../page/transactions/Renewal";
import { TimerProvider } from "../context/TimerContext";
import NewAccount from "../page/accounts/NewAccount";
import WithdrawInterest from "../page/transactions/WithdrawInterest";
import Customer from "../page/mutuelle/Customer";
import TransactionsSol from "../page/mutuelle/TransactionsSol";
import { CustomerProvider } from "../context/CustomerContext";
import { TransactionSolProvider } from "../context/TransactionSolContext";
import CustomersMutuelle from "../page/mutuelle/CustomersMutuelle";
import Home from '../page/home/index';
import Register from "../auth/Register";
import Profile from "../page/accounts/Profile";
import TransactionsMutuelle from "../page/mutuelle/TransactionsMutuelle";
import CustomerUpdate from "../page/mutuelle/CustomerUpdate";
import ListTransactionlocal from "../page/transactions/ListTransactionlocal";
import AddTransactionlocal from "../page/transactions/AddTransactionlocal";
import ProfilSol from "../page/accounts/ProfilSol";


function AppRoutes() {
  return (
    <div className="wrapper">
      <Router>
        <AuthProvider>
          <AccountProvider>
            <TransactionProvider>
              <TimerProvider>
                <CustomerProvider>
                  <TransactionSolProvider>
                    <Switch>
                      <Route path="/" component={Home} exact />
                      {/* <Route path="/prof" component={Profile} /> */}
                      <Route path="/login" component={Login} />
                      <Route path="/register" component={Register} />
                      <PrivateRoute path="/users" component={User} />
                      <PrivateRoute
                        path="/detail-user/:user_id"
                        component={UserEdit}
                      />
                      <PrivateRoute
                        path="/user-profile/:user_id"
                        component={UserProfile}
                      />
                      <PrivateRoute path="/add-renewal" component={Renewal} />
                      <PrivateRoute path="/dashboard" component={Dashboard} />
                      <PrivateRoute path="/List" component={ListAcount} />
                      <PrivateRoute path="/views-account/:accounts_id" component={ViewsAccount} />
                      <PrivateRoute path="/transaction" component={ListTransaction} />
                      <PrivateRoute path="/transactionlocal" component={ListTransactionlocal} />
                      <PrivateRoute path="/add-transaction" component={AddTransaction} />
                      <PrivateRoute path="/add-transactionlocal" component={AddTransactionlocal} />
                      <PrivateRoute path="/add-account" component={AddAcount} />
                      <PrivateRoute
                        path="/View-account"
                        component={ListAcount}
                      />
                      <PrivateRoute
                        path="/View-custommer/:accounts_id"
                        component={CustomerUpdate}
                      />
                      <PrivateRoute
                        path="/withdraw-interest"
                        component={WithdrawInterest}
                      />
                      <PrivateRoute
                        path="/new-account/:accounts_id"
                        component={NewAccount}
                      />
                      <Route path="/profile" component={Profile} />
                      <Route path="/sol/profile" component={ProfilSol} />
                      <PrivateRoute path="/customers" component={Customer} />
                      <PrivateRoute path="/customers-mutuelle" component={CustomersMutuelle} />
                      <PrivateRoute
                        path="/sol-transactions"
                        component={TransactionsSol}
                      />
                      <PrivateRoute
                        path="/mutuelle-transactions"
                        component={TransactionsMutuelle}
                      />
                      <Route
                        path="/forgot_password"
                        component={ForgotPassword}
                        exact
                      />
                      <Route path="/reset-password/:uidb64/:token" component={ResetPassword} />
                      {/* <Route  path="/reset-password" component={ResetPassword}/> */}
                    </Switch>
                  </TransactionSolProvider>
                </CustomerProvider>
              </TimerProvider>
            </TransactionProvider>
          </AccountProvider>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default AppRoutes;
