import React, { useContext, useEffect, useState } from "react";
import { DataTransactionsSol } from "../../data/DataTransactions";
import Footer from "../../Include/Footer";
import Sidebar from "../../Include/Sidebar";
import { TransactionSolContext } from "../../context/TransactionSolContext";
import { CustomerContext } from "../../context/CustomerContext";

const TransactionsSol = () => {
  const { createTransaction } = useContext(TransactionSolContext);
  const { getCustomers } = useContext(CustomerContext);

  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]); // Liste filtrée des clients
  const [selectedCustomer, setSelectedCustomer] = useState(null); // Client sélectionné
  const [searchTerm, setSearchTerm] = useState(""); // Terme de recherche
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const CustomersData = await getCustomers();
        setCustomers(CustomersData);
        setFilteredCustomers(CustomersData); // Initialisez la liste filtrée avec tous les clients
      } catch (error) {
        console.error("Error fetching Customers:", error);
      }
    };
    fetchCustomers();
  }, [getCustomers]);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);

    // Filtrer les clients en fonction du terme de recherche
    const filtered = customers.filter(
      (customer) => customer?.surnom?.toLowerCase().includes(value) // Validation ici
    );
    setFilteredCustomers(filtered);
  };

  const handleSelectChange = (e) => {
    const customerId = e.target.value;
    const selected = customers.find(
      (customer) => customer.id === parseInt(customerId)
    );
    setSelectedCustomer(selected);
  };
  const handleSubmitForm = async (e) => {
    e.preventDefault();
    const client = e.target.client.value;
    const montant = e.target.montant.value;

    let formData = new FormData();
    formData.append("client", client);
    formData.append("montant", montant);

    setLoading(true); // Activez le loader avant l'appel à l'API
    if (montant.length > 0) {
      try {
        await createTransaction(formData); // Utilisez await pour attendre que l'appel soit terminé
      } catch (error) {
        console.error("Error creating transaction:", error);
      }
    }
    setLoading(false); // Désactivez le loader après la fin de l'appel
  };

  return (
    <div className="hold-transition  sidebar-mini layout-fixed layout-navbar-fixed layout-footer-fixed">
      <div className="wrapper">
        <Sidebar />
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Gestion Transactions Sol</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">Dashboard</li>
                    <li className="breadcrumb-item active">Clients</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <h5>Liste des Clients</h5>
                <div className="clearfix hidden-md-up"></div>
              </div>

              <div className="row">
                <div className="col-md-5">
                  <div className="card">

                    <div className="card-header">
                      <h5 className="card-title fw-bold text-muted">DEPOT</h5>

                      <div className="card-tools">
                        <button
                          type="button"
                          className="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i className="fas fa-minus"></i>
                        </button>
                      </div>
                    </div>
                    <div className="card-body p-2">
                      <form
                        onSubmit={handleSubmitForm}
                        className="forms-sample"
                        encType="multipart/form-data"
                      >
                        <div className="row">
                          <div className="col-md-12">
                            {/* Input de recherche */}
                            <div className="form-group">
                              <label htmlFor="searchCustomer">
                                Rechercher un client
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="searchCustomer"
                                placeholder="Rechercher par surnom"
                                value={searchTerm}
                                onChange={handleSearch}
                              />
                            </div>

                            {/* Select des clients */}
                            <div className="form-group">
                              <label htmlFor="selectClient">Client</label>
                              <select
                                className="form-control"
                                id="selectClient"
                                name="client"
                                value={
                                  selectedCustomer ? selectedCustomer.id : ""
                                } // Ajoutez cette ligne
                                onChange={handleSelectChange}
                                required
                              >
                                <option value="" disabled>
                                  Choisir un client
                                </option>
                                {Array.isArray(filteredCustomers) &&
                                  filteredCustomers.map((customer) => (
                                    <option
                                      key={customer.id}
                                      value={customer.id}
                                    >
                                      {customer.surnom}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>

                          {/* Afficher sol et nb_main si un client est sélectionné */}
                          {selectedCustomer && (
                            <>
                              {/* <div className="col-md-6">
                                <div className="form-group">
                                  <label htmlFor="solInput">Sol</label>
                                  <input
                                    type="number"
                                    className="form-control-sm col-12 fw-bold"
                                    id="solInput"
                                    value={selectedCustomer.sol || 0} // Validation ici
                                    name="sol"
                                    disabled
                                  />
                                </div>
                              </div> */}
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label htmlFor="solInput">Sol</label>
                                  <input
                                    type="number"
                                    className="form-control-sm col-12 fw-bold"
                                    id="solInput"
                                    value={selectedCustomer.sol || 0} // Validation ici
                                    name="sol"
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label htmlFor="nbMainInput">
                                    Nombre de Mains
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control-sm col-12 fw-bold"
                                    id="nbMainInput"
                                    value={selectedCustomer.nb_main || 0} // Validation ici
                                    name="nb_main"
                                    disabled
                                  />
                                </div>
                              </div>
                            </>
                          )}
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="montantInput">
                                Montant en Gdes
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="montantInput"
                                placeholder="Montant"
                                required
                                name="montant"
                              />
                            </div>
                          </div>

                          <div className="col-md-12 mt-4 text-center">
                            <div className="form-group pt-1">
                              <button
                                type="submit"
                                className="btn btn-primary btn-sm"
                                disabled={loading}
                              >
                                {loading ? (
                                  <>
                                    <i className="fas fa-spinner fa-spin"></i>{" "}
                                    Enregistrement en cours...
                                  </>
                                ) : (
                                  <>
                                    <i className="fas fa-save"></i> Enregistrer
                                  </>
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="card-footer text-center"></div>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="card">

                    <div className="card-header">
                      <h5 className="card-title  fw-bold text-muted">LISTES DES DEPOTS</h5>

                      <div className="card-tools">
                        <button
                          type="button"
                          className="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i className="fas fa-minus"></i>
                        </button>
                      </div>
                    </div>
                    <div className="card-body p-0">
                      <DataTransactionsSol />
                    </div>
                    <div className="card-footer text-center"></div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="card">

                    <div className="card-header">
                      <h5 className="card-title  fw-bold text-muted">RETRAITS</h5>

                      <div className="card-tools">
                        <button
                          type="button"
                          className="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i className="fas fa-minus"></i>
                        </button>
                      </div>
                    </div>
                    <div className="card-body p-2">
                      <form
                        onSubmit={handleSubmitForm}
                        className="forms-sample"
                        encType="multipart/form-data"
                      >
                        <div className="row">
                          <div className="col-md-12">
                            {/* Input de recherche */}
                            <div className="form-group">
                              <label htmlFor="searchCustomer">
                                Rechercher un client
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="searchCustomer"
                                placeholder="Rechercher par surnom"
                                value={searchTerm}
                                onChange={handleSearch}
                              />
                            </div>

                            {/* Select des clients */}
                            <div className="form-group">
                              <label htmlFor="selectClient">Client</label>
                              <select
                                className="form-control"
                                id="selectClient"
                                name="client"
                                value={
                                  selectedCustomer ? selectedCustomer.id : ""
                                } // Ajoutez cette ligne
                                onChange={handleSelectChange}
                                required
                              >
                                <option value="" disabled>
                                  Choisir un client
                                </option>
                                {Array.isArray(filteredCustomers) &&
                                  filteredCustomers.map((customer) => (
                                    <option
                                      key={customer.id}
                                      value={customer.id}
                                    >
                                      {customer.surnom}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>

                          {/* Afficher sol et nb_main si un client est sélectionné */}
                          {selectedCustomer && (
                            <>
                              {/* <div className="col-md-6">
                                <div className="form-group">
                                  <label htmlFor="solInput">Sol</label>
                                  <input
                                    type="number"
                                    className="form-control-sm col-12 fw-bold"
                                    id="solInput"
                                    value={selectedCustomer.sol || 0} // Validation ici
                                    name="sol"
                                    disabled
                                  />
                                </div>
                              </div> */}
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label htmlFor="solInput">Sol</label>
                                  <input
                                    type="number"
                                    className="form-control-sm col-12 fw-bold"
                                    id="solInput"
                                    value={selectedCustomer.sol || 0} // Validation ici
                                    name="sol"
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label htmlFor="nbMainInput">
                                    Nombre de Mains
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control-sm col-12 fw-bold"
                                    id="nbMainInput"
                                    value={selectedCustomer.nb_main || 0} // Validation ici
                                    name="nb_main"
                                    disabled
                                  />
                                </div>
                              </div>
                            </>
                          )}
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="montantInput">
                                Nombre de main a paye
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="montantInput"
                                placeholder="Montant"
                                required
                                name="montant"
                              />
                            </div>
                          </div>

                          <div className="col-md-12 mt-4 text-center">
                            <div className="form-group pt-1">
                              <button
                                type="submit"
                                className="btn btn-primary btn-sm"
                                disabled={loading}
                              >
                                {loading ? (
                                  <>
                                    <i className="fas fa-spinner fa-spin"></i>{" "}
                                    Enregistrement en cours...
                                  </>
                                ) : (
                                  <>
                                    <i className="fas fa-save"></i> Enregistrer
                                  </>
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="card-footer text-center"></div>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="card">

                    <div className="card-header">
                      <h5 className="card-title  fw-bold text-muted">LISTES DES RETRAITS</h5>

                      <div className="card-tools">
                        <button
                          type="button"
                          className="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i className="fas fa-minus"></i>
                        </button>
                      </div>
                    </div>
                    <div className="card-body p-0">
                      <DataTransactionsSol />
                    </div>
                    <div className="card-footer text-center"></div>
                  </div>
                </div>

              </div>
            </div>
          </section>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default TransactionsSol;
