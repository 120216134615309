import React, { useContext, useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import Sidebar from "../Include/Sidebar";
import AuthContext from "../context/AuthContext";
import Footer from "../Include/Footer";
const swal = require("sweetalert2");

function Profile() {
  const { getUserById, updateUser, logoutUser } = useContext(AuthContext);
  const [records, setRecords] = useState({});
  const { user_id } = useParams();
  const [profileImage, setProfileImage] = useState(null);
  const [profileImageView, setProfileImageView] = useState(null);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUserById(user_id).then((data) => {
      setRecords(data);
      if (data.image) {
        setProfileImageView(data.image);
      }
    });
  }, [getUserById, user_id]);

  const handleImageChange = (event) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setProfileImage(file);
      const imageUrl = URL.createObjectURL(file);
      setProfileImageView(imageUrl);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    if (profileImage) {
      formData.append("image", profileImage);
    }

    const password = formData.get("password");
    const confirmPassword = formData.get("confirmPassword");

    if (password !== confirmPassword) {
      swal.fire({
        title: "Les mots de passe ne correspondent pas",
        icon: "warning",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      return;
    }

    formData.delete("confirmPassword");

    try {
      setLoading(true);
      const result = await updateUser(records.id, formData);
      setLoading(false);
      if (result.success) {
        if (result.passwordChanged) {
          await logoutUser();
          history.push("/");
        } else {
          history.push("/dashboard");
        }
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du profil", error);
    }
  };

  return (
    <>
      <Sidebar />
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6 d-flex align-items-center">
                <div className="col-sm-1 bg-blue rounded-lg p-2 text-center">
                  <i className="fas fa-user"></i>
                </div>
                <div className="ml-2">
                  <h3 className="display fw-12 fw-bold">Profil Utilisateur</h3>
                </div>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Tableau de Bord</Link>
                  </li>
                  <li className="breadcrumb-item">
                    {records.role === "Admin" ? (
                      <Link to="/users">Utilisateurs</Link>
                    ) : (
                      <span className="text-primary">Utilisateurs</span>
                    )}
                  </li>
                  <li className="breadcrumb-item active">Profil</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* profil */}
              <div className="col-md-4">
                <div className="card card-primary card-outline">
                  <div className="card-body">
                    <div className="text-center">
                      <img
                        className="profile-user-img img-fluid img-circle"
                        src={profileImageView || ""}
                        alt="profile"
                        style={{ maxWidth: "80px", borderRadius: "50px" }}
                      />
                    </div>
                    <h3 className="profile-username text-center">
                      {records.first_name + " " + records.last_name || ""}
                    </h3>
                    <p className="text-center mb-0">Nom Utilisateur</p>
                    <p className="text-muted text-center ">
                      {records.username || ""}
                    </p>
                    <ul className="list-group">
                      <li className="list-group-item p-1">
                        <b>Role</b>{" "}
                        <a href="#settings" className="float-right">
                          {records.role || ""}
                        </a>
                      </li>
                      <li className="list-group-item p-1">
                        <b>Email</b>{" "}
                        <a href="#settings" className="float-right">
                          {records.email || ""}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* formulaire */}
              <div className="col-md-8">
                {/* <div className="card-header card-primary card-outline bg-white p-2">
                  <ul className="nav nav-pills">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        href="#settings"
                        data-toggle="tab"
                      >
                        Modifier votre profil
                      </a>
                    </li>
                  </ul>
                </div> */}
                <div className="card card-primary card-outline">
                  <div className="tab-content">
                    <div className="active tab-pane" id="settings">
                      <form
                        encType="multipart/form-data"
                        onSubmit={handleSubmit}
                      >
                        <div className="p-3">
                          <ul className="nav nav-pills">
                            <li className="nav-item">
                              <a
                                className="nav-link active"
                                href="#settings"
                                data-toggle="tab"
                              >
                                Modifier votre profil
                              </a>
                            </li>
                          </ul>
                          <hr />
                          <div className="form-group row">
                            <label
                              htmlFor="inputName"
                              className="col-sm-4 col-form-label"
                            >
                              Nom
                            </label>
                            <div className="col-sm-8">
                              <input
                                type="text"
                                className="form-control"
                                id="inputName"
                                value={records.first_name || ""}
                                onChange={(e) =>
                                  setRecords({
                                    ...records,
                                    first_name: e.target.value,
                                  })
                                }
                                placeholder="Nom Utilisateur"
                                required
                                name="first_name"
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="inputName"
                              className="col-sm-4 col-form-label"
                            >
                              Prénom
                            </label>
                            <div className="col-sm-8">
                              <input
                                type="text"
                                className="form-control"
                                id="inputName"
                                value={records.last_name || ""}
                                onChange={(e) =>
                                  setRecords({
                                    ...records,
                                    last_name: e.target.value,
                                  })
                                }
                                placeholder="Prénom"
                                required
                                name="last_name"
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="inputEmail"
                              className="col-sm-4 col-form-label"
                            >
                              Email
                            </label>
                            <div className="col-sm-8">
                              <input
                                type="email"
                                className="form-control"
                                id="inputEmail"
                                onChange={(e) =>
                                  setRecords({
                                    ...records,
                                    email: e.target.value,
                                  })
                                }
                                value={records.email || ""}
                                placeholder="Email"
                                required
                                name="email"
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="inputUsername"
                              className="col-sm-4 col-form-label"
                            >
                              Nom Utilisateur
                            </label>
                            <div className="col-sm-8">
                              <input
                                type="text"
                                className="form-control"
                                id="inputUsername"
                                onChange={(e) =>
                                  setRecords({
                                    ...records,
                                    username: e.target.value,
                                  })
                                }
                                value={records.username || ""}
                                placeholder="Nom Utilisateur"
                                required
                                name="username"
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="inputSkills"
                              className="col-sm-4 col-form-label"
                            >
                              Photo
                            </label>
                            <div className="col-sm-8">
                              <input
                                type="file"
                                className="form-control"
                                id="inputProfileImage"
                                accept="image/*"
                                name="image"
                                onChange={handleImageChange}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="inputPassword"
                              className="col-sm-4 col-form-label"
                            >
                              Nouveau Mot de Passe
                            </label>
                            <div className="col-sm-8">
                              <input
                                type="password"
                                className="form-control"
                                id="inputPassword"
                                placeholder="Nouveau Mot de Passe"
                                name="password"
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="inputConfirmPassword"
                              className="col-sm-4 col-form-label"
                            >
                              Confirmer Mot de Passe
                            </label>
                            <div className="col-sm-8">
                              <input
                                type="password"
                                className="form-control"
                                id="inputConfirmPassword"
                                placeholder="Confirmer Mot de Passe"
                                name="confirmPassword"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="card-footer">
                          <button
                            type="submit"
                            className="btn btn-primary btn-sm"
                            disabled={loading}
                          >
                            {loading ? (
                              <>
                                <i className="fas fa-spinner fa-spin"></i>{" "}
                                Enregistrement en cours...
                              </>
                            ) : (
                              <>
                                <i className="fas fa-save"></i> Enregistrer
                              </>
                            )}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default Profile;
