import React, { useContext, useState } from "react";
import AuthContext from "../context/AuthContext";
import { CustomerContext } from "../context/CustomerContext";

const FormCustomers = () => {
  const { createCustomer } = useContext(CustomerContext);
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const nom = e.target.nom.value;
    const prenom = e.target.prenom.value;
    const tel = e.target.tel.value;
    const sur_nom = e.target.sur_nom.value;
    const nb_main = e.target.nb_main.value;
    const sol = e.target.sol.value;
    const montant_jours = e.target.montant_jours.value;

    let formData = new FormData();
    formData.append("nom", nom);
    formData.append("prenom", prenom);
    formData.append("tel", tel);
    formData.append("surnom", sur_nom);
    formData.append("nb_main", nb_main);
    formData.append("sol", sol);
    formData.append("montant_jours", montant_jours);
    formData.append("created_by", user.user_id);

    setLoading(true); // Active le loader
    try {
      if (sur_nom.length > 0) {
        await createCustomer(formData); // Attend la fin de la création du client
      }
    } catch (error) {
      console.error("Erreur lors de la création du client :", error);
    } finally {
      setLoading(false); // Désactive le loader après la fin de l'opération
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="forms-sample "
      encType="multipart/form-data"
    >
      <div className="row">
        <>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="name">Nom</label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Nom"
                name="nom"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="name">Prenom</label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Prenom"
                name="prenom"
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="name">SurNom</label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="SurNom"
                required
                name="sur_nom"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="exampleInputtel3">Télephone</label>
              <input
                type="number"
                className="form-control"
                id="exampleInputtel3"
                placeholder="Télephone"
                name="tel"
              />
            </div>
          </div>
          <div className="col-md-6">
            <label htmlFor="Nif_Cin" className="form-label">
              Sol
            </label>
            <div className="input-group">
              <span className="input-group-text" id="basic-addon9">
                <span className="material-symbols-outlined">Wc</span>
              </span>
              <select
                className="form-select"
                name="sol"
                required
                aria-label="Type de compte"
                id="Nif_Cin"
              >
                <option value="">Choisir sol</option>
                <option value="250">250 Gdes</option>
                <option value="500">500 Gdes</option>
                <option value="1000">1000 Gdes</option>
                <option value="1500">1500 Gdes</option>
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="exampleInputtel3">Nombre de main</label>
              <input
                type="number"
                className="form-control"
                id="exampleInputtel3"
                placeholder="Nombre de main"
                required
                name="nb_main"
                step="0.01"
                min="0.5" // Valeur minimum 1
                onInput={(e) => {
                  const value = parseFloat(e.target.value);
                  if (value < 0.5) {
                    e.target.setCustomValidity(
                      "Veuillez entrer un nombre supérieur ou égal à 0.5."
                    ); // Message d'erreur
                  } else {
                    e.target.setCustomValidity(""); // Réinitialise le message d'erreur
                  }
                }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="exampleInputtel3">Montant par jour</label>
              <input
                type="number"
                className="form-control"
                id="exampleInputtel3"
                placeholder="Montant/jour"
                required
                name="montant_jours"
              />
            </div>
          </div>
        </>
        {/* )} */}
        {/* <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="name">Nom Utilisateur</label>
            <input type="text" className="form-control" id="name"
              placeholder="Nom Utilisateur" name='username' />
          </div>
        </div> */}
        <div className="col-md-12 mt-4 text-center">
          <div className="form-group pt-1">
            <button
              type="submit"
              className="btn btn-primary btn-sm"
              disabled={loading}
            >
              {loading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> En cours...
                </>
              ) : (
                <>
                  <i className="fas fa-save"></i> Enregistrer
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

const FormCustomersMutuelle = () => {
  const { createCustomerMutuelle } = useContext(CustomerContext);
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const nom = e.target.nom.value;
    const prenom = e.target.prenom.value;
    const tel = e.target.tel.value;
    const username = e.target.username.value;
    const password = e.target.password.value;
    const mutuelle = e.target.mutuelle.value;
    const duree = e.target.duree.value;

    let formData = new FormData();
    formData.append("nom", nom);
    formData.append("prenom", prenom);
    formData.append("tel", tel);
    formData.append("password", password);
    formData.append("username", username);
    formData.append("mutuelle", mutuelle);
    formData.append("duree", duree);
    formData.append("created_by", user.user_id);

    setLoading(true); // Activer le loader avant de commencer
    try {
      if (nom.length > 0) {
        await createCustomerMutuelle(formData); // Attendre que la fonction se termine
      }
    } catch (error) {
      console.error("Erreur de connexion :", error);
    } finally {
      setLoading(false); // Désactiver le loader une fois terminé (succès ou échec)
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="forms-sample "
      encType="multipart/form-data"
    >
      <div className="row">
        <>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="name">Nom</label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Nom"
                required
                name="nom"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="name">Prenom</label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Prenom"
                required
                name="prenom"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="exampleInputtel3">Télephone</label>
              <input
                type="number"
                className="form-control"
                id="exampleInputtel3"
                placeholder="Télephone"
                required
                name="tel"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="exampleInputtel3">Mutuelle | Montant/Jours</label>
              <input
                type="number"
                className="form-control"
                id="exampleInputtel3"
                placeholder="Mutuelle | Montant/Jours"
                required
                name="mutuelle"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="exampleInputtel3">Durée</label>
              <input
                type="number"
                className="form-control"
                id="exampleInputtel3"
                placeholder="Durée"
                required
                name="duree"
              />
            </div>
          </div>
        </>
        {/* )} */}
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="name">Nom Utilisateur</label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Nom Utilisateur"
              name="username"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="exampleInputtel3">Mot de passe</label>
            <input
              type="email"
              className="form-control"
              id="exampleInputtel3"
              placeholder="Mot de passe"
              name="password"
            />
          </div>
        </div>
        <div className="col-md-12 mt-4 text-center">
          <div className="form-group pt-1">
            <button
              type="submit"
              className="btn btn-primary btn-sm"
              disabled={loading}
            >
              {loading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> En cours...
                </>
              ) : (
                <>
                  <i className="fas fa-save"></i> Enregistrer
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export { FormCustomers, FormCustomersMutuelle };
