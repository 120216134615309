import { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FormUsers } from "../../data/Form";
import DataUsers from "../../data/DataUsers";
import Sidebar from "../../Include/Sidebar";
import Footer from "../../Include/Footer";
function User() {
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the form from submitting
    setShowModal(true); // Show the modal
  };

  const closeModal = () => {
    setShowModal(false); // Hide the modal
  };
  useEffect(() => {
		document.title = "H-SOL | Users";
	  }, [])
  return (
    
    <>
        <Sidebar />
        <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6 d-flex align-items-center">
                <div className="col-sm-1 bg-blue rounded-lg p-2 text-center">
                  <i className="fas fa-users"></i>
                </div>
                <div className="ml-2">
                  <h3 className="display fw-12">Gestion des Utilisateurs</h3>
                </div>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><Link to="/dashboard">Acceuil</Link></li>
                  <li className="breadcrumb-item">Utilisateurs</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
          <section className="content">
            <div className="container-fluid col-12">
            <div className="row">
                <div className="row">
                  <div className="col-6 ">
                  </div> 
                  <div className=" col-6 p-2 pl-0">
                    <button 
                      className="btn btn-primary p-1 fw-bold float-end"
                      onClick={handleSubmit}
                    >
                      <i className="fas fa-user-plus"> </i>
                      <span> </span>Nouveau Utilisateur
                    </button>
                  </div>
                </div>
                  <DataUsers />
              </div>
            </div>
          </section>
          <Modal
            className="mt-0"
            show={showModal}
            onHide={closeModal}
            size="lg"
            top="true"
          >
            <Modal.Header closeButton>
              <Modal.Title>Formulaire d'enregistrement</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="card">
                <div className="card-body">
                  <FormUsers />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="btn btn-danger" onClick={closeModal}>
                <i className="fas fa-trash"></i> Fermer
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      <Footer/>
    </>
  );
}

export default User;
