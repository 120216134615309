// App.js
import React from 'react';
import AppRoutes from './components/routes/Routes';
// import 'animate.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';

function App() {
  return (
<>
<AppRoutes />
</>
  );
}

export default App;
