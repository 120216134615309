import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../Include/Sidebar";
import { Link } from "react-router-dom";
import { AccountContext } from "../../context/AccountsContext";
import AuthContext from "../../context/AuthContext";
import Footer from "../../Include/Footer";
import { useParams } from "react-router-dom";

function NewAccount() {
  const [accountType, setAccountType] = useState("");
  const [duree, setDuree] = useState("N/A"); // Définit la valeur par défaut à 'N/A'
  const { copyAccount, getAccountById } = useContext(AccountContext);
  const { user } = useContext(AuthContext);
  const [records, setRecords] = useState({});
  const { accounts_id } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAccountById(accounts_id).then((data) => {
      setRecords(data);
      setAccountType(records?.type || "");
    });
  }, [getAccountById, accounts_id, records?.type]);

  const handleAccountTypeChange = (event) => {
    setAccountType(event.target.value);
    if (event.target.value !== "PL") {
      setDuree("N/A"); // Réinitialise la durée si le type de compte n'est pas Plasman
    }
  };

  const handleDureeChange = (event) => {
    setDuree(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const type = e.target.type.value;
    const devise = e.target.devise.value;

    let formData = new FormData();
    formData.append("existing_account_id", accounts_id);
    formData.append("type", type);
    formData.append("devise", devise);
    formData.append("created_by", user.user_id);

    if (accountType === "PL") {
      formData.append("duree", duree);
    } else {
      formData.append("duree", "N/A");
    }

    setLoading(true);
    type.length > 0 && copyAccount(formData);
    setLoading(false);
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar />
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6 d-flex align-items-center">
                  <div className="col-sm-1 bg-blue rounded-lg p-2 text-center">
                    <i className="fas fa-info"></i>
                  </div>
                  <div className="ml-2">
                    <h3 className="display fw-12 ">
                      Nouveau Compte pour
                      <span className="fw-bold">
                        {" "}
                        {records?.username || ""}
                      </span>{" "}
                    </h3>
                  </div>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Acceuil</Link>
                    </li>
                    <li className="breadcrumb-item">Compte</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <section className="content">
            <div className="container-fluid col-12 text-start ">
              <div className="card p-3">
                <div className="col-md-12 mb-2">
                  <Link
                    to="/List"
                    className="btn btn-primary rounded-lg float-end"
                  >
                    <i className="fa fa-arrow-left"></i>
                  </Link>
                </div>
                <hr />
                <div className="row p-2">
                  <div className="card p-0 col-xl-6 col-lg-5 ">
                    <div className="card-header bg-primary mb-1">
                      Detail client
                    </div>
                    <form
                      className="row g-3 mx-auto"
                      encType="multipart/form-data"
                    >
                      <div className="col-md-12">
                        <label htmlFor="name" className="form-label">
                          Nom Complet
                        </label>
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon3">
                            <span className="material-symbols-outlined">
                              person
                            </span>
                          </span>
                          <input
                            type="text"
                            placeholder="Joseph"
                            name="nom"
                            readOnly
                            value={`${records?.nom} ${records?.prenom}` || ""}
                            required
                            className="form-control"
                            id="name"
                            aria-label="name"
                            aria-describedby="basic-addon3"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="prenom" className="form-label">
                          Email
                        </label>
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon4">
                            <span className="material-symbols-outlined">
                              email
                            </span>
                          </span>
                          <input
                            type="text"
                            name="prenom"
                            placeholder="Jeanne"
                            readOnly
                            value={records?.email || ""}
                            onChange={(e) =>
                              setRecords({ ...records, email: e.target.value })
                            }
                            required
                            className="form-control"
                            id="prenom"
                            aria-label="prenom"
                            aria-describedby="basic-addon4"
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <label for="status" className="form-label">
                          NIF | CIN
                        </label>
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon12">
                            <span className="material-symbols-outlined">
                              grade
                            </span>
                          </span>
                          <input
                            type="text"
                            name="statut"
                            required
                            readOnly
                            value={records?.nif_cin || ""}
                            onChange={(e) =>
                              setRecords({
                                ...records,
                                nif_cin: e.target.value,
                              })
                            }
                            placeholder="Marchand | Etudiant..."
                            className="form-control"
                            id="status"
                            aria-label="status"
                            aria-describedby="basic-addon12"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="card p-0 col-xl-6 col-lg-5">
                    <div className="card-header bg-primary ">
                      Ajouter le noouveau compte
                    </div>
                    <form
                      onSubmit={handleSubmit}
                      className="row g-3 mx-auto"
                      encType="multipart/form-data"
                    >
                      <div className="col-md-12">
                        <label htmlFor="Type" className="form-label">
                          Type de compte
                        </label>
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon12">
                            <span className="material-symbols-outlined">
                              credit_card
                            </span>
                          </span>
                          <select
                            className="form-select"
                            name="type"
                            required
                            aria-label="Type de compte"
                            id="Type"
                            onChange={handleAccountTypeChange}
                          >
                            <option value="">Choisir un type</option>
                            <option value="BS">Bousanm</option>
                            <option value="PL">Plasman</option>
                          </select>
                        </div>
                      </div>
                      {accountType === "PL" && (
                        <div className="col-md-">
                          <label htmlFor="duree" className="form-label">
                            Durée
                          </label>
                          <div className="input-group">
                            <span
                              className="input-group-text"
                              id="basic-addon13"
                            >
                              <span className="material-symbols-outlined">
                                timer
                              </span>
                            </span>
                            <select
                              className="form-select"
                              name="duree"
                              aria-label="Durée"
                              id="duree"
                              value={duree}
                              onChange={handleDureeChange}
                            >
                              <option value="N/A">N/A</option>
                              <option value="3">3 Mois</option>
                              <option value="6">6 Mois</option>
                              <option value="9">9 Mois</option>
                              <option value="12">12 Mois</option>
                            </select>
                          </div>
                        </div>
                      )}
                      <div className="col-md-12">
                        <label htmlFor="Nif_Cin" className="form-label">
                          Devise
                        </label>
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon9">
                            <span className="material-symbols-outlined">
                              Wc
                            </span>
                          </span>
                          <select
                            className="form-select"
                            name="devise"
                            required
                            aria-label="Type de compte"
                            id="Nif_Cin"
                          >
                            <option value="">Choisir la devise</option>
                            <option value="HTG">HTG</option>
                            <option value="USD">USD</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-12 mb-2">
                        <button
                          type="submit"
                          className="btn btn-primary btn-sm"
                          disabled={loading}
                        >
                          {loading ? (
                            <>
                              <i className="fas fa-spinner fa-spin"></i>{" "}
                              Enregistrement en cours...
                            </>
                          ) : (
                            <>
                              <i className="fas fa-save"></i> Enregistrer
                            </>
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default NewAccount;
