import React, { useContext, useEffect, useState } from "react";
import { DataTransactionsMutuelle } from "../../data/DataTransactions";
import Footer from "../../Include/Footer";
import Sidebar from "../../Include/Sidebar";
import { TransactionSolContext } from "../../context/TransactionSolContext";
import { CustomerContext } from "../../context/CustomerContext";

const TransactionsMutuelle = () => {
  const { createTransactionMutuelle } = useContext(TransactionSolContext);
  const { getCustomersMutuelle } = useContext(CustomerContext);
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false); // Gestion du loader
  // const [loading1, setLoading1] = useState(false); // Gestion du loader

  useEffect(() => {
    const fetchCustomers = async () => {
      // setLoading1(true); // Démarre le loader avant la récupération des clients
      try {
        const CustomersData = await getCustomersMutuelle();
        setCustomers(CustomersData);
      } catch (error) {
        console.error("Erreur lors de la récupération des clients:", error);
      } finally {
        // setLoading1(false); // Arrête le loader après la récupération des données
      }
    };
    fetchCustomers();
  }, [getCustomersMutuelle]);

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    const client = e.target.client.value;
    const montant = e.target.montant.value;
    const type_transaction = e.target.type_transaction.value;

    let formData = new FormData();
    formData.append("client", client);
    formData.append("type_transaction", type_transaction);
    formData.append("montant", montant);

    setLoading(true); // Démarre le loader lors de la soumission
    try {
      montant.length > 0 && (await createTransactionMutuelle(formData)); // Utiliser await pour attendre la fin
    } catch (error) {
      console.error("Erreur lors de la création de la transaction:", error);
    } finally {
      setLoading(false); // Arrête le loader après la soumission
    }
  };

  return (
    <div className="hold-transition  sidebar-mini layout-fixed layout-navbar-fixed layout-footer-fixed">
      <div className="wrapper">
        <Sidebar />
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Gestion Mutuelle</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">Dashboard</li>
                    <li className="breadcrumb-item active">
                      Transactions Mutuelle
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <h5>Liste des Clients</h5>
                <div className="clearfix hidden-md-up"></div>
              </div>

              <div className="row">
                <div className="col-md-5">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">-</h3>

                      <div className="card-tools">
                        <div
                          type="button"
                          className="btn btn-success btn-sm fw-bold"
                        >
                          <i className="fas fa-user-plus"></i> Nouvelle
                          Transaction
                        </div>
                      </div>
                    </div>

                    <div className="card-body p-2">
                      <form
                        onSubmit={handleSubmitForm}
                        className="forms-sample "
                        encType="multipart/form-data"
                      >
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="exampleSelectGender">
                                Client
                              </label>
                              <select
                                className="form-control"
                                id="exampleSelectGender"
                                name="client"
                              >
                                <option value="" disabled selected>
                                  Choisir un client
                                </option>
                                {Array.isArray(customers) &&
                                  customers.map((customer) => (
                                    <option
                                      key={customer.id}
                                      value={customer.id}
                                    >
                                      {customer.nom + " " + customer.prenom}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail3">
                                Type Transaction
                              </label>

                              <select
                                className="form-select"
                                name="type_transaction"
                                required
                                aria-label="Type de compte"
                                id="Nif_Cin"
                              >
                                <option value="">Choisir type</option>
                                <option value="Depo">Depot</option>
                                <option value="Retre">Retrait</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail3">
                                Montant
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="exampleInputEmail3"
                                placeholder="Montant"
                                required
                                name="montant"
                              />
                            </div>
                          </div>

                          <div className="col-md-12 mt-4 text-center">
                            <div className="form-group pt-1">
                              <button
                                type="submit"
                                className="btn btn-primary btn-sm"
                                disabled={loading} // Désactive le bouton lors du chargement
                              >
                                {loading ? (
                                  <>
                                    <i className="fas fa-spinner fa-spin"></i>{" "}
                                    Enregistrement en cours...
                                  </>
                                ) : (
                                  <>
                                    <i className="fas fa-save"></i> Enregistrer
                                  </>
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>

                    <div className="card-footer text-center"></div>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">-</h3>

                      <div className="card-tools">
                        <div
                          type="button"
                          className="btn btn-warning btn-sm fw-bold"
                        >
                          <i className="fas fa-list"></i> Liste des Transactions
                        </div>
                      </div>
                    </div>

                    <div className="card-body p-0">
                      <DataTransactionsMutuelle />
                    </div>

                    <div className="card-footer text-center"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default TransactionsMutuelle;
