/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../context/AuthContext";
import { Link } from "react-router-dom";
import Loader from "../Include/loader/loader";
import Carousel from "react-bootstrap/Carousel";
import { Link as ScrollLink } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import { Navbar as BootstrapNavbar, Nav, Container } from "react-bootstrap";

export default function Login() {
  const { loginUser, isOnline } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "Bousanm | Login";
  }, []);

  //   const handleSubmit = (e) => {
  //     e.preventDefault();
  //     const username = e.target.username.value;
  //     const password = e.target.password.value;

  //     if (username.length > 0 && password.length > 0) {
  //       setLoading(true);
  //       loginUser(username, password);
  //       setLoading(false);
  //     }
  //   };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const username = e.target.username.value;
    const password = e.target.password.value;

    if (username.length > 0 && password.length > 0) {
      setLoading(true);
      try {
        await loginUser(username, password); // Attendre que la connexion soit terminée
      } catch (error) {
        console.error("Erreur de connexion :", error);
      } finally {
        setLoading(false); // Remettre loading à false après la fin du processus
      }
    }
  };

  if (!isOnline) {
    return <Loader />;
  }

  return (
    <>
      <div
        className="container-fluid topbar px-0 px-lg-4 bg-light py-2 d-none d-lg-block"
        id="navbar"
      >
        <div className="container">
          <div className="row gx-0 align-items-center">
            <div className="col-lg-8 text-center text-lg-start mb-lg-0">
              <div className="d-flex flex-wrap">
                <div className="border-end border-primary pe-3">
                  <a href="#" className="text-muted small">
                    <i className="fa fa-clock text-primary me-2"></i>7/7 | 24/24
                  </a>
                </div>
                <div className="ps-3">
                  <a
                    href="mailto:zig.bousanm@gmail.com"
                    className="text-muted small"
                  >
                    <i className="fas fa-envelope text-primary me-2"></i>
                    zig.bousanm@gmail.com
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 text-center text-lg-end">
              <div className="d-flex justify-content-end">
                <div className="d-flex border-end border-primary pe-3">
                  <a className="btn p-0 text-primary me-3" href="#">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a className="btn p-0 text-primary me-3" href="#">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a
                    className="btn p-0 text-primary me-3"
                    href="https://www.instagram.com/bousanm"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a className="btn p-0 text-primary me-0" href="#">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Topbar End */}

      {/* Navbar & Hero Start */}
      <div className="container-fluid nav-bar px-0 px-lg-4 py-lg-0">
        <Container>
          <BootstrapNavbar expand="lg" className="navbar-light bg-white">
            <BootstrapNavbar.Brand href="#">
              <h1 className="display-7 text-primary mb-0">
                <img src="/img/logo.png" style={{ width: "60px" }} alt="Logo" />
                Bousanm
              </h1>
            </BootstrapNavbar.Brand>
            <BootstrapNavbar.Toggle className="p-1 btn-sm border border-success d-md-none">
              <RouterLink
                to="/"
                className="btn btn-primary ml-auto rounded-pill flex-shrink-0 btn-sm float-end d-md-none"
              >
                <i className="fas fa-home"></i>
              </RouterLink>
            </BootstrapNavbar.Toggle>
          </BootstrapNavbar>
        </Container>
      </div>
      {/*  Contact Start */}
      <div className="container-fluid contact bg-light " id="contact">
        <div className="container py-5">
          <RouterLink
            to="/"
            className="d-none d-sm-inline-block btn btn-primary rounded-pill py-2 px-4 ms-3 btn-sm float-end"
          >
            <i className="fas fa-home"></i>
          </RouterLink>
          <div
            className="text-center mx-auto pb-5 wow fadeInUp"
            data-wow-delay="0.2s"
            style={{ maxWidth: "800px" }}
          >
            <h4 className="display-7 text-primary">Authentification </h4>
          </div>
          <div className="row g-5">
            <div className="col-xl-6 wow fadeInRight" data-wow-delay="0.4s">
              <div>
                <form onSubmit={handleSubmit}>
                  <div className="row g-3">
                    <div className="col-lg-12">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control border-0"
                          placeholder="Nom Utilisateur"
                          id="name"
                          name="username"
                          required
                        />
                        <label for="name">Nom Utilisateur</label>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-floating">
                        <input
                          type="password"
                          className="form-control border-0"
                          placeholder="Password"
                          id="pass"
                          name="password"
                          required
                        />
                        <label for="pass">Mot de passe</label>
                      </div>
                    </div>
                    <div className="forgot-pwd">
                      <Link className="pwd" to="/forgot_password">
                        Mot de passe <span>oublier ?</span>{" "}
                      </Link>
                    </div>
                    <div className="col-12">
                      <button
                        type="submit"
                        className="btn btn-primary btn-sm"
                        disabled={loading}
                      >
                        {loading ? (
                          <>
                            <i className="fas fa-spinner fa-spin"></i> Connexion
                            en cours...
                          </>
                        ) : (
                          <>
                            <i className="fas fa-save"></i> Se Connecter
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-xl-6 wow fadeInLeft" data-wow-delay="0.2s">
              <div className="contact-img d-flex justify-content-center">
                <div className="contact-img-inner">
                  <img
                    src="/img/login-img-1.png"
                    className="img-fluid w-100"
                    alt="Image Contact"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
