import { jwtDecode } from 'jwt-decode';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { createContext, useState, useEffect, useCallback } from 'react';
import { baseUrlAuth } from './BaseUrl';
const swal = require('sweetalert2');
const AuthContext = createContext();

const baseUrl = baseUrlAuth;

export default AuthContext

export const AuthProvider = ({ children }) => {

    const [authTokens, setAuthTokens] = useState(() =>
        localStorage.getItem("authTokens")
            ? JSON.parse(localStorage.getItem("authTokens"))
            : null
    );
    const [user, setUser] = useState(() =>
        localStorage.getItem("authTokens")
            ? jwtDecode(localStorage.getItem("authTokens"))
            : null
    );
    const [loading, setLoading] = useState(true);
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const history = useHistory();

    const checkInternetConnection = useCallback(() => {
        setIsOnline(navigator.onLine);
    }, []);

    const loginUser = async (identifier, password) => {
        if (!isOnline) {
            setLoading(true);
            return;
        }
        const response = await fetch(`${baseUrl}login/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ identifier, password })
        });
        const data = await response.json();

        if (response.status === 200) {
            const user = data.user;  // Assurez-vous que `data.user` contient les informations de l'utilisateur
            setAuthTokens(data);
            setUser(user);
            localStorage.setItem("authTokens", JSON.stringify(data));

            // Redirection en fonction du type d'utilisateur
            if (user.role === "User") {
                // Utilisateur Accounts
                history.push("/profile");
                swal.fire({
                    title: "Login Successful",
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    position: 'top-right',
                    timerProgressBar: true,
                    showConfirmButton: false,
                });
            }
            else if (user.role === "Customer" || user.role === "MutuelleCustomer") {
                // Utilisateur Accounts
                history.push("/sol/profile");
                swal.fire({
                    title: "Login Successful",
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    position: 'top-right',
                    timerProgressBar: true,
                    showConfirmButton: false,
                });
            }
            else {
                // Utilisateur classique ou autre cas
                history.push("/dashboard");
                swal.fire({
                    title: "Login Successful",
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    position: 'top-right',
                    timerProgressBar: true,
                    showConfirmButton: false,
                });
            }
        } else {
            console.log(response.status);
            console.log("there was a server issue");
            swal.fire({
                title: "Username or password does not exist",
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            });
        }
    };


    const getUserEmailFromLocalStorage = () => {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        if (authTokens && authTokens.user) {
            return authTokens.user.email;
        }
        return null;
    };
    const getUserCodeFromLocalStorage = () => {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        if (authTokens && authTokens.user) {
            return authTokens.user.code;
        }
        return null;
    };
    const getUserRoleFromLocalStorage = () => {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        if (authTokens && authTokens.user) {
            return authTokens.user.role;
        }
        return null;
    };

    const registerUser = async (full_name, email, username, password, password2) => {
        const response = await fetch(`${baseUrl}register/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                full_name, email, username, password, password2
            })
        })
        if (response.status === 201) {
            history.push("/login")
            swal.fire({
                title: "Registration Successful, Login Now",
                icon: "success",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        } else {
            console.log("there was a server issue");
            swal.fire({
                title: "An Error Occured " + response.status,
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        }
    }
    const logoutUser = useCallback(() => {
        setAuthTokens(null)
        setUser(null)
        localStorage.removeItem("authTokens")
        history.push("/")
        swal.fire({
            title: "You have been logged out...",
            icon: "success",
            toast: true,
            timer: 3000,
            position: 'top-right',
            timerProgressBar: true,
            showConfirmButton: false,
        })
    }, [history, setAuthTokens, setUser]);


    // User Actions by Admin
    const createUser = async (formData) => {
        const response = await fetch(`${baseUrl}user/`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${authTokens.access}`
            },
            body: formData
        });

        if (response.status === 201) {
            window.location.reload();
            swal.fire({
                title: "User Created Successfully",
                icon: "success",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            });
        } else {
            console.log("there was a server issue");
            swal.fire({
                title: "An Error Occured " + response.status,
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            });
        }
    };

    const getUser = async (userId) => {
        const response = await fetch(`${baseUrl}user/${userId}/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${authTokens.access}`
            },
        })
        const data = await response.json();
        return data;
    }

    const updateUser = async (userId, formData) => {
        const response = await fetch(`${baseUrl}user/${userId}/`, {
            method: "PUT",
            headers: {
                "Authorization": `Bearer ${authTokens.access}`
            },
            body: formData
        });

        const data = await response.json();

        if (response.status === 200) {
            swal.fire({
                title: "Profil mis à jour avec succès",
                icon: "success",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            });

            const passwordChanged = formData.get("password") !== ""; // Vérifiez si le mot de passe a été modifié
            return { success: true, passwordChanged };
        } else {
            console.log("Problème de serveur");
            swal.fire({
                title: "Une erreur s'est produite : " + response.status + "\n" + data.error,
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            });
            return { success: false };
        }
    };


    const deleteUser = async (userId) => {
        const response = await fetch(`${baseUrl}user/${userId}/`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${authTokens.access}`
            },
        })
        if (response.status === 204) {
            swal.fire({
                title: "User Deleted Successfully",
                icon: "success",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        } else {
            console.log(response.status);
            console.log("there was a server issue");
            swal.fire({
                title: "An Error Occured " + response.status,
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        }
    }

    const getUserById = async (userId) => {
        const response = await fetch(`${baseUrl}user/${userId}/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${authTokens.access}`
            },
        })
        const data = await response.json();
        console.log(data);
        return data;
    }

    const getUsers = async () => {
        const response = await fetch(`${baseUrl}user/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${authTokens.access}`
            },
        })
        const data = await response.json();
        return data;
    }
    const activateDeactivateUser = async (userId) => {
        try {
            const response = await fetch(`${baseUrl}activate-deactivate-user/${userId}/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authTokens.access}`,
                },
            });

            if (!response.ok) {
                if (response.status === 403) {
                    swal.fire({
                        title: "Permission Denied: \nYou cannot deactivate your own account.",
                        icon: "error",
                        toast: true,
                        timer: 3000,
                        position: 'top-right',
                        timerProgressBar: true,
                        showConfirmButton: false,
                    });
                } else {
                    throw new Error('Failed to activate/deactivate user');
                }
            } else {
                history.push("/users");
                const data = await response.json();
                swal.fire({
                    title: data.message,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    position: 'top-right',
                    timerProgressBar: true,
                    showConfirmButton: false,
                });
            }
        } catch (error) {
            console.error(error);
            swal.fire({
                title: "Failed to activate/deactivate user",
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            });
        }
    };
    // End User Actions by Admin
    const resetPassword = async (uidb64, token, newPassword, confirmPassword) => {
        try {
            const response = await fetch(`${baseUrl}reset-password/${uidb64}/${token}/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ new_password: newPassword, confirm_password: confirmPassword })
            });
            const data = await response.json();

            if (response.status === 200) {
                swal.fire({
                    title: "Password reset successful",
                    text: data.message,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    position: 'top-right',
                    timerProgressBar: true,
                    showConfirmButton: false,
                });
                history.push("/");
            } else {
                swal.fire({
                    title: "Password reset failed",
                    text: data.error || "An error occurred",
                    icon: "error",
                    toast: true,
                    timer: 3000,
                    position: 'top-right',
                    timerProgressBar: true,
                    showConfirmButton: false,
                });
            }
        } catch (error) {
            swal.fire({
                title: "An error occurred",
                text: "Please try again.",
                icon: "error",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            });
        }
    };

    const fogotPassword = async (email) => {
        try {
            const response = await fetch(`${baseUrl}forgot-password/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (response.ok) {
                const data = await response.json();
                swal.fire({
                    title: 'Success',
                    text: data.message,
                    icon: 'success',
                    timer: 3000,
                    timerProgressBar: true,
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false
                });
            } else {
                const errorData = await response.json();
                swal.fire({
                    title: 'Error',
                    text: errorData.message || 'An error occurred. Please try again.',
                    icon: 'error',
                    timer: 3000,
                    timerProgressBar: true,
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false
                });
            }
        } catch (error) {
            console.error(error);
            swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error',
                timer: 3000,
                timerProgressBar: true,
                toast: true,
                position: 'top-end',
                showConfirmButton: false
            });
        }
    };
    useEffect(() => {
        window.addEventListener("online", checkInternetConnection);
        window.addEventListener("offline", checkInternetConnection);

        return () => {
            window.removeEventListener("online", checkInternetConnection);
            window.removeEventListener("offline", checkInternetConnection);
        };
    }, [checkInternetConnection]);

    const contextData = {
        user, setUser, authTokens, setAuthTokens, registerUser, loginUser, logoutUser, deleteUser,
        getUsers, getUser, updateUser, createUser, resetPassword, fogotPassword, getUserById,
        activateDeactivateUser, loading, isOnline, getUserEmailFromLocalStorage,
        getUserRoleFromLocalStorage, getUserCodeFromLocalStorage
    }

    const checkTokenExpiration = useCallback(() => {
        const authTokens = localStorage.getItem("authTokens");
        if (!authTokens) return;

        const { access } = JSON.parse(authTokens);
        const decodedToken = jwtDecode(access);
        const isExpired = dayjs.unix(decodedToken.exp).isBefore(dayjs());

        if (isExpired) {
            setAuthTokens(null)
            setUser(null)
            localStorage.removeItem("authTokens")
            history.push("/")
            swal.fire({
                title: "Votre session est expirée",
                icon: "info",
                toast: true,
                timer: 3000,
                position: 'top-right',
                timerProgressBar: true,
                showConfirmButton: false,
            })
        }
    }, [history]);

    useEffect(() => {
        const interval = setInterval(() => {
            checkTokenExpiration();
        }, 60000); // Vérifie toutes les minutes

        return () => clearInterval(interval);
    }, [checkTokenExpiration]);

    useEffect(() => {
        if (authTokens) {
            setUser(jwtDecode(authTokens.access))
        }
        setLoading(false)
    }, [authTokens, loading, checkTokenExpiration]);

    return (
        <AuthContext.Provider value={contextData}>
            {loading ? null : children}
        </AuthContext.Provider>
    )

}
