/* eslint-disable */
import React from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'animate.css';
import OwlCarousel from 'react-owl-carousel';
import { Link as RouterLink } from 'react-router-dom';

import './Carousel.css'; // Assurez-vous que ce chemin est correct et que le fichier CSS est bien importé

export default function DefaultCarousel() {
  const options = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1,
    dots: false,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    navText: ["<span></span>", "<span></span>"],
  };

  return (

    <OwlCarousel className="header-carousel owl-theme" {...options}>
      {/* Première diapositive */}
      <div className="header-carousel-item bg-primary">
        <div className="carousel-caption">
          <div className="container">
            <div className="row g-4 align-items-center">
              <div className="col-lg-7 animate__animated animate__fadeInLeft">
                <div className="text-sm-center text-md-start">
                  <h4 className="text-white text-uppercase fw-bold mb-4">Bienvenue chez Bousanm</h4>
                  <h1 className="display-1 text-white mb-4">La plateforme qui vous rend heureux</h1>
                  <p className="mb-5 fs-5">
                    Chez Bousanm, nous croyons que la sécurité et la simplicité de gestion de vos investissements peuvent transformer votre quotidien. Découvrez une plateforme conçue pour vous offrir la tranquillité d'esprit, où chaque transaction est rapide, sécurisée et sans tracas.
                  </p>
                  <div className="d-flex justify-content-center justify-content-md-start flex-shrink-0 mb-4">
                    <RouterLink className="btn btn-light rounded-pill py-2 px-2 px-md-5 me-2 btn-sm" to="/register">
                      <i className="fas fa-user-plus me-1"></i> S'inscrire
                  </RouterLink>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 animate__animated animate__fadeInRight">
                <div className="carousel-img" style={{ objectFit: 'cover' }}>
                  <img src="/img/carousel-2.png" className="img-fluid w-100" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Deuxième diapositive */}
      <div className="header-carousel-item bg-primary">
        <div className="carousel-caption">
          <div className="container">
            <div className="row gy-4 gy-lg-0 gx-0 gx-lg-5 align-items-center">
              <div className="col-lg-5 animate__animated animate__fadeInLeft">
                <div className="carousel-img">
                  <img src="/img/carousel-2.png" className="img-fluid w-100" alt="" />
                </div>
              </div>
              <div className="col-lg-7 animate__animated animate__fadeInRight">
                <div className="text-sm-center text-md-end">
                  <h4 className="text-white text-uppercase fw-bold mb-4">Bienvenue chez Bousanm</h4>
                  <h1 className="display-1 text-white mb-4">La plateforme qui vous rend heureux</h1>
                  <p className="mb-5 fs-5">
                    Chez Bousanm, nous croyons que la sécurité et la simplicité de gestion de vos investissements peuvent transformer votre quotidien. Découvrez une plateforme conçue pour vous offrir la tranquillité d'esprit, où chaque transaction est rapide, sécurisée et sans tracas.
                  </p>
                  <div className="d-flex  justify-content-md-end flex-shrink-0 mb-4 text-md-end">
                    <RouterLink className="btn btn-light rounded-pill py-2 px-2 px-md-5 me-2 btn-sm" to="/register">
                      <i className="fas fa-user-plus me-1"></i> S'inscrire
                    </RouterLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </OwlCarousel>
  );
}
