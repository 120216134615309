/* eslint-disable */
import React from 'react'

const Contact = () => {
    return (
        <>
            {/*  Contact Start */}
            <div className="container-fluid contact bg-light py-5" id='contact'>
                <div className="container py-5">
                    <div className="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.2s" style={{ maxWidth: "800px" }}>
                        <h4 className="text-primary">Contactez-nous</h4>
                        <h1 className="display-6 mb-4">Si vous avez des commentaires, Contactez-nous maintenant</h1>
                    </div>
                    <div className="row g-5">
                        <div className="col-xl-6 wow fadeInLeft" data-wow-delay="0.2s">
                            <div className="contact-img d-flex justify-content-center" >
                                <div className="contact-img-inner">
                                    <img src="img/contact-img.png" className="img-fluid w-100" alt="Image Contact" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 wow fadeInRight" data-wow-delay="0.4s">
                            <div>
                                <form>
                                    <div className="row g-3">
                                        <div className="col-lg-12 col-xl-6">
                                            <div className="form-floating">
                                                <input type="text" className="form-control border-0" id="name" placeholder="Your Name" />
                                                <label for="name">Votre Nom Complet</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-xl-6">
                                            <div className="form-floating">
                                                <input type="email" className="form-control border-0" id="email" placeholder="Your Email" />
                                                <label for="email">Votre Email</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-xl-6">
                                            <div className="form-floating">
                                                <input type="phone" className="form-control border-0" id="phone" placeholder="Phone" />
                                                <label for="phone">Votre Numéro de Téléphone
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-6">
                                            <div className="form-floating">
                                                <input type="text" className="form-control border-0" id="subject" placeholder="Subject" />
                                                <label for="subject">Sujet</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <textarea className="form-control border-0" placeholder="Leave a message here" id="message" style={{ height: "120px" }}></textarea>
                                                <label for="message">Votre Message</label>
                                            </div>

                                        </div>
                                        <div className="col-12">
                                            <button className="btn btn-primary w-100 py-3">Envoyer Votre Message</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Contact End */}
        </>
    )
}

export default Contact