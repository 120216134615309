/* eslint-disable */
import About from "../About";
import Faqs from "../Faqs";
import Feature from "../Feature";
import Footer from "../Footer";
import Navbar from "../Navbar";
import Service from "../Service";
import DefaultCarousel from "../Carousel"
import Contact from "../Contact";


export default function Home(){
    return (
        <>
            <Navbar/>
            <DefaultCarousel/>
            <About/>
            <Service/>
            {/* <Feature/> */}
            <Faqs/>
            <Contact/> 
            <Footer/>
        </>
    );
}