import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import AuthContext from "../context/AuthContext";
import Sidebar from "../Include/Sidebar";
import Footer from "../Include/Footer";
// const swal = require("sweetalert2");

function UserEdit() {
  const { getUserById, updateUser } = useContext(AuthContext); // Ajoutez updateUser ici
  const [records, setRecords] = useState();
  const { user_id } = useParams();
  const [profileImage, setProfileImage] = useState(null);
  const [profileImageView, setProfileImageView] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const userId = user_id;
    getUserById(userId).then((data) => {
      setRecords(data); // Mettez à jour records avec les données récupérées
    });
  }, [getUserById, user_id]);
  const handleImageChange = (event) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setProfileImage(file); // Mettre à jour profileImage avec le fichier
      const imageUrl = URL.createObjectURL(file);
      setProfileImageView(imageUrl);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    if (profileImage) {
      formData.append("image", profileImage);
    }

    // Enregistrez l'utilisateur avec le mot de passe non crypté

    setLoading(true);
    try {
      updateUser(records.id, formData);
    } catch (error) {
      console.error("Erreur de connexion :", error);
    } finally {
      setLoading(false); // Remettre loading à false après la fin du processus
    }
  };

  return (
    <>
      <Sidebar />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6 d-flex align-items-center">
                <div className="col-sm-1 bg-blue rounded-lg p-2 text-center">
                  <i className="fas fa-pen"></i>
                </div>
                <div className="ml-2">
                  <h3 className="display fw-12">Modifier le Profil</h3>
                </div>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Acceuil</Link>
                  </li>
                  <li className="breadcrumb-item">
                    {records && records.role === "Admin" ? (
                      <Link to="/users">Utilisateurs</Link>
                    ) : (
                      <span className="text-primary">Utilisateurs</span>
                    )}
                  </li>
                  <li className="breadcrumb-item active">Profil</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid col-12">
            <div className="card p-3">
              <div className="col-md-12 text-end">
                <Link to="/users" className="btn btn-primary rounded-lg">
                  <i className="fa fa-arrow-left"></i>
                </Link>
              </div>
              <form
                className="forms-sample"
                encType="multipart/form-data"
                onSubmit={handleSubmit}
              >
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="profil">Photo</label>
                    <div className="form-group">
                      <img
                        src={
                          profileImageView || (records && records.image) || ""
                        }
                        id="profil"
                        alt="profil"
                        style={{
                          maxWidth: "80px",
                          maxHeight: "80px",
                          borderRadius: "50px",
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="name">Nom</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        value={records?.first_name || ""}
                        onChange={(e) =>
                          setRecords({
                            ...records,
                            first_name: e.target.value,
                          })
                        }
                        placeholder="Nom Utilisateur"
                        required
                        disabled
                        name="full_name"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="name">Prenom</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        value={records?.last_name || ""}
                        onChange={(e) =>
                          setRecords({
                            ...records,
                            last_name: e.target.value,
                          })
                        }
                        disabled
                        placeholder="Nom Utilisateur"
                        required
                        name="full_name"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail3">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail3"
                        onChange={(e) =>
                          setRecords({ ...records, email: e.target.value })
                        }
                        value={records?.email || ""}
                        placeholder="Email"
                        required
                        disabled
                        name="email"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="name">Nom Utilisateur</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        onChange={(e) =>
                          setRecords({
                            ...records,
                            username: e.target.value,
                          })
                        }
                        value={records?.username || ""}
                        placeholder="Nom Utilisateur"
                        required
                        disabled
                        name="username"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="role">Role</label>
                      <select
                        name="role"
                        className="form-control"
                        id="exampleSelectGender"
                        value={records?.role || ""}
                        onChange={(e) =>
                          setRecords({ ...records, role: e.target.value })
                        }
                      >
                        <option>Admin</option>
                        <option>Caisier</option>
                        <option>Agent</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-group col-md-6">
                    <label htmlFor="exampleInputFile">Fichier importer</label>
                    import Footer from '../Include/Footer';
                    <div className="input-group">
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="exampleInputFile"
                          accept="image/*"
                          value={records?.image.url || ""}
                          name="image"
                          onChange={handleImageChange}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="exampleInputFile"
                        >
                          {profileImage
                            ? "Image sélectionnée"
                            : "Sélectionner une image"}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-4 text-center">
                    <div className="form-group pt-1">
                      <button
                        type="submit"
                        className="btn btn-primary btn-sm"
                        disabled={loading}
                      >
                        {loading ? (
                          <>
                            <i className="fas fa-spinner fa-spin"></i>{" "}
                            Enregistrement en cours...
                          </>
                        ) : (
                          <>
                            <i className="fas fa-save"></i> Enregistrer
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default UserEdit;
