/* eslint-disable */
import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
const Footer = () => {
  return (
    <>
      {/* <!-- Footer Start --> */}
      <div className="container-fluid footer py-5 wow fadeIn" data-wow-delay="0.2s">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-xl-9">
              <div className="mb-5">
                <div className="row g-4">
                  <div className="col-md-6 col-lg-6 col-xl-5">
                    <div className="footer-item">
                      <h1 className="display-7 text-white mb-0">
                        <img src="/img/logo.png" style={{ width: '60px' }} alt="Logo" />
                        Bousanm</h1>
                      <p className="text-white mb-4 text-justify">Notre plateforme assure la protection de vos données grâce à des systèmes de sécurité avancés. Optimisée pour la performance, elle garantit des transactions rapides et une navigation fluide pour une expérience fiable et sécurisée...</p>
                      <div className="footer-btn d-flex">
                        <a className="btn btn-md-square rounded-circle me-3" href="#"><i className="fab fa-facebook-f"></i></a>
                        <a className="btn btn-md-square rounded-circle me-3" href="#"><i className="fab fa-twitter"></i></a>
                        <a className="btn btn-md-square rounded-circle me-3" href="https://www.instagram.com/bousanm"><i className="fab fa-instagram"></i></a>
                        <a className="btn btn-md-square rounded-circle me-0" href="#"><i className="fab fa-linkedin-in"></i></a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 col-xl-3 text-muted">
                    <div className="footer-item">
                      <h4 className="text-white mb-4">Liens utiles</h4>
                      <ScrollLink to="navbar" spy={true} smooth={true} offset={-70} duration={500}><i className="fas fa-angle-right me-2"></i> Accueil</ScrollLink>
                      <ScrollLink to='about' spy={true} smooth={true} offset={-70} duration={500}><i className="fas fa-angle-right me-2"></i> À propos</ScrollLink>
                      <ScrollLink to='service' spy={true} smooth={true} offset={-70} duration={500}><i className="fas fa-angle-right me-2"></i> Services</ScrollLink>
                      <ScrollLink to='faqs' spy={true} smooth={true} offset={-70} duration={500}><i className="fas fa-angle-right me-2"></i> FAQs</ScrollLink>
                      <ScrollLink to='contact' spy={true} smooth={true} offset={-70} duration={500}><i className="fas fa-angle-right me-2"></i> Contact</ScrollLink>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 col-xl-4">
                    <div className="footer-item">
                      <h4 className="mb-4 text-white">Partenaires</h4>
                      <div className="row g-3">
                        <div className="col-4">
                          <div className="footer-instagram rounded">
                            <img src="img/instagram-footer-1.jpg" className="img-fluid w-100" alt="" />
                            <div className="footer-search-icon">
                              <a href="img/instagram-footer-1.jpg" data-lightbox="footerInstagram-1" className="my-auto"><i className="fas fa-link text-white"></i></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="footer-instagram rounded">
                            <img src="img/instagram-footer-2.jpg" className="img-fluid w-100" alt="" />
                            <div className="footer-search-icon">
                              <a href="img/instagram-footer-2.jpg" data-lightbox="footerInstagram-2" className="my-auto"><i className="fas fa-link text-white"></i></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="footer-instagram rounded">
                            <img src="img/instagram-footer-3.jpg" className="img-fluid w-100" alt="" />
                            <div className="footer-search-icon">
                              <a href="img/instagram-footer-3.jpg" data-lightbox="footerInstagram-3" className="my-auto"><i className="fas fa-link text-white"></i></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="footer-instagram rounded">
                            <img src="img/instagram-footer-4.jpg" className="img-fluid w-100" alt="" />
                            <div className="footer-search-icon">
                              <a href="img/instagram-footer-4.jpg" data-lightbox="footerInstagram-4" className="my-auto"><i className="fas fa-link text-white"></i></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="footer-instagram rounded">
                            <img src="img/instagram-footer-5.jpg" className="img-fluid w-100" alt="" />
                            <div className="footer-search-icon">
                              <a href="img/instagram-footer-5.jpg" data-lightbox="footerInstagram-5" className="my-auto"><i className="fas fa-link text-white"></i></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="footer-instagram rounded">
                            <img src="img/instagram-footer-6.jpg" className="img-fluid w-100" alt="" />
                            <div className="footer-search-icon">
                              <a href="img/instagram-footer-6.jpg" data-lightbox="footerInstagram-6" className="my-auto"><i className="fas fa-link text-white"></i></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3">
              <div className="footer-item">
                <h4 className="text-white mb-4">Appelez-nous</h4>
                <div className="d-flex flex-shrink-0">
                  <div className="footer-btn">
                    <a href="#" className="btn btn-lg-square rounded-circle position-relative wow tada" data-wow-delay=".9s">
                      <i className="fa fa-phone-alt fa-2x pt-2"></i>
                      <div className="position-absolute" style={{ top: '0', right: '19px' }}>
                        <span><i className="fa fa-comment-dots text-secondary"></i></span>
                      </div>
                    </a>
                  </div>
                  <div className="d-flex flex-column ms-3 flex-shrink-0">
                    <span className='text-muted'>Appelez nos experts</span>
                    <a href="tel:+50955657272"><span className="text-muted">Gratuit: +509 5565-7272</span></a>
                    <a href="#" className="text-muted"><i className="fa fa-map-marker-alt text-primary me-2"></i>111, Rue 6 J, Cap-Haitien, HAITI</a>
                  </div>
                  {/* <div className="ps-3">
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Footer End --> */}

      {/* <!-- Copyright Start --> */}
      <div className="container-fluid copyright py-4">
        <div className="container">
          <div className="row g-4 align-items-center">
            <div className="col-md-6 text-center text-md-end mb-md-0">
              <span className=" text-gray"><a href="https://ziginvestment.com" className="border-bottom  text-white"> &copy; Zig Investment</a>, All right reserved.</span>
            </div>
            <div className="col-md-6 text-center text-md-start  text-gray">
              Designed By <a className="border-bottom text-white me-2" href="#">KOLABO Tech </a> Distributed By <a className="border-bottom text-white" href="#"> Zig Investment</a>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Copyright End --> */}
      {/* <!-- Back to Top --> */}
      <a href="#" className="btn btn-primary btn-lg-square rounded-circle back-to-top pt-3"><i className="fas fa-arrow-up"></i></a>
    </>
  )
}

export default Footer