import React, { useContext, useState } from "react";
import Sidebar from "../../Include/Sidebar";
import { Link } from "react-router-dom";
import Footer from "../../Include/Footer";
import { TransactionsContext } from "../../context/TransactionsContext";
import AuthContext from "../../context/AuthContext";
const swal = require("sweetalert2");

function AddTransactionlocal() {
  const { getTransactionByCode, createTransaction } =
    useContext(TransactionsContext);
  const [num_compte, setNum_compte] = useState({ num_compte: "" });
  const [dataAccount, setDataAccount] = useState();
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  const handleFilterAccounts = () => {
    const value = num_compte.num_compte;
    if (value === "") {
      swal.fire({
        title: "Entrer Numero du compte",
        icon: "warning",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      return;
    }

    let formData = new FormData();
    formData.append("num_compte", value);

    getTransactionByCode(formData).then((data) => {
      if (data) {
        setDataAccount(data); // Mettez à jour records avec les données récupérées
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const type_transaction = e.target.type_transaction.value;
    const montant = e.target.montant.value; // Récupérer la valeur du montant

    if (!dataAccount) {
      swal.fire({
        title: "Veuillez rechercher le compte",
        icon: "warning",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      return;
    }

    const compte = dataAccount.id;
    if (!montant) {
      swal.fire({
        title: "Veuillez entrer le montant",
        icon: "warning",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      return;
    }

    let formData = new FormData();
    formData.append("compte", compte);
    formData.append("type_transaction", type_transaction);
    formData.append("montant", montant); // Ajouter le montant au formData
    formData.append("created_by", user.user_id);

    try {
      setLoading(true);
      await createTransaction(formData);
      setLoading(false);
    } catch (error) {
      swal.fire({
        title: "Erreur lors de Transaction",
        text: error.message || "Une erreur s'est produite",
        icon: "error",
        toast: true,
        timer: 3000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      // Afficher un message d'erreur à l'utilisateur ou effectuer d'autres actions nécessaires
    }
  };

  return (
    <>
      <Sidebar />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6 d-flex align-items-center">
                <div className="col-sm-1 bg-blue rounded-lg p-2 text-center">
                  <i className="fas fa-money-bill"></i>
                </div>
                <div className="ml-2">
                  <h3 className="display ffw-500 fw-bold">
                    Nouveau Transaction Local
                  </h3>
                </div>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Tableau de Bord</Link>
                  </li>
                  <li className="breadcrumb-item">Nouveau Transaction Local</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid col-12 text-end ">
            <Link className="col-6 align-items-right" to="/transactionlocal">
              <button className="btn btn-success">Afficher</button>
            </Link>
            <hr />
            <div className="row text-start">
              <div className="col-xl-6 col-lg-5">
                <div className="card shadow mb-4">
                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary">
                      Recherche Compte
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="row g-3 mx-auto">
                      <div className="col-md-9">
                        <label htmlFor="num_compte" className="form-label">
                          Numero Compte
                        </label>
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon3">
                            <span className="material-symbols-outlined">
                              tag
                            </span>
                          </span>
                          <input
                            type="text"
                            placeholder="Numero Compte"
                            name="num_compte"
                            value={num_compte.num_compte}
                            onChange={(e) =>
                              setNum_compte({ num_compte: e.target.value })
                            }
                            className="form-control"
                            id="num_compte"
                            aria-label="name"
                            aria-describedby="basic-addon3"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="name" className="form-label text-white">
                          .
                        </label>
                        <div className="input-group">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={handleFilterAccounts}
                          >
                            Rechercher
                          </button>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label
                          htmlFor="num_compte_disable"
                          className="form-label"
                        >
                          Numero Compte
                        </label>
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon3">
                            <span className="material-symbols-outlined">
                              tag
                            </span>
                          </span>
                          <input
                            type="text"
                            disabled
                            placeholder="Numero Compte"
                            value={dataAccount?.num_compte || ""}
                            name="num_compte_disable"
                            required
                            className="form-control"
                            id="num_compte_disable"
                            aria-label="name"
                            aria-describedby="basic-addon3"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="name" className="form-label">
                          Nom Complet
                        </label>
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon3">
                            <span className="material-symbols-outlined">
                              person
                            </span>
                          </span>
                          <input
                            type="text"
                            disabled
                            placeholder="Nom Complet"
                            value={`${dataAccount?.nom || ""} ${
                              dataAccount?.prenom || ""
                            }`}
                            name="nom_complet"
                            required
                            className="form-control"
                            id="name"
                            aria-label="name"
                            aria-describedby="basic-addon3"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="type" className="form-label">
                          Type Compte
                        </label>
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon3">
                            <span className="material-symbols-outlined">
                              credit_card
                            </span>
                          </span>
                          <input
                            type="text"
                            disabled
                            placeholder="Type Compte"
                            value={dataAccount?.type || ""}
                            name="type"
                            required
                            className="form-control"
                            id="type"
                            aria-label="name"
                            aria-describedby="basic-addon3"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="balance" className="form-label">
                          Balance
                        </label>
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon3">
                            <span class="material-symbols-outlined">
                              account_balance_wallet
                            </span>
                          </span>
                          <input
                            type="text"
                            disabled
                            placeholder="Balance"
                            value={dataAccount?.balance || ""}
                            name="balance"
                            required
                            className="form-control"
                            id="balance"
                            aria-label="name"
                            aria-describedby="basic-addon3"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="etat" className="form-label">
                          Etat du compte
                        </label>
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon3">
                            <span class="material-symbols-outlined">
                              altitude
                            </span>
                          </span>
                          <input
                            type="text"
                            disabled
                            placeholder="Etat du compte"
                            value={dataAccount?.etat || ""}
                            name="etat"
                            required
                            className="form-control"
                            id="etat"
                            aria-label="name"
                            aria-describedby="basic-addon3"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Transactions */}
              {dataAccount?.etat !== "inactif" ? (
                <div className="col-xl-6 col-lg-5">
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 className="m-0 font-weight-bold text-primary">
                        Transaction sur le compte
                      </h6>
                    </div>
                    <div className="card-body">
                      <form
                        className="row g-3 mx-auto"
                        onSubmit={handleSubmit}
                        encType="multipart/form-data"
                      >
                        <div className="col-md-12">
                          <label
                            htmlFor="type_transaction"
                            className="form-label"
                          >
                            Type Transaction
                          </label>
                          <div className="input-group">
                            <span
                              className="input-group-text"
                              id="basic-addon3"
                            >
                              <span className="material-symbols-outlined">
                                receipt_long
                              </span>
                            </span>
                            <select
                              className="form-select"
                              name="type_transaction"
                              required
                              aria-label="type_transaction"
                              id="type_transaction"
                            >
                              <option value="">Choisir Type Transaction</option>
                              <option value="Depo">Depot</option>
                              <option value="Retre">Retrait</option>
                              <option value="PL">Placement</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-9">
                          <label htmlFor="montant" className="form-label">
                            Montant
                          </label>
                          <div className="input-group">
                            <span
                              className="input-group-text"
                              id="basic-addon3"
                            >
                              <span className="material-symbols-outlined">
                                payments
                              </span>
                            </span>
                            <input
                              type="number"
                              className="form-control"
                              name="montant"
                              required
                              placeholder="Montant"
                              id="montant"
                              aria-label="montant"
                              aria-describedby="basic-addon3"
                            />
                          </div>
                        </div>
                        {/* <div className="col-md-12">
                                                <label htmlFor="new_balance" className="form-label">Nouveau Balance</label>
                                                <div className="input-group">
                                                    <span className="input-group-text" id="basic-addon3"><span class="material-symbols-outlined">
                                                        account_balance_wallet
                                                        </span></span>
                                                    <input type="text" disabled placeholder="Balance" name='new_balance' required className="form-control" id="new_balance" aria-label="name" aria-describedby="basic-addon3" />
                                                </div>
                                            </div> */}
                        <div className="col-md-3">
                          <label
                            htmlFor="name"
                            className="form-label text-white"
                          >
                            .
                          </label>
                          <div className="input-group">
                            <button
                              type="submit"
                              className="btn btn-primary btn-sm"
                              disabled={loading}
                            >
                              {loading ? (
                                <>
                                  <i className="fas fa-spinner fa-spin"></i>{" "}
                                  Enregistrement en cours...
                                </>
                              ) : (
                                <>
                                  <i className="fas fa-save"></i> Enregistrer
                                </>
                              )}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-xl-6 col-lg-5">
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 className="m-0 font-weight-bold text-primary">
                        Transaction sur le compte
                      </h6>
                    </div>
                    <div className="card-body">
                      <p className="text-danger">
                        Le compte est inactif. Les transactions sont
                        désactivées.
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {/*End Transactions */}
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default AddTransactionlocal;
