/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import DataTable from "react-data-table-component";
import { ExportDropdown, customStyles } from "./Utility";
import { Dropdown } from "react-bootstrap";
import { AccountContext } from "../context/AccountsContext";
import LoaderData from "../Include/loaderData/loaderData";
const swal = require("sweetalert2");

const DataAccounts = () => {
  const { getAccounts, activateDeactivateAccount } = useContext(AccountContext); // Récupérez la fonction getAccounts du contexte d'authentification
  const [originalRecords, setOriginalRecords] = useState([]); // Initialisez avec un tableau vide
  const [records, setRecords] = useState();
  const [selectedAccount, setSelectedAccount] = useState("Tous les comptes"); // État pour suivre la classe sélectionnée

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAccounts();
        setOriginalRecords(data); // Stockez les données récupérées dans originalRecords
        setRecords(data);
        setLoading(false);
        // console.log(data);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchData();
  }, [getAccounts]);
  if (loading) {
    return <LoaderData />; // Affiche le loader tant que les données sont en cours de chargement
  }
  const columnsAccount = [
    {
      name: "Numero",
      selector: (row) => row.num_compte,
      sortable: true,
      maxWidth: "50px",
    },
    {
      name: "Nom Complet",
      selector: (row) => (
        <div className="d-flex align-items-center">
          {row.nom + " " + row.prenom}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => (
        <div className="d-flex align-items-center">
          {row.type + " - " + row.devise}
        </div>
      ),
      sortable: true,
      maxWidth: "50px",
    },
    {
      name: "Statut",
      selector: (row) => row.statut,
      sortable: true,
    },
    {
      name: "Balance",
      selector: (row) => row.balance,
      sortable: true,
      maxWidth: "50px",
    },
    {
      name: "Interêt",
      selector: (row) => row.interet,
      sortable: true,
      maxWidth: "50px",
    },
    {
      name: "Actions",
      cell: (row) => {
        let isActive = false;
        if (row.etat === "actif") {
          isActive = true;
        }
        return (
          <div className="btn-group" role="group">
            <Link
              to={"/views-account/" + row.id}
              className="btn btn-primary btn-sm me-1"
            >
              <i className="fas fa-eye"></i>
            </Link>
            <button
              className={`btn btn-${
                isActive ? "success" : "danger"
              } btn-sm w-100 me-1`}
              onClick={() => handleActivateDeactivate(row.id)}
            >
              {isActive ? "Désactiver" : "Activer"}
            </button>
            <Link
              to={"/new-account/" + row.id}
              className="btn btn-info btn-sm me-1"
            >
              <i className="fas fa-plus"></i>
            </Link>
          </div>
        );
      },
    },
  ];

  const handleActivateDeactivate = async (id) => {
    try {
      await activateDeactivateAccount(id);
      await refreshData();
    } catch (error) {
      console.error(error);
    }
  };

  const refreshData = async () => {
    try {
      const data = await getAccounts();
      setOriginalRecords(data);
      setRecords(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFilter = (event) => {
    const { value } = event.target;
    if (value === "") {
      setRecords(originalRecords); // Réinitialiser les données à leur valeur d'origine
      return;
    }
    const newData = originalRecords.filter((row) => {
      const nomLower = row.nom.toLowerCase();
      const num_compteLower = row.num_compte.toLowerCase();
      const searchLower = value.toLowerCase();
      return (
        nomLower.includes(searchLower) || num_compteLower.includes(searchLower)
      );
    });

    setRecords(newData);
  };

  const handleFilterByAccount = (type) => {
    setSelectedAccount(type);
    if (type === "Tous les comptes") {
      setRecords(originalRecords);
    } else {
      const newData = originalRecords.filter(
        (row) => row.type.toLowerCase() === type.toLowerCase()
      );
      setRecords(newData);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6 text-start d-flex align-items-center">
          <ExportDropdown
            id="dropdown-item-button"
            title={`Trier par ${selectedAccount}`}
            menualign="right"
            variant="outline-primary btn btn-primary text-white btn-sm mb-0"
          >
            <Dropdown.ItemText>Selectionner un type</Dropdown.ItemText>
            <Dropdown.Item
              as="button"
              onClick={() => handleFilterByAccount("Tous les comptes")}
            >
              Tous les comptes
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              onClick={() => handleFilterByAccount("BS")}
            >
              Bousanm
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              onClick={() => handleFilterByAccount("PL")}
            >
              Plasman
            </Dropdown.Item>
          </ExportDropdown>
        </div>

        <div className="col-md-6 ">
          <input
            className="form-control mb-2"
            type="text"
            placeholder="Search"
            onChange={handleFilter}
          />
        </div>

        <div className="col-md-12 ">
          <DataTable
            columns={columnsAccount}
            data={records}
            fixedHeader
            pagination
            customStyles={customStyles}
            paginationPerPage="10"
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
            paginationComponentOptions={{
              rowsPerPageText: "Lignes par page:",
              rangeSeparatorText: "sur",
              noRowsPerPage: false,
              selectAllRowsItem: false,
              selectAllRowsItemText: "Tous",
            }}
            subHeader
            noDataComponent="Aucune donnée trouvée"
          />
        </div>
      </div>
    </>
  );
};
export default DataAccounts;
