/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import { Navbar as BootstrapNavbar, Nav, Container } from 'react-bootstrap';

export default function Navbar() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate loading completion
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 500); // Ajuste le temps de chargement selon les besoins

        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {isLoading && (
                /* Spinner Start */
                <div id="spinner" className="show bg-white position-fixed d-flex align-items-center justify-content-center w-100 vh-100">
                    <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                /* Spinner End */
            )}

            {/* Topbar Start */}
            <div className="container-fluid topbar px-0 px-lg-4 bg-light py-2 d-none d-lg-block" id='navbar'>
                <div className="container">
                    <div className="row gx-0 align-items-center">
                        <div className="col-lg-8 text-center text-lg-start mb-lg-0">
                            <div className="d-flex flex-wrap">
                                <div className="border-end border-primary pe-3">
                                    <a href="#" className="text-muted small"><i className="fa fa-clock text-primary me-2"></i>7/7 | 24/24</a>
                                </div>
                                <div className="ps-3 pe-3 border-end border-primary">
                                    <a href="mailto:zig.bousanm@gmail.com" className="text-muted small"><i className="fas fa-envelope text-primary me-2"></i>zig.bousanm@gmail.com</a>
                                </div>
                                <div className="ps-3">
                                    <a href="#" className="text-muted small"><i className="fa fa-map-marker-alt text-primary me-2"></i>111, Rue 6 J, Cap-Haitien, HAITI</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 text-center text-lg-end">
                            <div className="d-flex justify-content-end">
                                <div className="d-flex border-end border-primary pe-3">
                                    <a className="btn p-0 text-primary me-3" href="#"><i className="fab fa-facebook-f"></i></a>
                                    <a className="btn p-0 text-primary me-3" href="#"><i className="fab fa-twitter"></i></a>
                                    <a className="btn p-0 text-primary me-3" href="https://www.instagram.com/bousanm"><i className="fab fa-instagram"></i></a>
                                    <a className="btn p-0 text-primary me-0" href="#"><i className="fab fa-linkedin-in"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Topbar End */}

            {/* Navbar & Hero Start */}
            <div className="container-fluid nav-bar px-0 px-lg-4 py-lg-0">
                <Container>
                    <BootstrapNavbar expand="lg" className="navbar-light bg-white">
                        <BootstrapNavbar.Brand href="#">
                            <h1 className="display-7 text-primary mb-0">
                                <img src="/img/logo.png" style={{ width: '60px' }} alt="Logo" />
                                Bousanm
                            </h1>
                        </BootstrapNavbar.Brand>
                        <BootstrapNavbar.Toggle aria-controls="navbarCollapse" className="py-0 px-1 btn-sm border border-success" />
                        <BootstrapNavbar.Collapse id="navbarCollapse">
                            <Nav className="mx-auto">
                                <ScrollLink to="navbar" className="nav-link mb-2 mt-2" spy={true} smooth={true} offset={-70} duration={500}>Accueil</ScrollLink>
                                <ScrollLink to='about' className="nav-link mb-2 mt-2" spy={true} smooth={true} offset={-70} duration={500}>À propos</ScrollLink>
                                <ScrollLink to='service' className="nav-link mb-2 mt-2" spy={true} smooth={true} offset={-70} duration={500}>Services</ScrollLink>
                                <ScrollLink to='faqs' className="nav-link mb-2 mt-2" spy={true} smooth={true} offset={-70} duration={500}>FAQs</ScrollLink>
                                <ScrollLink to='contact' className="nav-link mb-2 mt-2" spy={true} smooth={true} offset={-70} duration={500}>Contact</ScrollLink>
                                <RouterLink to='login' className="btn btn-primary rounded-pill py-2 px-4 ms-3 flex-shrink-0 btn-sm">Se connecter</RouterLink>
                            </Nav>
                        </BootstrapNavbar.Collapse>
                        <div className="d-none d-xl-flex flex-shrink-0 ps-4">
                            <a href="#" className="btn btn-light btn-lg-square rounded-circle position-relative wow tada" data-wow-delay=".9s">
                                <i className="fa fa-phone-alt fa-2x pt-2"></i>
                                <div className="position-absolute" style={{ top: '0', right: '19px' }}>
                                    <span><i className="fa fa-comment-dots text-secondary"></i></span>
                                </div>
                            </a>
                            <div className="d-flex flex-column ms-3">
                                <span>Appelez nos experts</span>
                                <a href="tel:+50955657272"><span className="text-dark">Gratuit: +509 55657272</span></a>
                            </div>
                        </div>
                    </BootstrapNavbar>
                </Container>
            </div>
            {/* Navbar & Hero End */}
        </>
    );
}
