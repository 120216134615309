import React from 'react';
import { Accordion } from 'react-bootstrap';

const Faqs = () => {
  return (
    <div className="container-fluid faq-section bg-light py-5" id="faqs">
      <div className="container py-5">
        <div className="row g-5 align-items-center">
          <div className="col-xl-6">
            <div className="h-100">
              <div className="mb-5">
                <h4 className="text-primary">Quelques FAQ importantes</h4>
                <h1 className="display-4 mb-0">Questions fréquemment posées</h1>
              </div>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Q: Sommes-nous une entreprise virtuelles?</Accordion.Header>
                  <Accordion.Body>
                    R: Nous Sommes une entreprise physique avec une équipe compétente dynamique et sérieuse dans divers domaine.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Q: Pourquoi faire confiance à Zig Investment ou Bousanm?</Accordion.Header>
                  <Accordion.Body>
                    R: Notre confiance se trouve dans la diversité de nos investissements et les différentes façons de faire circuler l’argent dans nos communautés.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Q: Est-ce que la plateforme BOUSANM est fiable et sécurisée ??</Accordion.Header>
                  <Accordion.Body>
                    R: BOUSANM utilise les technologies de pointe pour éventuellement détecter, mitiger et répondre immédiatement aux incidents.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div className="col-xl-6">
            <img src="img/carousel-2.png" className="img-fluid w-100" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
