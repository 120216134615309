
import styled from 'styled-components';
import { DropdownButton } from 'react-bootstrap';

export const customStyles = {
    rows: {
      style: {
        minHeight: "45px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        fontSize: "16px",
        fontWeight: "bold",
        fontFamily: 'Sansita',
      },
    },
  };
  
export const ExportDropdown = styled(DropdownButton)`
    color: white;
    border: none;
    width: auto;
    border-radius: 5px;
    margin-left: 1px;
    margin-bottom:1px;
  `;
  