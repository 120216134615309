/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import "./loopple.css";
import "./theme.css";
import { AccountContext } from "../../context/AccountsContext";
import AuthContext from "../../context/AuthContext";
import { useTimer } from "../../context/TimerContext";
import { CustomerContext } from "../../context/CustomerContext";

const Profile = () => {
  const [accountInfo, setAccountInfo] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const { getClientAccounts, fetchNextInterest, changePassword } =
    useContext(AccountContext); // Récupérez l'email et les tokens d'authentification
  const { getCustomerBySurnom, getCustomerMutuelleByTel } =
    useContext(CustomerContext);
  const { getUserEmailFromLocalStorage, getUserRoleFromLocalStorage } =
    useContext(AuthContext); // Récupérez la fonction getUserEmailFromLocalStorage
  const { timeLeft } = useTimer();
  const email = getUserEmailFromLocalStorage(); // Appelez la fonction pour récupérer l'e-mail de l'utilisateur
  const role = getUserRoleFromLocalStorage();
  const [nextInterest, setNextInterest] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      alert("Passwords don't match. Please try again.");
      return;
    }

    try {
      await changePassword(selectedAccount.email, newPassword);
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };
  const formatTime = ({ hours, minutes, seconds }) => {
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");
    return `${formattedHours}h ${formattedMinutes}m ${formattedSeconds}s`;
  };

  useEffect(() => {
    if (selectedAccount && selectedAccount.type === "PL") {
      fetchNextInterest(selectedAccount.id).then(setNextInterest);
    }
  }, [selectedAccount, fetchNextInterest]);

  useEffect(() => {
    const fetchAccountInfo = async () => {
      const data = await getClientAccounts(email);
      if (!data.error) {
        setAccountInfo(data);
        setSelectedAccount(data[0]); // Sélectionnez le premier compte par défaut
        //   console.log(data);
      }
    };

    fetchAccountInfo();
  }, [email, getClientAccounts]);

  const handleSelectAccount = (numCompte) => {
    const selected = accountInfo.find(
      (account) => account.num_compte === numCompte
    );
    setSelectedAccount(selected);
  };

  return (
    <div>
      <nav
        className="sidenav-1 navbar-1 navbar-1-vertical fixed-left navbar-1-expand-xs navbar-1-light bg-white-1 loopple-fixed-start"
        id="sidenav-1-main"
      >
        <div className="navbar-1-inner">
          <div
            className="collapse navbar-1-collapse"
            id="sidenav-1-collapse-main"
          >
            <ul className="navbar-1-nav">
              <li className="nav-item-1 mb-3 mt-0">
                <a
                  className="nav-link-1 active bg-primary text-white"
                  href="javascript:"
                >
                  <i className="fa fa-desktop text-primary"></i>
                  <span className="nav-link-1-text fw-bold">Bousanm</span>
                </a>
              </li>
              <li className="nav-item-1">
                <a className="nav-link-1 active fw-bold" href="javascript:">
                  <i className="fa fa-desktop text-primary"></i>
                  <span className="nav-link-1-text fw-bold">Vos Comptes</span>
                </a>
              </li>
              <li className="nav-item-1">
                <a className="nav-link-1" href="javascript:">
                  <i className="fa fa-lock text-danger"></i>
                  <span className="nav-link-1-text">
                    {" "}
                    {selectedAccount
                      ? `${selectedAccount.num_compte} `
                      : "Numero Compte"}
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div className="main-content-1" id="panel">
        <nav
          className="navbar-1 navbar-1-top navbar-1-expand navbar-1-dark border-bottom bg-primary"
          id="navbar-1Top"
        >
          <div className="container-fluid-1">
            <div
              className="collapse navbar-1-collapse"
              id="navbar-1SupportedContent"
            >
              <ul className="navbar-1-nav align-items-center ml-md-auto">
                <li className="nav-item-1 d-xl-none">
                  <div
                    className="pr-3 sidenav-1-toggler sidenav-1-toggler-dark active"
                    data-action="sidenav-1-pin"
                    data-target="#sidenav-1-main"
                  >
                    <div className="sidenav-1-toggler-inner">
                      <i className="sidenav-1-toggler-line"></i>
                      <i className="sidenav-1-toggler-line"></i>
                      <i className="sidenav-1-toggler-line"></i>
                    </div>
                  </div>
                </li>
                <li className="nav-item-1 d-sm-none">
                  <a
                    className="nav-link-1"
                    href="#"
                    data-action="search-show"
                    data-target="#navbar-1-search-main"
                  >
                    <i className="ni ni-zoom-split-in"></i>
                  </a>
                </li>
              </ul>
              <ul className="navbar-1-nav align-items-center ml-auto ml-md-0">
                <li className="nav-item-1 dropdown">
                  <a
                    className="nav-link-1 pr-0"
                    href="#"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="media align-items-center">
                      <span className="avatar avatar-sm rounded-circle">
                        <img
                          alt="Image placeholder"
                          src="https://demos.creative-tim.com/argon-dashboard/assets-old/img/theme/team-4.jpg"
                        />
                      </span>
                      <div className="media-body ml-2 d-none d-lg-block">
                        <span className="mb-0 text-sm font-weight-bold">
                          Username
                        </span>
                      </div>
                    </div>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <div className="dropdown-header noti-title">
                      <h6 className="text-overflow m-0">Bienvenue!</h6>
                    </div>
                    <a href="#!" className="dropdown-item">
                      <i className="fa fa-user"></i>
                      <span>Mon Profil</span>
                    </a>
                    <div className="dropdown-divider"></div>
                    <a href="#!" className="dropdown-item">
                      <i className="fa fa-sign-out-alt"></i>
                      <span>Déconnecter</span>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div className="container-fluid-1 pt-3">
          <div className="row removable">
            <div className="col-lg-4">
              <div className="card-1 card-1-profile">
                <img
                  src="/img/h-bg.png"
                  alt="Image placeholder"
                  className="card-1-img-top-1"
                />
                <div className="row justify-content-center">
                  <div className="col-lg-3 order-lg-2">
                    <div className="card-1-profile-1-image">
                      <a href="#">
                        <img
                          src="https://demos.creative-tim.com/argon-dashboard/assets-old/img/theme/team-4.jpg"
                          className="rounded-circle"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="card-1-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                  <div className="d-flex justify-content-between">
                    <a href="#" className="btn btn-sm mr-4 bg-primary">
                      {selectedAccount
                        ? `${selectedAccount.type} `
                        : "Type Compte"}
                    </a>
                    <a href="#" className="btn btn-sm float-right btn-success">
                      {selectedAccount ? `${selectedAccount.etat} ` : "Etat"}
                    </a>
                  </div>
                </div>
                <div className="card-1-body pt-0">
                  <div className="row">
                    <div className="col">
                      <div className="card-1-profile-1-stats d-flex justify-content-center">
                        <div>
                          <span className="heading">
                            {selectedAccount
                              ? `${selectedAccount.balance} `
                              : "Balance"}
                          </span>
                          <span className="description">Balance</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <h5 className="h3">
                      {selectedAccount
                        ? `${selectedAccount.nom} ${selectedAccount.prenom}`
                        : "Nom Complet"}
                      <span className="font-weight-light"></span>
                    </h5>
                    <h5 className="font-weight-300">
                      {selectedAccount
                        ? `${selectedAccount.adresse} `
                        : "Adresse"}
                    </h5>
                    <h5 className="h5 mt-4">
                      {selectedAccount
                        ? `${selectedAccount.statut} `
                        : "Statut"}
                    </h5>
                    <h5></h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-8">
              {/* card-1 for Transactions */}
              <div className="card-1 card-1-stats">
                <div className="card-1-body">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-1-title text-uppercase text-muted mb-0">
                        Derniere Transaction
                      </h5>
                      <span className="h2 font-weight-bold mb-0">2350</span>{" "}
                      gdes
                    </div>
                    <div className="col-auto">
                      <div className="icon icon-shape text-white rounded-circle shadow bg-gradient-warning">
                        Depo
                      </div>
                    </div>
                  </div>
                  <p className="mt-3 mb-0 text-sm">
                    <span className="text-success mr-2">
                      <i className="fa fa-arrow-up"></i> 10-Sept-2024
                    </span>
                    <span className="text-nowrap">04:34:09 PM</span>
                  </p>
                </div>
              </div>
              {/* card-1 for Interests */}
              <div className="card-1 card-1-stats">
                <div className="card-1-body">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-1-title text-uppercase text-muted mb-0">
                        Interet calculé
                      </h5>
                      <span className="h2 font-weight-bold mb-0">442</span>
                    </div>
                    <div className="col-auto">
                      <h5 className="card-1-title text-uppercase text-muted mb-0 ">
                        Jours
                      </h5>
                      <div className="icon icon-shape text-white rounded-circle shadow bg-gradient-success fw-bold">
                        34
                      </div>
                    </div>
                  </div>
                  <p className="mt-3 mb-0 text-sm">
                    <span className="text-success mr-2">
                      <i className="fa fa-arrow-up"></i> 30%
                    </span>
                    <span className="text-nowrap">Depuis le mois dernier</span>
                  </p>
                </div>
              </div>

              <div class="card-1 card-1-stats">
                <div class="card-1-body">
                  <div class="row">
                    <div class="col">
                      <h5 class="card-1-title text-uppercase text-muted mb-0">
                        Prochain interet dans{" "}
                      </h5>
                      <span class="h2 font-weight-bold mb-0">0.12939229</span>{" "}
                      gdes
                    </div>
                    <div class="col-auto">
                      <div class="icon icon-shape bg-orange text-white rounded-circle shadow">
                        <i class="fa fa-inbox"></i>
                      </div>
                    </div>
                  </div>
                  <p class="mt-3 mb-0 text-sm">
                    <span class="text-success mr-2">
                      <i class="fa fa-arrow-up"></i>&nbsp;06:34:09
                    </span>
                    <span class="text-nowrap">Since last week</span>
                  </p>
                </div>
              </div>
              {/* More content can follow */}
            </div>
          </div>
          <div className="row removable">
            <div className="col-lg-12">
              <div className="card-1">
                <div className="card-1-header border-0">
                  <div className="row align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Liste des Transactions</h3>
                    </div>
                    <div className="col text-right">
                      <a href="#!" className="btn btn-sm btn-primary">
                        Voir tout
                      </a>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">type</th>
                        <th scope="col">Montant</th>
                        <th scope="col">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Facebook</th>
                        <td>1,480</td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="mr-2">60%</span>
                            <div>
                              <div className="progress">
                                <div
                                  className="progress-bar bg-gradient-danger"
                                  role="progressbar"
                                  aria-valuenow="60"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                  style={{ width: "60%" }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
