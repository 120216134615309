/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import DataTable from "react-data-table-component";
import { customStyles } from "./Utility";
import { CustomerContext } from "../context/CustomerContext";
import LoaderData from "../Include/loaderData/loaderData";
import { AccountContext } from "../context/AccountsContext";
const swal = require("sweetalert2");

const DataCustomers = () => {
  const { getCustomers, deleteCustomers } = useContext(CustomerContext); // Récupérez la fonction getCustomers du contexte d'authentification
  const { ResetPasswordCustomer } = useContext(AccountContext);
  const [originalRecords, setOriginalRecords] = useState([]); // Initialisez avec un tableau vide
  const [records, setRecords] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getCustomers();
        setOriginalRecords(data); // Stockez les données récupérées dans originalRecords
        setRecords(data);
        setLoading(false);
        // console.log(data);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchData();
  }, [getCustomers]);

  if (loading) {
    return <LoaderData />; // Affiche le loader tant que les données sont en cours de chargement
  }
  const columnsUser = [
    // {
    //   name: "ID",
    //   selector: (row) => row.id,
    //   sortable: true,
    //   maxWidth: '50px',
    // },
    {
      name: "Code",
      selector: (row) => row.code,
      sortable: true,
      // maxWidth: '50px',
    },
    // {
    //   name: "Nom",
    //   selector: (row) => row.nom,
    //   sortable: true,
    // },

    {
      name: "Tlf.",
      selector: (row) => row.tel,
      sortable: true,
    },
    {
      name: "SurNom",
      selector: (row) => row.surnom,
      sortable: true,
    },
    {
      name: "Sol/Jours",
      selector: (row) => row.sol + " Gdes",
      sortable: true,
    },
    {
      name: "Nbr de Mains",
      selector: (row) => row.nb_main,
      sortable: true,
    },
    {
      name: "Mtnt/Jours",
      selector: (row) => row.montant_jours + " Gdes",
      sortable: true,
    },
    {
      name: "Solde",
      selector: (row) => row.balance + " Gdes",
      maxwidth: "50px",
      sortable: true,
      paddingRight: "0px",
    },
    // {
    //   name: "Balance",
    //   selector: (row) => row.balance + ' Gdes',
    //   maxwidth: "50px",
    //   sortable: true,
    //   paddingRight: "0px",
    // },
    {
      name: "Pass",
      selector: (row) => row.pass_send,
      maxwidth: "50px",
      sortable: true,
      paddingRight: "0px",
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="btn-group" role="group">
          <Link
            to={"/View-custommer/" + row.id}
            className="btn btn-primary btn-sm me-1"
          >
            <i className="fas fa-eye"></i>
          </Link>
          <button
            onClick={() => handleDelete(row.id)}
            className="btn btn-danger btn-sm me-1"
          >
            <i className="fas fa-trash"></i>
          </button>
          <button
            onClick={() => handleReset(row.code)}
            className="btn btn-warning btn-sm  "
          >
            <i className="fas fa-redo"></i>
          </button>
        </div>
      ),
    },
  ];

  function handleDelete(id) {
    swal
      .fire({
        title: "Êtes-vous sûr(e) de vouloir supprimer ce client?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, supprimer!",
        cancelButtonText: "Annuler",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          await deleteCustomers(id); // Assuming userId is defined
          swal.fire(
            "Supprimé!",
            "Votre client a été supprimé avec succès.",
            "success"
          );
          await refreshData();
        }
      });
  }

  function handleReset(code) {
    swal
      .fire({
        title:
          "Êtes-vous sûr(e) de vouloir réinitialiser le mot de passe de ce client?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, Réinitialiser!",
        cancelButtonText: "Annuler",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          await ResetPasswordCustomer(code); // Assuming userId is defined
          swal.fire(
            "Réinitialisé!",
            "Mot de passe client réinitialisé avec succès.",
            "success"
          );
          await refreshData();
        }
      });
  }
  const refreshData = async () => {
    try {
      const data = await getCustomers();
      setOriginalRecords(data);
      setRecords(data);
    } catch (error) {
      console.error(error);
    }
  };
  function handleFilter(event) {
    const { value } = event.target;
    if (value === "") {
      setRecords(originalRecords); // Réinitialiser les données à leur valeur d'origine
      return;
    }

    const searchLower = value.toLowerCase();

    const newData = originalRecords.filter((row) => {
      const code = row.code ? row.code.toString().toLowerCase() : ""; // Convertir en string s'il est null/undefined
      const surnom = row.surnom ? row.surnom.toString().toLowerCase() : ""; // Convertir en string s'il est null/undefined

      return code.includes(searchLower) || surnom.includes(searchLower);
    });

    setRecords(newData);
  }

  return (
    <>
      <div className="row wow fadeIn  " data-wow-delay="0.1s ">
        <div className="col-md-6 "></div>
        <div className="col-md-6 mt-1">
          <input
            className="form-control mb-2"
            type="text"
            placeholder="Search"
            onChange={handleFilter}
          />
        </div>

        <div className="col-12 ">
          <DataTable
            columns={columnsUser}
            data={records}
            fixedHeader
            pagination
            customStyles={customStyles}
            paginationPerPage="10"
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
            paginationComponentOptions={{
              rowsPerPageText: "Lignes par page:",
              rangeSeparatorText: "sur",
              noRowsPerPage: false,
              selectAllRowsItem: false,
              selectAllRowsItemText: "Tous",
            }}
            noDataComponent="Aucune donnée trouvée"
          />
        </div>
      </div>
    </>
  );
};

const DataCustomersMutuelle = () => {
  const { getCustomersMutuelle } = useContext(CustomerContext); // Récupérez la fonction getCustomers du contexte d'authentification
  const [originalRecords, setOriginalRecords] = useState([]); // Initialisez avec un tableau vide
  const [records, setRecords] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getCustomersMutuelle();
        setOriginalRecords(data); // Stockez les données récupérées dans originalRecords
        setRecords(data);
        setLoading(false);
        // console.log(data);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchData();
  }, [getCustomersMutuelle]);

  if (loading) {
    return <LoaderData />; // Affiche le loader tant que les données sont en cours de chargement
  }
  const columnsUser = [
    {
      name: "Code",
      selector: (row) => row.code,
      sortable: true,
      // maxWidth: '50px',
    },
    {
      name: "Nom Complet",
      selector: (row) => (
        <div className="d-flex align-items-center">
          {row.nom + " " + row.prenom}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Mutuelle",
      selector: (row) => row.mutuelle,
      sortable: true,
    },
    {
      name: "Durée",
      selector: (row) => row.duree + " Mois",
      sortable: true,
    },
    {
      name: "Balance",
      selector: (row) => row.balance,
      maxwidth: "50px",
      sortable: true,
      paddingRight: "0px",
    },
    // {
    //   name: "Actions",
    //   cell: (row) => (
    //     <div className="btn-group" role="group">
    //       <Link to={'/detail-user/'+ row.id} className="btn btn-primary btn-sm me-1">
    //         <i className="fas fa-eye"></i>
    //       </Link>

    //       <Link to={'/detail-user/'+ row.id} className="btn btn-warning btn-sm me-1">
    //         <i className="fas fa-edit"></i>
    //       </Link>

    //     </div>
    //   ),
    // },
  ];

  function handleFilter(event) {
    const { value } = event.target;
    if (value === "") {
      setRecords(originalRecords); // Réinitialiser les données à leur valeur d'origine
      return;
    }

    const newData = originalRecords.filter((row) => {
      const nomLower = row.nom.toLowerCase();
      const prenomLower = row.prenom.toLowerCase();
      const codeLower = row.code.toLowerCase();
      const searchLower = value.toLowerCase();
      return (
        nomLower.includes(searchLower) ||
        prenomLower.includes(searchLower) ||
        codeLower.includes(searchLower)
      );
    });

    setRecords(newData);
  }

  return (
    <>
      <div className="row wow fadeIn  " data-wow-delay="0.1s ">
        <div className="col-md-6 "></div>
        <div className="col-md-6 mt-1">
          <input
            className="form-control mb-2"
            type="text"
            placeholder="Search"
            onChange={handleFilter}
          />
        </div>

        <div className="col-12 ">
          <DataTable
            columns={columnsUser}
            data={records}
            fixedHeader
            pagination
            customStyles={customStyles}
            paginationPerPage="10"
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
            paginationComponentOptions={{
              rowsPerPageText: "Lignes par page:",
              rangeSeparatorText: "sur",
              noRowsPerPage: false,
              selectAllRowsItem: false,
              selectAllRowsItemText: "Tous",
            }}
            noDataComponent="Aucune donnée trouvée"
          />
        </div>
      </div>
    </>
  );
};
export { DataCustomers, DataCustomersMutuelle };
