import React from "react";
import Sidebar from "../../Include/Sidebar";
import { Link } from "react-router-dom";
import DataAccounts from "../../data/DataAccounts";
import Footer from "../../Include/Footer";

function ListAcount() {
  return (
    <>
      <Sidebar />
      <div className="content-wrapper  ">
        {/*wow fadeIn " data-wow-delay="0.05s */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6 d-flex align-items-center">
                <div className="col-sm-1 bg-blue rounded-lg p-2 text-center">
                  <i className="fas fa-user-plus"></i>
                </div>
                <div className="ml-2">
                  <h3 className="display fw-12">Gestion des Comptes</h3>
                </div>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Acceuil</Link>
                  </li>
                  <li className="breadcrumb-item active">Comptes</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        {/* <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className=" col-12 p-3 text-end  border-0 p-0 order-2 order-md-1">
                    <Link to='/add-account' className="btn btn-success p-1 fw-bold" >
                      <i className="fas fa-plus"> </i>
                      <span> </span>Nouveau Compte
                    </Link>
                  </div>
                 
                 <DataAccounts/>
                </div>
              </div>
            </div> */}

        <div className="container-fluid">
          <div className="card">
            <div className="col-12">
              <div className="card-header bg-white">
                <h2 className="card-title display">Liste des Comptes</h2>
                <div className="card col-3 m-2 ml-auto border-0 p-0 order-2 order-md-1">
                  <Link
                    to="/add-account"
                    className="btn btn-success p-1 fw-bold"
                  >
                    <i className="fas fa-plus"> </i>
                    <span> </span>Nouveau Compte
                  </Link>
                </div>
              </div>
              <DataAccounts />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ListAcount;
